import { parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "rebass";
import { Browser } from "@capacitor/browser";
import line_logo from "../../../Assets/clinic/line_logo.svg";
import da from "date-fns/locale/da";
import AgnosAnalytics, { AAActionType } from "../../../AgnosAnalytics";

const dayInTh = {
  Mon: "จันทร์",
  Tue: "อังคาร",
  Wed: "พุธ",
  Thu: "วันพฤหัสบดี",
  Fri: "ศุกร์",
  Sat: "เสาร์",
  Sun: "อาทิตย์",
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const ClinicProfileDetailBox = ({ data, distance }) => {
  const [expandOpenHours, setExpandOpenHours] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [todayDisplayString, setTodayDisplayString] = useState(null);
  const [openingHours, setOpeningHours] = useState([]);

  useEffect(() => {
    if (!data || !data.opening_hours) return;

    let clinicOpening = [];
    days.forEach((day) => {
      const today = data.opening_hours.filter((d) => d.day === day);
      const todayHourList = today?.map((d) =>
        d.opening_time
          ? `${d.opening_time.slice(0, -3)}-${d.closing_time.slice(0, -3)} น.`
          : ""
      );

      const todayHoursStr = today[0]?.opening_time
        ? `${dayInTh[today[0]?.day]} ${todayHourList.join(", ")}`
        : `${dayInTh[today[0]?.day]} ปิดทำการ`;

      clinicOpening = [
        ...clinicOpening,
        {
          day: today[0]?.day,
          openingHoursDisplay: todayHoursStr,
          closed: !today[0]?.opening_time || !today[0]?.closing_time,
        },
      ];

      calculateOpeningDisplayString(today, today[0]?.day);
    });
    setOpeningHours(clinicOpening);
    console.log(clinicOpening);
  }, [data]);

  const calculateOpeningDisplayString = (todayOpeningHours, day) => {
    const currentDate = new Date();
    const isSunday = currentDate.getDay() === 0 && day === days[6]; // if today is sunday
    const isToday = day === days[currentDate.getDay() - 1] || isSunday;
    console.log(isSunday, isToday);

    if (!isToday) return;

    todayOpeningHours.forEach((opening) => {
      if (!opening.opening_time || !opening.closing_time) {
        setIsOpen(false);
        setTodayDisplayString(null);
        return;
      }

      const currentTime = currentDate.getHours();
      const openingDate = parse(
        opening.opening_time.slice(0, -3),
        "HH:mm",
        new Date()
      );
      const openingTime = openingDate.getHours();
      let closingDate = parse(
        opening.closing_time.slice(0, -3),
        "HH:mm",
        new Date()
      );
      const closingTime = closingDate.getHours();

      if (closingTime < openingTime) {
        closingDate.setDate(closingDate.getDate() + 1);
      }

      let openingStr = "";
      if (currentDate < openingDate) {
        setIsOpen(false);
        openingStr = `เปิด ${opening.opening_time}`;
      } else if (currentDate >= openingDate && currentDate < closingDate) {
        setIsOpen(true);
        openingStr = `เปิดจนถึง ${opening.closing_time}`;
      } else {
        setIsOpen(false);
        openingStr = `ปิดเมื่อ ${opening.closing_time}`;
      }

      setTodayDisplayString(openingStr.slice(0, -3));
    });
  };

  const onClickOpenMap = async () => {
    AgnosAnalytics.event({
      page: "clinic_profile",
      actionType: AAActionType.CLICK,
      action: "clinic_maps_button",
      data: { clinic_name: data.clinic_name },
    });
    await Browser.open({ url: `${data.map_link}` });
  };

  const onClickSocialButton = async (type) => {
    switch (type) {
      case "facebook":
        AgnosAnalytics.event({
          page: "clinic_profile",
          actionType: AAActionType.CLICK,
          action: `clinic_contact_${type}`,
          data: { clinic_name: data.clinic_name },
        });
        await Browser.open({ url: `${data.facebook_link}` });
        break;
      case "line":
        AgnosAnalytics.event({
          page: "clinic_profile",
          actionType: AAActionType.CLICK,
          action: `clinic_contact_${type}`,
          data: { clinic_name: data.clinic_name },
        });
        break;
      case "phone_number":
        AgnosAnalytics.event({
          page: "clinic_profile",
          actionType: AAActionType.CLICK,
          action: `clinic_contact_${type}`,
          data: { clinic_name: data.clinic_name },
        });
        break;
      case "website":
        AgnosAnalytics.event({
          page: "clinic_profile",
          actionType: AAActionType.CLICK,
          action: `clinic_contact_${type}`,
          data: { clinic_name: data.clinic_name },
        });
        await Browser.open({ url: `${data.website_link}` });
        break;
    }
  };

  return (
    <Flex
      mt={4}
      mb={120}
      pb={30}
      minHeight={400}
      width={1}
      flexDirection="column"
      css={{
        background: "#fff",
        borderRadius: "16px",
        padding: "20px 24px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        textAlign: "start",
        zIndex: 2,
      }}
    >
      {/* logo */}
      <Flex
        width={1}
        justifyContent="space-between"
        display={data.site_logo === null ? "none !important" : "flex"}
      >
        <Image
          ml={"-10px"}
          width={135}
          src={data.site_logo}
          onError={(e) => (e.target.style.visibility = "hidden")}
        />

        <Flex
          alignItems="center"
          css={{
            gap: "4px",
            visibility: "hidden",
          }}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.17383 16.8701H13.8174C15.7158 16.8701 16.7002 15.8857 16.7002 14.0137V3.31738C16.7002 1.43652 15.7158 0.460938 13.8174 0.460938H3.17383C1.27539 0.460938 0.291016 1.43652 0.291016 3.31738V14.0137C0.291016 15.8945 1.27539 16.8701 3.17383 16.8701ZM8.51758 12.3701C5.0459 12.3701 2.62012 9.53125 2.62012 8.65234C2.62012 7.78223 5.02832 4.95215 8.51758 4.95215C12.0156 4.95215 14.4062 7.78223 14.4062 8.65234C14.4062 9.53125 12.0244 12.3701 8.51758 12.3701ZM8.51758 11.0605C9.84473 11.0605 10.9258 9.96191 10.9258 8.66113C10.9258 7.31641 9.84473 6.25293 8.51758 6.25293C7.18164 6.25293 6.10059 7.31641 6.10059 8.66113C6.10059 9.96191 7.18164 11.0605 8.51758 11.0605ZM8.52637 9.71582C7.94629 9.71582 7.46289 9.22363 7.46289 8.66113C7.46289 8.08984 7.94629 7.59766 8.52637 7.59766C9.08887 7.59766 9.58105 8.08984 9.58105 8.66113C9.58105 9.22363 9.08887 9.71582 8.52637 9.71582Z"
              fill="#8FB6E8"
            />
          </svg>
          <Text variant="base" fontSize="12px" fontWeight={700} color="#8FB6E8">
            55
          </Text>
        </Flex>
      </Flex>

      <Text
        mt={2}
        variant="base"
        fontSize="20px"
        fontWeight={700}
        color="#001B52"
      >
        {data.clinic_name}
      </Text>
      <Text
        mt={2}
        variant="base"
        fontSize="14px"
        fontWeight={400}
        color="#9E9E9E"
      >
        {data.clinic_specialty}
      </Text>

      <Flex
        mt={2}
        css={{
          gap: "16px",
        }}
      >
        {!isNaN(distance) && (
          <Text
            variant="base"
            fontSize="14px"
            fontWeight={500}
            color={isOpen ? "#00CCB1" : "#F54800"}
          >
            {distance ?? "-"} กม.
          </Text>
        )}
        <Text
          variant="base"
          fontSize="14px"
          fontWeight={500}
          color={isOpen ? "#00CCB1" : "#F54800"}
          css={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <span
            style={{
              width: "11px",
              height: "11px",
              borderRadius: "50%",
              background: isOpen ? "#60CB6A" : "#F54800",
            }}
          />
          {` ${isOpen ? "เปิดอยู่" : "ปิดอยู่"}`}
        </Text>
      </Flex>

      <Text
        mt={2}
        variant="base"
        fontSize="14px"
        fontWeight={400}
        color="#001B52"
      >
        {data.clinic_detail}
      </Text>

      <Text
        mt={20}
        variant="base"
        fontSize="14px "
        fontWeight={400}
        color="#1E1446"
        css={{
          gap: "8px",
          display: "flex",
          alignItems: "start",
          lineHeight: "0",
        }}
      >
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginTop: "3px",
          }}
        >
          <path
            d="M7.5 0C3.63438 0 0.5 3.13438 0.5 7C0.5 10.8656 3.63438 14 7.5 14C11.3656 14 14.5 10.8656 14.5 7C14.5 3.13438 11.3656 0 7.5 0ZM10.2578 9.15156L9.81094 9.76094C9.80122 9.77421 9.78897 9.78542 9.7749 9.79395C9.76083 9.80247 9.74522 9.80812 9.72896 9.81059C9.7127 9.81306 9.69611 9.81229 9.68014 9.80833C9.66418 9.80437 9.64916 9.79729 9.63594 9.7875L7.05156 7.90312C7.03546 7.89156 7.02238 7.8763 7.0134 7.85863C7.00443 7.84095 6.99983 7.82138 7 7.80156V3.5C7 3.43125 7.05625 3.375 7.125 3.375H7.87656C7.94531 3.375 8.00156 3.43125 8.00156 3.5V7.36719L10.2297 8.97812C10.2859 9.01719 10.2984 9.09531 10.2578 9.15156Z"
            fill="#1E1446"
          />
        </svg>
        <Flex mb="8px" flexDirection="column">
          <Text
            variant="base"
            as="p"
            onClick={() => setExpandOpenHours(!expandOpenHours)}
          >
            {todayDisplayString && !expandOpenHours
              ? todayDisplayString
              : "เวลาเปิด-ปิด"}
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginLeft: "8px",
                transform: expandOpenHours && "rotate(180deg)",
                transition: "cubic-bezier(0.45, 0.05, 0.55, 0.95) 0.5s",
              }}
            >
              <path
                d="M7.14063 7.96875C7.40625 7.96875 7.63281 7.86719 7.83594 7.66406L13.7969 1.57031C13.9688 1.39844 14.0547 1.1875 14.0547 0.9375C14.0547 0.429688 13.6641 0.0234375 13.1562 0.0234375C12.9062 0.0234375 12.6797 0.125 12.5 0.296875L7.14063 5.79688L1.78125 0.296875C1.60938 0.132813 1.38281 0.0234375 1.13281 0.0234375C0.617188 0.0234375 0.226562 0.429688 0.226562 0.9375C0.226562 1.1875 0.3125 1.39844 0.484375 1.57031L6.44531 7.66406C6.64844 7.875 6.875 7.96875 7.14063 7.96875Z"
                fill="#1E1446"
              />
            </svg>
          </Text>
          <Flex
            mt={1}
            display={expandOpenHours ? "block" : "none !important"}
            flexDirection="column"
          >
            {openingHours.map((d, i) => (
              <Text
                my={2}
                variant="base"
                as="p"
                key={i}
                style={{
                  color: d.closed ? "#A7A7A7" : "#1E1446",
                  marginTop: "-2px",
                }}
              >
                {d.openingHoursDisplay}
              </Text>
            ))}
          </Flex>
        </Flex>
      </Text>

      <Flex
        mt={2}
        justifyContent="space-between"
        alignItems="center"
        css={{
          gap: "8px",
        }}
      >
        <Flex
          alignItems="center"
          css={{
            gap: "8px",
          }}
        >
          <Box width={14}>
            <svg
              width="12"
              height="16"
              viewBox="0 0 11 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.61725 14.6207C5.61725 14.6207 10.6692 9.54956 10.9488 5.95658C11.204 2.67677 8.89251 0 5.61725 0C2.342 0 -0.228884 2.67599 0.0161729 5.95658C0.279646 9.4837 5.61725 14.6207 5.61725 14.6207ZM5.55515 8.1875C7.00862 8.1875 8.18688 7.00923 8.18688 5.55577C8.18688 4.10231 7.00862 2.92404 5.55515 2.92404C4.10169 2.92404 2.92343 4.10231 2.92343 5.55577C2.92343 7.00923 4.10169 8.1875 5.55515 8.1875Z"
                fill="#1E1446"
              />
            </svg>
          </Box>
          <Text
            variant="base"
            fontSize="14px "
            fontWeight={400}
            color="#1E1446"
            css={{
              marginLeft: "2px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {data.address ? data.address : "-"}
          </Text>
        </Flex>

        <Text
          variant="base"
          fontSize="14px "
          fontWeight={700}
          color="#1E1446"
          css={{
            visibility:
              data.map_link === null || data?.map_link === ""
                ? "hidden"
                : "visible",
            textDecoration: "underline",
          }}
          onClick={onClickOpenMap}
        >
          ดูแผนที่
        </Text>
      </Flex>

      <Text
        display={
          data.services && data.services.length > 0
            ? "ิblock"
            : "none !important"
        }
        mt={3}
        variant="base"
        fontSize="16px"
        fontWeight={700}
        color="#001B52"
      >
        บริการ
        <Text
          variant="base"
          as="ul"
          style={{
            fontWeight: "400",
            paddingInlineStart: "34px",
            marginBlockStart: "8px",
          }}
        >
          {data?.services?.map((service, i) => (
            <Text variant="base" as="li" key={i}>
              {service}
            </Text>
          ))}
        </Text>
      </Text>

      <Flex
        mt="20px"
        variant="base"
        css={{
          alignItems: "center",
          lineHeight: "0",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginTop: "8px",
          }}
        >
          <path
            d="M8.5 16C12.8868 16 16.5077 12.3791 16.5077 8C16.5077 3.62089 12.8791 0 8.49226 0C4.11315 0 0.5 3.62089 0.5 8C0.5 12.3791 4.12089 16 8.5 16ZM8.60832 12.6267C8.39168 12.6267 8.22921 12.472 8.22921 12.2476V11.7215H6.99903C6.56576 11.7215 6.28723 11.4352 6.28723 10.9942V5.12186C6.28723 4.68085 6.5735 4.38685 6.99903 4.38685H8.22921V3.80658C8.22921 3.58221 8.39168 3.42747 8.60832 3.42747C8.81721 3.42747 8.97195 3.58221 8.97195 3.80658V4.38685H9.11895C10.3878 4.38685 11.2234 5.16828 11.2234 6.26692C11.2234 6.94004 10.8056 7.55899 10.2176 7.83752C11.03 8.07737 11.5716 8.75822 11.5716 9.62476C11.5716 10.9014 10.6277 11.7215 9.14217 11.7215H8.97195V12.2476C8.97195 12.472 8.81721 12.6267 8.60832 12.6267ZM7.51741 7.44294H8.22921V5.43907H7.52514L7.51741 7.44294ZM8.97195 7.40426C9.6528 7.31915 10.001 6.96325 10.001 6.39845C10.001 5.84139 9.64507 5.51644 8.97195 5.45455V7.40426ZM7.52514 10.6692L8.22921 10.6615V8.45648H7.52514V10.6692ZM8.97195 10.6538C9.87718 10.6151 10.3259 10.236 10.3259 9.54739C10.3259 8.88201 9.87718 8.51064 8.97195 8.45648V10.6538Z"
            fill="#1E1446"
          />
        </svg>
        <Text variant="base" fontSize="14px " fontWeight={400} color="#1E1446">
          ราคาเริ่มต้น <strong>{data.medical_fee}</strong> บาท
        </Text>
      </Flex>

      <Text mt={3} variant="base" fontSize="16px" fontWeight={700} color="#000">
        ช่องทางการติดต่อ
      </Text>

      <Text
        mt={2}
        variant="base"
        fontSize="14px"
        fontWeight={500}
        color="#1E1446"
        as="a"
        href={data.facebook_link}
        onClick={() => onClickSocialButton("facebook")}
        target="_blank"
        css={{
          display: data.facebook_link === null ? "none !important" : "flex",
          gap: "16px",
          textDecoration: "underline",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 8.05C0.5 5.20255 0.5 3.77882 1.06293 2.69513C1.53731 1.78191 2.28191 1.03731 3.19513 0.562933C4.27882 0 5.70255 0 8.55 0H16.45C19.2975 0 20.7212 0 21.8049 0.562933C22.7181 1.03731 23.4627 1.78191 23.9371 2.69513C24.5 3.77882 24.5 5.20255 24.5 8.05V15.95C24.5 18.7975 24.5 20.2212 23.9371 21.3049C23.4627 22.2181 22.7181 22.9627 21.8049 23.4371C20.7212 24 19.2975 24 16.45 24H8.55C5.70255 24 4.27882 24 3.19513 23.4371C2.28191 22.9627 1.53731 22.2181 1.06293 21.3049C0.5 20.2212 0.5 18.7975 0.5 15.95V8.05Z"
            fill="url(#paint0_linear_3717_75863)"
          />
          <path
            d="M17.1667 15.3307L17.7 11.9974H14.5V9.66406C14.5 8.73073 14.8333 7.9974 16.3 7.9974H17.8333V4.93073C16.9667 4.7974 16.0333 4.66406 15.1667 4.66406C12.4333 4.66406 10.5 6.33073 10.5 9.33073V11.9974H7.5V15.3307H10.5V23.9974C11.8333 23.9974 13.1667 23.9974 14.5 23.9974V15.3307H17.1667Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3717_75863"
              x1="12.5"
              y1="23.2988"
              x2="12.5"
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0062E0" />
              <stop offset="1" stopColor="#19AFFF" />
            </linearGradient>
          </defs>
        </svg>
        {data.clinic_name}
      </Text>
      <Text
        mt={3}
        variant="base"
        fontSize="14px"
        fontWeight={500}
        color="#1E1446"
        css={{
          display: !data.line_oa ? "none !important" : "flex",
          gap: "16px",
          textDecoration: "underline",
          alignItems: "center",
          overflow: "hidden",
        }}
        as="a"
        href={`https://line.me/R/ti/p/${data.line_oa}`}
        target="_blank"
        onClick={() => onClickSocialButton("line")}
      >
        <Image src={line_logo} minWidth="24px" />
        {data.line_oa}
      </Text>
      <Text
        mt={3}
        variant="base"
        fontSize="14px"
        fontWeight={500}
        color="#1E1446"
        as="a"
        href={`tel:${data.phone}`}
        target="_self"
        css={{
          display: !data.phone ? "none !important" : "flex",
          gap: "16px",
          textDecoration: "underline",
          alignItems: "center",
          overflow: "hidden",
        }}
        onClick={() => onClickSocialButton("phone_number")}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginLeft: "2px",
            minWidth: "24px",
          }}
        >
          <path
            d="M9.92188 7.16406C10.1016 7.16406 10.2656 7.10156 10.3984 6.96875L13.5469 3.8125L14.6172 2.64844L14.4844 4.67188V5.79688C14.4844 6.15625 14.7578 6.42969 15.125 6.44531C15.4922 6.45312 15.7969 6.17188 15.7969 5.80469V1.40625C15.7969 0.890625 15.5312 0.609375 15.0078 0.609375H10.5859C10.2188 0.609375 9.92969 0.914062 9.94531 1.28906C9.95312 1.65625 10.2344 1.92188 10.5859 1.92188H11.7422L13.7891 1.79688L12.6016 2.86719L9.45312 6.01562C9.30469 6.16406 9.23438 6.33594 9.23438 6.53125C9.23438 6.89062 9.53125 7.16406 9.92188 7.16406ZM4.85938 11.4453C7.16406 13.7578 9.96875 15.5391 12.2578 15.5391C13.2812 15.5391 14.1797 15.1797 14.8984 14.3828C15.3203 13.9141 15.5781 13.375 15.5781 12.8359C15.5781 12.4219 15.4141 12.0156 15.0156 11.7344L12.6094 10.0312C12.2344 9.76562 11.9219 9.64062 11.6406 9.64062C11.2734 9.64062 10.9453 9.84375 10.5859 10.2031L10.0156 10.7656C9.92969 10.8516 9.82031 10.8906 9.71875 10.8906C9.59375 10.8906 9.47656 10.8438 9.39062 10.7969C8.90625 10.5312 8.0625 9.8125 7.28125 9.03125C6.5 8.25 5.77344 7.41406 5.51562 6.91406C5.46875 6.82812 5.42188 6.71875 5.42188 6.59375C5.42188 6.5 5.45312 6.39062 5.54688 6.29688L6.10938 5.72656C6.46094 5.35938 6.67188 5.03906 6.67188 4.67188C6.67188 4.38281 6.54688 4.07031 6.28125 3.69531L4.58594 1.32031C4.29688 0.914062 3.88281 0.742188 3.44531 0.742188C2.91406 0.742188 2.38281 0.976562 1.91406 1.42188C1.14062 2.15625 0.796875 3.07031 0.796875 4.07812C0.796875 6.36719 2.54688 9.14062 4.85938 11.4453Z"
            fill="#1E1446"
          />
        </svg>
        {data.phone}
      </Text>
      <Text
        mt={3}
        variant="base"
        fontSize="14px"
        fontWeight={500}
        color="#1E1446"
        as="a"
        href={data.website_link}
        onClick={() => onClickSocialButton("website")}
        target="_blank"
        css={{
          display: !data.website_link ? "none !important" : "flex",
          overflow: "hidden",
          gap: "16px",
          textDecoration: "underline",
          alignItems: "center",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginLeft: "2px",
            minWidth: "24px",
          }}
        >
          <path
            d="M0.287109 8.3254C0.287109 8.71715 0.588449 9.05615 1.07059 9.05615C1.30413 9.05615 1.51507 8.92808 1.7034 8.77741L9.17662 2.50202C9.38756 2.32122 9.62863 2.32122 9.83957 2.50202L17.3052 8.77741C17.486 8.92808 17.697 9.05615 17.9305 9.05615C18.375 9.05615 18.714 8.77741 18.714 8.34047C18.714 8.08433 18.6161 7.88093 18.4202 7.71519L16.3033 5.93729V2.80336C16.3033 2.46436 16.0848 2.25342 15.7458 2.25342H14.7062C14.3747 2.25342 14.1487 2.46436 14.1487 2.80336V4.12172L10.5778 1.1234C9.92243 0.573451 9.09375 0.573451 8.43834 1.1234L0.588449 7.71519C0.385045 7.88093 0.287109 8.10693 0.287109 8.3254ZM2.73549 15.098C2.73549 16.1828 3.4135 16.8382 4.53599 16.8382H14.4727C15.5876 16.8382 16.2732 16.1828 16.2732 15.098V9.37256L9.96763 4.09159C9.67383 3.83545 9.31975 3.84298 9.03348 4.09159L2.73549 9.37256V15.098ZM11.4819 15.4219H7.52679V10.6005C7.52679 10.2464 7.76032 10.0204 8.1144 10.0204H10.9018C11.2559 10.0204 11.4819 10.2464 11.4819 10.6005V15.4219Z"
            fill="#1E1446"
          />
        </svg>
        {data.website_link}
      </Text>
    </Flex>
  );
};

export default ClinicProfileDetailBox;
