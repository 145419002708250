import React, { useState, useEffect } from "react";
import { Flex, Text, Image } from "rebass";
import { useNavigate, useLocation } from "react-router-dom";
import background_banner_clinic from "../../../Assets/clinic/background_banner_clinic.png";

const ClinicBanner = ({ promotion, onClick, data }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [promotionImage, setPromotionImage] = useState(null);

  useEffect(() => {
    if (data?.images) {
      setPromotionImage(data?.images[0]);
    }
  }, []);

  return (
    <Flex
      mt={3}
      width={1}
      minHeight={promotion ? 120 : 95}
      css={{
        borderRadius: "16px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Flex
        flex={2}
        pb={2}
        flexDirection="column"
        css={{
          background: `url(${promotionImage}) no-repeat  `,
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        {/* <Flex
        width={20}
        height={20}
        css={{
          background: "#ADDAF8",
          position: "absolute",
          borderRadius: "100%",
          zIndex: 4,
        }}
      /> */}
        <Text
          mt={3}
          ml="16px"
          variant="base"
          fontSize="20px"
          fontWeight={700}
          color="#1A59C2"
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          มีโค้ด
          <span
            style={{
              color: "#FF2121",
              fontSize: "48px",
              fontWeight: "700",
            }}
          >
            ส่วนลด
          </span>
        </Text>
        <Text
          ml="16px"
          variant="base"
          fontSize={["14px", "14px", "18px"]}
          fontWeight={700}
          color="#1A59C2"
          textAlign="left"
        >
          ทุกแพ็กเกจตรวจสุขภาพ
        </Text>

        <Flex
          css={{
            visibility: "hidden",
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <svg
            width="60"
            height="24"
            viewBox="0 0 60 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.748047 23.9171V18.3125H1.96462L3.67887 23.0485L5.39313 18.3125H6.61261V23.9142H5.93738V18.9757H5.81515L4.05432 23.7117H3.30342L1.54551 18.9757H1.42327V23.9142H0.748047V23.9171Z"
              fill="#00205C"
            />
            <path
              d="M8.33398 23.9171V18.3125H12.0186V18.8642H9.00921V20.7979H11.4947V21.3407H9.00921V23.3654H12.0215V23.9171H8.33398Z"
              fill="#00205C"
            />
            <path
              d="M15.5492 23.9171H13.3867V18.3125H15.5492C16.5358 18.3125 17.1878 18.6734 17.5108 19.3982C17.6971 19.8149 17.7902 20.3577 17.7902 21.0326C17.7902 22.4411 17.4119 23.3302 16.6522 23.697C16.3466 23.8437 15.9799 23.9171 15.5492 23.9171ZM17.0975 21.0326C17.0975 19.9587 16.8385 19.2896 16.3175 19.0255C16.108 18.917 15.8519 18.8642 15.5463 18.8642H14.059V23.3654H15.5463C16.5795 23.3654 17.0975 22.5878 17.0975 21.0326Z"
              fill="#00205C"
            />
            <path
              d="M19.2949 23.9171V18.3125H19.9701V23.9142H19.2949V23.9171Z"
              fill="#00205C"
            />
            <path
              d="M25.3874 23.8302C24.7849 23.9446 24.2465 24.0004 23.7692 24.0004C23.2919 24.0004 22.9048 23.9387 22.6108 23.8184C22.3169 23.6981 22.0899 23.5074 21.9269 23.2521C21.7668 22.9968 21.6562 22.7004 21.5951 22.3689C21.534 22.0373 21.502 21.6235 21.502 21.1247C21.502 20.6288 21.534 20.2121 21.5951 19.8747C21.6562 19.5372 21.7668 19.2379 21.9269 18.9797C22.087 18.7215 22.314 18.5307 22.605 18.4134C22.8961 18.296 23.2773 18.2344 23.743 18.2344C24.2087 18.2344 24.7587 18.2931 25.3874 18.4134L25.3612 18.9386C24.7704 18.8418 24.2465 18.7919 23.7867 18.7919C23.1434 18.7919 22.7214 18.968 22.5119 19.323C22.3052 19.6751 22.2005 20.2796 22.2005 21.1335C22.2005 21.559 22.2208 21.9023 22.2587 22.1605C22.2965 22.4187 22.3722 22.6535 22.4828 22.8618C22.5934 23.0702 22.7564 23.2169 22.9688 23.3079C23.1813 23.3959 23.4927 23.4428 23.906 23.4428C24.3193 23.4428 24.8024 23.393 25.3641 23.2961L25.3874 23.8302Z"
              fill="#00205C"
            />
            <path
              d="M26.4473 23.922L28.1091 18.3203H29.5789L31.2408 23.922H30.5743L30.1377 22.4812H27.5474L27.1108 23.922H26.4473ZM28.6156 18.8514L27.6988 21.9208H29.9922L29.0725 18.8514H28.6156Z"
              fill="#00205C"
            />
            <path
              d="M35.8334 23.9171H32.498V18.3125H33.1733V23.3566H35.8334V23.9171Z"
              fill="#00205C"
            />
            <path
              d="M23.2798 0.914062H20.6604C20.4974 0.914062 20.3664 1.04611 20.3664 1.21043V9.1625C20.3664 10.3832 19.729 10.9525 18.364 10.9525C16.999 10.9525 16.3616 10.3832 16.3616 9.1625V1.21043C16.3616 1.04611 16.2306 0.914062 16.0677 0.914062H13.4483C13.2853 0.914062 13.1543 1.04611 13.1543 1.21043V9.10969C13.1543 10.659 13.6025 11.8181 14.4844 12.5605C15.3517 13.2882 16.6556 13.655 18.364 13.655C20.0724 13.655 21.3763 13.2853 22.2436 12.5605C23.1255 11.821 23.5737 10.659 23.5737 9.10969V1.21043C23.5737 1.04611 23.4427 0.914062 23.2798 0.914062Z"
              fill="#00205C"
            />
            <path
              d="M35.5386 6.46031H31.7812C31.6182 6.46031 31.4873 6.59235 31.4873 6.75668V8.86354C31.4873 9.02786 31.6182 9.1599 31.7812 9.1599H32.6631V10.8941L31.3825 10.9528C30.3405 10.9528 29.6566 10.718 29.351 10.2603C29.0192 9.76438 28.8504 8.84886 28.8504 7.54015C28.8504 6.20209 28.9377 5.25429 29.1123 4.71731C29.2695 4.22727 29.514 3.89276 29.8341 3.71963C30.0728 3.59052 30.5967 3.43793 31.7347 3.43793C32.6864 3.43793 33.932 3.51423 35.4338 3.66388C35.5153 3.66975 35.5939 3.64627 35.6579 3.59345C35.7191 3.54064 35.7569 3.46141 35.7598 3.38218L35.8355 1.5042C35.8413 1.35455 35.7365 1.22544 35.5939 1.19903L34.91 1.07579C33.5333 0.829302 32.2323 0.703125 31.0449 0.703125C29.0774 0.703125 27.6542 1.2225 26.8131 2.24952C25.9865 3.25894 25.5703 4.89924 25.5703 7.1264C25.5703 9.3477 25.972 11.0027 26.7607 12.0473C27.5669 13.1154 29.0425 13.6553 31.1409 13.6553C32.2731 13.6553 33.7691 13.5174 35.5852 13.2474C35.7307 13.2269 35.8384 13.1007 35.8384 12.954V6.75668C35.8326 6.59235 35.7016 6.46031 35.5386 6.46031Z"
              fill="#00205C"
            />
            <path
              d="M43.3497 5.80595C42.1186 5.43916 41.3328 5.16039 41.0126 4.9726C40.7623 4.82881 40.6488 4.63515 40.6488 4.37106C40.6488 4.13337 40.6488 3.40566 42.4213 3.40566C43.1402 3.40566 44.4004 3.49955 46.1671 3.68442C46.333 3.69616 46.4727 3.58465 46.4901 3.42033L46.6997 1.50714C46.7171 1.35162 46.6124 1.21077 46.4581 1.18142L45.7712 1.05818C44.3189 0.823433 43.1024 0.703125 42.1565 0.703125C40.7303 0.703125 39.5865 1.0171 38.7541 1.63918C37.8956 2.2818 37.459 3.25894 37.459 4.54712C37.459 5.58 37.7966 6.40162 38.4602 6.99142C39.1005 7.56069 40.157 8.03312 41.6821 8.43219C42.788 8.72562 43.2479 8.9457 43.4429 9.07481C43.6728 9.23033 43.7776 9.42986 43.7776 9.70569C43.7776 10.2397 43.6 10.9528 42.0604 10.9528C41.1785 10.9528 39.8805 10.8472 38.207 10.6388C38.0498 10.6153 37.9014 10.7327 37.8781 10.8912L37.6132 12.7691C37.59 12.9276 37.6976 13.0773 37.8548 13.1037L38.5329 13.2269C39.9736 13.5115 41.2135 13.6524 42.2147 13.6524C43.603 13.6524 44.7497 13.3061 45.6228 12.6254C46.5163 11.9241 46.9703 10.8912 46.9703 9.54724C46.9703 8.48207 46.6822 7.67513 46.1176 7.14401C45.5675 6.63343 44.6624 6.19622 43.3497 5.80595Z"
              fill="#00205C"
            />
            <path
              d="M59.2686 13.0691L56.364 1.13219C56.332 1.00015 56.2126 0.90625 56.0759 0.90625H51.1048C50.968 0.90625 50.8516 1.00015 50.8167 1.13219L47.912 13.0691C47.8917 13.1571 47.912 13.251 47.9673 13.3244C48.0226 13.3978 48.1099 13.4388 48.2002 13.4388H50.8196C50.9564 13.4388 51.0757 13.3449 51.1077 13.21L51.5646 11.2792H55.616L56.0729 13.21C56.105 13.3449 56.2243 13.4388 56.3611 13.4388H58.9805C59.0707 13.4388 59.158 13.3978 59.2133 13.3244C59.2686 13.251 59.2919 13.1601 59.2686 13.0691ZM54.9873 8.57956H52.202L53.375 3.50608H53.8173L54.9873 8.57956Z"
              fill="#00205C"
            />
            <path
              d="M16.0746 5.84669H11.1297V1.21043C11.1297 1.04611 10.9987 0.914062 10.8358 0.914062H8.21635C8.05337 0.914062 7.9224 1.04611 7.9224 1.21043V5.79388H3.56836V8.51108H7.91949V13.1473C7.91949 13.3117 8.05046 13.4437 8.21344 13.4437H10.8328C10.9958 13.4437 11.1268 13.3117 11.1268 13.1473V8.51108H12.0814H16.0717C16.2317 8.51108 16.3598 8.40251 16.3598 8.26459V6.09024C16.3627 5.95527 16.2347 5.84669 16.0746 5.84669Z"
              fill="url(#paint0_linear_3717_75782)"
            />
            <path
              d="M3.71023 1.21043C3.71023 1.04611 3.57926 0.914062 3.41627 0.914062H0.796866C0.63097 0.914062 0.5 1.04611 0.5 1.21043V13.1473C0.5 13.3117 0.63097 13.4437 0.793956 13.4437H3.41336C3.57635 13.4437 3.70732 13.3117 3.70732 13.1473V1.21043H3.71023Z"
              fill="#00205C"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3717_75782"
                x1="3.56952"
                y1="7.17815"
                x2="16.3637"
                y2="7.17815"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#851719" />
                <stop offset="0.0268" stopColor="#8A181C" />
                <stop offset="0.1372" stopColor="#9B1B29" />
                <stop offset="0.263" stopColor="#A81D34" />
                <stop offset="0.4113" stopColor="#B21E3B" />
                <stop offset="0.6035" stopColor="#B71E40" />
                <stop offset="1" stopColor="#B91F41" />
              </linearGradient>
            </defs>
          </svg>
        </Flex>
      </Flex>
      <Flex
        flex={promotion ? 0 : 1}
        justifyContent="center"
        alignItems="center"
        backgroundColor="#fff"
        onClick={onClick}
      >
        <Flex
          // minWidth={100}
          minHeight={41}
          width={"80%"}
          justifyContent="center"
          alignItems="center"
          css={{
            background: "#1A59C2",
            borderRadius: "16px",
          }}
        >
          <Text
            variant="base"
            color="#fff"
            fontSize={["12px", "12px", "14px"]}
            fontWeight={700}
          >
            รับโค้ดส่วนลด
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ClinicBanner;
