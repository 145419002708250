const symptomColors = [
    "#5EB1E1",
    "#2C497F",
    "#6FA9BE",
    "#8C4AFF",
    "#3FC493",
    "#5B5EB5",
    "#610F7F",
    "#F46036",
    "#EFBF11",
    "#1EBBEB",
    "#EF7C8E",
    "#887BB0",
    "#D29FF0",
    "#E0702F",
    "#8FDDE7"
];

export default symptomColors;