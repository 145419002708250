import React from "react";
import { Modal } from "semantic-ui-react";
import { Text, Button, Flex, Image } from "rebass";
import internetErrorImage from "../../Assets/internet-error-icon.svg";

const InternetErrorModal = ({ visible, setVisible, buttonFunc }) => {
  return (
    <Modal
      open={visible}
      onClose={setVisible}
      basic
      style={{
        background: "#FFFFFF",
        borderRadius: "16px 16px 0px 0px",
        width: "100vw",
        bottom: "0px",
      }}
    >
      <Flex flexDirection="column" alignItems="center">
        <Image
          src={internetErrorImage}
          style={{ margin: "40px 0px 16px 0px" }}
        />
        <Text
          fontFamily="Mitr"
          textAlign="center"
          color="#1E1446"
          width="50vw"
          marginBottom="24px"
        >
          <h3
            style={{
              fontSize: "25px",
              fontWeight: "500",
              marginBottom: "8px",
            }}
          >
            อินเทอร์เน็ตขัดข้อง
          </h3>
          <span style={{ fontSize: "16px", fontWeight: "300" }}>
            กรุณาตรวจสอบเครือข่าย หรือเชื่อมต่อ wifi อีกครั้ง
          </span>
        </Text>
        <Button
          onClick={() => {
            buttonFunc();
            setVisible();
          }}
          variant="primary"
          width="90%"
          maxWidth="500px"
          css={{
            fontFamily: "Mitr",
            fontSize: "20px",
            borderRadius: "14px",
            background: "#1E1446",
            color: "#F2E1C1",
            marginBottom: "60px",
            padding: "10px 0px",
          }}
        >
          โหลดใหม่อีกครั้ง
        </Button>
      </Flex>
    </Modal>
  );
};

export default InternetErrorModal;
