import { Capacitor } from "@capacitor/core";
import { authHeader } from "./session";
import { apiService } from "./apiService";

export const AAActionType = {
  CLICK: "click", // button or components
  VIEW: "view", // content or page
  CLOSE: "close", // content, popup, or page
  EXPAND: "expand", // hidden content
  DOWNLOAD: "download",
  SCROLL: "scroll",
  SUBMIT: "submit",
  SEARCH: "search",
  RESET: "reset",
};

export const AADataType = {
  QUESTION: "question",
  CHOICE: "choice",
  ARTICLE: "article",
  QUESTIONFEEDBACK: "questionfeedback",
  DISEASE: "disease",
};

export const event = async ({
  page,
  actionType,
  action,
  dataType,
  data = null,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  let utm_medium = queryParams.get("utm_medium");
  let utm_source = queryParams.get("utm_source");
  let utm_campaign = queryParams.get("utm_campaign");
  if (data != null && (utm_medium || utm_source || utm_campaign)) {
    data = { ...data, utm_source, utm_medium, utm_campaign };
  } else if (data === null && (utm_campaign || utm_medium || utm_source)) {
    data = { utm_source, utm_medium, utm_campaign };
  }
  let req = {
    page,
    action_type: actionType,
    action,
    data_type: dataType,
    data,
  };
  await apiService
    .post(`/analytic/send`, req, authHeader())
    .then(() => console.log(`[agnos-analytics] ${JSON.stringify(req)}`))
    .catch((err) => console.error(err));
};

// Event with question data
export const questionEvent = async ({
  page,
  actionType,
  action,
  questionType,
  question,
  duration,
}) => {
  let questionData = {
    question_key: question.question_key,
    question_text: question.question,
    question_type: questionType,
    multi_choice: question.multi_choice,
    duration: duration,
  };
  event({
    page,
    actionType,
    action,
    dataType: AADataType.QUESTION,
    data: questionData,
  });
};

// Event with choice data
export const choiceEvent = async ({
  page,
  actionType,
  action,
  questionType,
  question,
  duration,
  choices,
}) => {
  let choiceData = {
    question_key: question.question_key,
    question_text: question.question,
    question_type: questionType,
    multi_choice: question.multi_choice,
    duration: duration,
    choices,
  };
  event({
    page,
    actionType,
    action,
    dataType: AADataType.CHOICE,
    data: choiceData,
  });
};

// Event with article data
export const articleEvent = async ({
  page,
  actionType,
  action,
  articleId,
  channel = null,
}) => {
  let articleData = {
    article_id: articleId,
    source: Capacitor.isNativePlatform() ? "MOBILE" : "WEB",
    channel,
  };
  event({
    page,
    actionType,
    action,
    dataType: AADataType.ARTICLE,
    data: articleData,
  });
};

export const questionFeedback = async ({
  page,
  actionType,
  action,
  questionKeys,
  questionType,
  answer,
  suggestion,
  entryType,
}) => {
  let questionFeedbackData = {
    question_key: questionKeys,
    question_type: questionType,
    answer: answer,
    suggestion: suggestion,
    entry_type: entryType,
  };
  event({
    page,
    actionType,
    action,
    dataType: AADataType.QUESTIONFEEDBACK,
    data: questionFeedbackData,
  });
};

export default {
  event,
  questionEvent,
  choiceEvent,
  articleEvent,
  questionFeedback,
};
