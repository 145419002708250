import React from "react";
import { Flex, Box, Button, Text } from "rebass";
import { Modal } from "semantic-ui-react";
import SeeMoreImageList from "./SeeMoreImageList";

const SeeMoreModal = ({ open, question, onClose }) => {
  const onClickDoNotUnderstand = () => {
    onClose();
  };
  return (
    <Modal open={open} closeOnDimmerClick={false} onClose={onClose}>
      <Modal.Content>
        <Box textAlign="start" p="8px">
          <Text fontFamily="Mitr" textAlign="center" color="#4d4d4d">
            <h4 style={{ fontSize: "1.3rem", fontWeight: 400 }}>
              {question.question}
            </h4>
          </Text>
          <Text fontFamily="Mitr" textAlign="center" color="#8a8a8a" mt={2}>
            <h5 style={{ fontSize: "1.2rem", fontWeight: 300 }}>
              {question.see_more}
            </h5>
          </Text>
          {question.question &&
            question.see_more_img &&
            question.see_more_img.length > 0 && (
              <Box m={16} textAlign="start">
                <SeeMoreImageList images={question.see_more_img} size="240px" />
              </Box>
            )}

          {/* Confirm Buttons */}
          <Flex mt={24} mb={16}>
            <Box width={1 / 2} mx="8px">
              <Button
                onClick={onClose}
                width={1}
                sx={{
                  borderRadius: "31px",
                  backgroundColor: "#075fd2",
                }}
              >
                <Text
                  fontFamily="Mitr"
                  textAlign="center"
                  fontSize={17}
                  color="#fff"
                >
                  เข้าใจแล้ว
                </Text>
              </Button>
            </Box>

            <Box width={1 / 2} mx="8px">
              <Button
                onClick={onClickDoNotUnderstand}
                width={1}
                sx={{
                  borderRadius: "31px",
                  backgroundColor: "#fff",
                  border: "1px solid #206ed1",
                }}
              >
                <Text
                  fontFamily="Mitr"
                  textAlign="center"
                  fontSize={17}
                  color="#1034a6"
                >
                  ยังไม่เข้าใจ
                </Text>
              </Button>
            </Box>
          </Flex>
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default SeeMoreModal;
