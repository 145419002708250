import React, { useState, useEffect } from "react";
import { Button, Flex, Image, Text } from "rebass";
// import { packageDetails } from "./constants/packageDetails.js";
import ClinicHeader from "./Components/ClinicHeader";
import clinic_background from "../../Assets/clinic/clinic_background.webp";
import ClinicBanner from "./Components/ClinicBanner";
import ClinicPackageDetailBox from "./Components/ClinicPackageDetailBox";
import ClinicPromotionCodeModal from "./Components/ClinicPromotionCodeModal.js";
import AgnosAnalytics, { AAActionType } from "../../AgnosAnalytics.js";
import { useLocation } from "react-router-dom";

const ClinicPromotionDetail = () => {
  const location = useLocation();
  const [clinicData, setClinicData] = useState([]);
  const [openPromotionModal, setOpenPromotionModal] = useState(false);
  const [packageDetails, setPackageDetails] = useState([]);

  useEffect(() => {
    if (location.state) {
      setClinicData(location.state.clinicData);
      setPackageDetails(location.state.clinicData.packages);
    }
  }, [location]);

  console.log(clinicData)

  const onClickReedeemButton = () => {
    setOpenPromotionModal(true);
    AgnosAnalytics.event({
      page: "promotion_information",
      actionType: AAActionType.CLICK,
      action: "clinic_code_redeem_fix_button",
      data: { clinic_name: clinicData.clinic_name },
    });
  };

  return (
    <Flex
      minHeight="100vh"
      width={1}
      flexDirection="column"
      alignItems="center"
      mb={20}
      css={{
        background: `url(${clinic_background}) no-repeat`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <ClinicHeader textHeader={"โค้ดส่วนลด"} />
      <Flex pt={4} width="90%" flexDirection="column">
        <ClinicBanner promotion={true} />
        <Flex
          mt={4}
          mb={100}
          pb={30}
          width={1}
          flexDirection="column"
          css={{
            background: "#fff",
            borderRadius: "16px",
            padding: "20px 24px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            textAlign: "start",
          }}
        >
          <Text
            variant="base"
            fontSize="14px"
            fontWeight={700}
            color="#001B52"
          >{`วันที่ใช้บริการได้ ${packageDetails?.start_date ?? '-'} - ${packageDetails?.end_date ?? "-"}`}</Text>
          {packageDetails.map((p, i) => (
            <ClinicPackageDetailBox
              key={i}
              title={p.title}
              packageDetails={p.details}
              packagePrice={p.price}
              packgaeDiscount={p.discount}
            />
          ))}
          <Text
            mt={2}
            variant="base"
            fontSize="16px"
            fontWeight={700}
            color="#001B52"
          >
            เงื่อนไขการใช้:
            <ul
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginBlockStart: "4px",
              }}
            >
              <li>ใช้งานได้ครั้งเดียวต่อ 1 สิทธิ์</li>
              <li>ไม่สามารถใช้ให้กับผู้อื่นได้</li>
            </ul>
          </Text>
          <Text
            mt={2}
            variant="base"
            fontSize="16px"
            fontWeight={700}
            color="#001B52"
          >
            สิทธิพิเศษที่ได้รับ:
            <ul
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginBlockStart: "4px",
              }}
            >
              <li> ส่วนลดผลิตภัณฑ์เสริมอาหารจากฮักษา</li>
              <li> ส่วนลดตรวจร่างกายเบื้องต้น</li>
            </ul>
          </Text>
        </Flex>
      </Flex>
      <Flex
        width={1}
        minHeight={90}
        justifyContent="center"
        alignItems="center"
        css={{
          background: "rgba(239, 242, 245, 0.5)",
          position: "fixed",
          bottom: 0,
          //   filter: "blur(10px)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Button
          minWidth={350}
          minHeight={48}
          fontSize="16px"
          fontWeight={700}
          onClick={onClickReedeemButton}
          css={{
            borderRadius: "12px",
            background: "#1A59C2",
          }}
        >
          รับโค้ดส่วนลด
        </Button>
      </Flex>
      {openPromotionModal && (
        <ClinicPromotionCodeModal openModal={setOpenPromotionModal} packageDetails={clinicData} />
      )}
    </Flex>
  );
};

export default ClinicPromotionDetail;
