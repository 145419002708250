import React from "react";
import SingleQuestion from "./SingleQuestion";
import SingleMultipleQuestion from "./SingleMultipleQuestion";
import MultipleSubQuestion from "./MultipleSubQuestion";
import MultipleMainQuestion from "./MultipleMainQuestion";

const questionType = {
  SINGLE: "SINGLE",
  MULTIPLE_SUB: "MULTIPLE_SUB",
  MULTIPLE_MAIN: "MULTIPLE_MAIN",
};

export const choiceType = {
  RADIO: "radio",
  IMAGE: "image",
  CHOICE_IMAGE: "choice_image",
  ASK_MORE: "ask_more",
  DATEPICKER: "datepicker",
};

const Questions = ({
  questionData,
  user,
  transition,
  setTransition,
  nextState,
  onAnswer,
}) => {
  if (!questionData) return null;

  switch (questionData.question_set_display) {
    case questionType.SINGLE:
      const question = questionData.questions[0];
      if (question?.multi_choice) {
        return (
          <SingleMultipleQuestion
            question={question}
            user={user}
            transition={transition}
            setTransition={setTransition}
            nextState={nextState}
            onAnswer={onAnswer}
          />
        );
      }
      return (
        <SingleQuestion
          question={question}
          user={user}
          transition={transition}
          setTransition={setTransition}
          nextState={nextState}
          onAnswer={onAnswer}
        />
      );
    case questionType.MULTIPLE_SUB:
      return (
        <MultipleSubQuestion
          questions={questionData.questions}
          user={user}
          transition={transition}
          setTransition={setTransition}
          nextState={nextState}
          onAnswer={onAnswer}
        />
      );
    case questionType.MULTIPLE_MAIN:
      return (
        <MultipleMainQuestion
          questionData={questionData}
          questions={questionData.questions}
          user={user}
          transition={transition}
          setTransition={setTransition}
          nextState={nextState}
          onAnswer={onAnswer}
        />
      );
    default:
      return (
        <SingleQuestion
          question={questionData.questions[0]}
          user={user}
          transition={transition}
          setTransition={setTransition}
          nextState={nextState}
          onAnswer={onAnswer}
        />
      );
  }
};

export default Questions;
