import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Flex, Text, Image, Button } from "rebass";

import { apiService } from "../../apiService";
import { authHeader } from "../../session";
import AgnosAnalytics, { AAActionType } from "../../AgnosAnalytics";
import ClinicSortModal, { clinicSortType } from "./Components/ClinicSortModal";
import AnimationLoader from "./Components/AnimationLoader";

import {
  setClinicFilter,
  setClinicSortType,
  setSearchText,
} from "../../reducers/clinicSlice";

import doctorFilter from "../../Assets/referral/doctor_filter.svg";
import sortArrow from "../../Assets/clinic/sort_arrow.svg";
import ClinicCard from "../../Components/HomeTabs/component/ClinicCard";
import ClinicHeader from "./Components/ClinicHeader";
import clinic_background from "../../Assets/clinic/clinic_background.webp";
import SearchBox from "../../Components/Referral/SearchBox";
import { APP_API_BASE_URL } from "../../config";

const openingHours = ["08:00,17:00", "17:00,22:00", "22:00,08:00"];

const ClinicAllRecommend = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const didMount = useRef(false);

  const dispatch = useDispatch();

  const [clinicData, setClinicData] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({});
  const [openSortModal, setOpenSortModal] = useState(false);
  const [openingTime, setOpeningTime] = useState(null);
  const [closeTime, setCloseTime] = useState(null);
  const [distance, setDistance] = useState(null);
  const [searchInput, setSearchInput] = useState();
  const [selectedSortType, setSelectedSortType] = useState(
    clinicSortType.DISTANCE
  );
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [scroll, setScroll] = useState(false);

  const { clinicFilter, sortType, clinicSearchText } = useSelector(
    (state) => state.clinic
  );

  const handleOnDocumentBottom = useCallback(() => {
    if (clinicData.length < count) {
      setLoading(true);
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, clinicData.length]);



  useEffect(() => {
    if (location.state) {
      // setClinicData(location.state?.clinicAllData);
      setCurrentPosition(location.state?.currentPosition);
    }

    setSearchInput(clinicSearchText);

    if (sortType) {
      setSelectedSortType(sortType);
    }

    if (clinicFilter.radius) {
      setDistance(clinicFilter.radius);
    }

    getAllClinicData();

    const time = Math.round(performance.now());

    // if (typeof window !== "undefined") {
    //   window.onscroll = function (ev) {
    //     if (
    //       window.innerHeight + window.scrollY >=
    //       document.body.scrollHeight * 0.5
    //     ) {
    //       setScroll(true);
    //     }
    //   };
    // }

    return () => {
      onHandleBeforeUnload(time);
    };
  }, [location]);

  useEffect(() => {
    if (scroll) {
      AgnosAnalytics.event({
        page: "Mhorprom-provide mental services",
        actionType: AAActionType.SCROLL,
        action: "all_recommend_clinic_view_dmind",
        data: {},
      });
    }
  }, [scroll]);

  useEffect(() => {
    dispatch(setSearchText(searchInput));
    getAllClinicData(searchInput, selectedSortType);
  }, [searchInput]);

  const prefillOpeningTimeData = (data) => {
    if (data[0] === openingHours[0]) {
      setOpeningTime("8:00");
      setCloseTime("17:00");
    }
    if (data[0] === openingHours[0] && data[1] === openingHours[1]) {
      setOpeningTime("8:00");
      setCloseTime("22:00");
    }
    if (
      data[0] === openingHours[0] &&
      data[1] === openingHours[1] &&
      data[2] === openingHours[2]
    ) {
      setOpeningTime(null);
      setCloseTime(null);
    }
    if (!data[0] && !data[1] && !data[2]) {
      console.log(distance);
      dispatch(
        setClinicFilter({
          radius: clinicFilter.radius,
          clinicType: clinicFilter.clinicType,
          openingDays: clinicFilter.openingDays,
          openingTimes: null,
          hasDiscount: clinicFilter.hasDiscount,
          serviceType: clinicFilter.serviceType,
        })
      );
    }
    if (data[1] === openingHours[1]) {
      setOpeningTime("17:00");
      setCloseTime("22:00");
    }
    if (data[2] === openingHours[2]) {
      setOpeningTime("22:00");
      setCloseTime("8:00");
    }
    if (data[1] === openingHours[1] && data[2] === openingHours[2]) {
      setOpeningTime("17:00");
      setCloseTime("8:00");
    }
    if (data[0] === openingHours[0] && data[2] === openingHours[2]) {
      setOpeningTime(null);
      setCloseTime(null);
    }
  };

  const getAllClinicData = async (input, sortBy) => {
    setLoadingData(true);
    console.log(clinicFilter.radius);

    let selectedOpeningTimes = clinicFilter?.openingTimes;
    if (selectedOpeningTimes) {
      prefillOpeningTimeData(selectedOpeningTimes);
    }

    try {
      let req = {
        data_source: "morphorm",
        page_number: pageNumber,
        page_size: 20,
        lat: currentPosition.lat,
        lng: currentPosition.lng,
        radius: clinicFilter.radius,
        clinic_types: clinicFilter.clinicType,
        opening_days: clinicFilter.openingDays,
        opening_time: openingTime,
        closing_time: closeTime,
        has_promotion: clinicFilter.hasDiscount,
        service_type: clinicFilter.serviceType,
        sort_by: sortBy,
        search_text: searchInput === "" ? null : searchInput,
      };

      const apiClinicData = await apiService.post(
        APP_API_BASE_URL + "/clinics/search",
        req,
        authHeader()
      );

      const clinicData = apiClinicData.data.clinics;
      if (apiClinicData.status === 200) {
        setLoadingData(false);
        setClinicData(clinicData);
      }

      if (count === null) {
        setCount(apiClinicData.data.metadata.page_count);
      }
      // setClinicData((prev) => [...prev, ...clinicData]);
      setLoading(false);
      didMount.current = true;
      // if (pageSize) {
      //   window.scrollTo({
      //     top: lastSelectedDoctorSearchCardRef.current.offsetTop - 200,
      //     left: 0,
      //     behavior: "smooth",
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleBeforeUnload = (time) => {
    AgnosAnalytics.event({
      page: "Mhorprom-provide mental services",
      action: "view_All_Recommended_Clinic",
      actionType: AAActionType.VIEW,
      data: { time_spent: Math.round(performance.now()) - time },
    });
  };

  const onClickClinicCard = (data) => {
    AgnosAnalytics.event({
      page: "Mhorprom-provide mental services",
      actionType: AAActionType.CLICK,
      action: `clinic_card_view_dmind`,
      data: { clinic_id: data.id },
    });
    navigate("/clinic_profile", {
      state: {
        clinicData: data,
        currentPosition,
      },
    });
  };

  const onClickSort = () => {
    setOpenSortModal(true);
  };

  const onClickFilter = () => {
    AgnosAnalytics.event({
      page: "Mhorprom-provide mental services",
      actionType: AAActionType.CLICK,
      action: "clinic_filter_DMIND",
      data: {},
    });
    navigate("/clinic_filter");
  };

  if (typeof window !== "undefined") {
    window.onscroll = function (ev) {
      if (!loading) {
        if (
          window.innerHeight + window.scrollY + 0.5 >=
          document.body.scrollHeight
        ) {
          handleOnDocumentBottom();
        }
      }
    };
  }

  return (
    <Flex
      minHeight="100vh"
      width={1}
      flexDirection="column"
      alignItems="center"
      backgroundColor="#F7F7F2"
    >
      <ClinicHeader textHeader={"คลินิกสุขภาพใจ"} />
      <Flex mt={2} flexDirection="column" width="90%">
        <SearchBox
          placeholderText="ค้นหาคลินิก"
          searchText={searchInput}
          setSearchText={setSearchInput}
          onClickClearSearchBox={() => setSearchInput("")}
          AAScript={() => {
            AgnosAnalytics.event({
              page: "Mhorprom-provide mental services",
              actionType: AAActionType.SEARCH,
              action: "clinic_search_DMIND",
              data: {},
            });
          }}
        />
        <Flex
          mt={3}
          justifyContent="flex-end"
          css={{
            gap: "12px",
          }}
        >
          <Button
            onClick={onClickSort}
            minWidth={110}
            display="flex"
            backgroundColor="rgba(255, 255, 255, 0.5)"
            padding="4px 12px"
            alignItems="center"
            sx={{
              boxShadow: "0px 4px 8px 0px rgba(143, 182, 232, 0.35)",
              borderRadius: "20px",
            }}
          >
            <Image alt="sort-arrow" src={sortArrow} pr={12} />
            <Text fontFamily="Anuphan" color="#1E1446" fontWeight={700}>
              จัดเรียง
            </Text>
          </Button>
          <Button
            onClick={onClickFilter}
            minWidth={110}
            display="flex"
            backgroundColor="rgba(255, 255, 255, 0.5)"
            padding="4px 12px"
            alignItems="center"
            sx={{
              boxShadow: "0px 4px 8px 0px rgba(143, 182, 232, 0.35)",
              borderRadius: "20px",
            }}
          >
            <Image alt="doctor-filter" src={doctorFilter} pr={12} />
            <Text fontFamily="Anuphan" color="#1E1446" fontWeight={700}>
              ตัวกรอง
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Flex
        mt={4}
        mb={70}
        flexDirection="column"
        alignItems="center"
        width="90%"
        css={{
          gap: "16px",
        }}
      >
        {loadingData ? (
          <AnimationLoader />
        ) : clinicData.length === 0 ? (
          <Text variant="base" fontSize="18px" fontWeight={600} color="#292A2A">
            ไม่พบข้อมูล
          </Text>
        ) : (
          clinicData.map((data, i) => (
            <ClinicCard
              key={i}
              clinicData={data}
              currentPosition={currentPosition}
              onClick={() => onClickClinicCard(data)}
            />
          ))
        )}
      </Flex>
      {openSortModal ? (
        <ClinicSortModal
          openModal={setOpenSortModal}
          selectedSortType={selectedSortType}
          onSelectSortType={(sortBy) => {
            setSelectedSortType(sortBy);
            dispatch(setClinicSortType(sortBy));
            setOpenSortModal(false);
            getAllClinicData(searchInput, sortBy);
          }}
        />
      ) : null}
      {loading && <AnimationLoader />}
    </Flex>
  );
};

export default ClinicAllRecommend;
