import React, { useEffect, useState } from "react";
import { Router, useLocation, useNavigate } from "react-router-dom";
import { Flex, Text, Image, Button } from "rebass";
import ReactGA from "react-ga4";
import MetaTags from "react-meta-tags";

import { eightQSeverity } from "../models/severity";

import MentalButton from "../Components/MentalButton";
import denied_consent_image from "../Assets/denine-consent-image.svg";
import moph_image from "../Assets/moph-text.png";
import result_image from "../Assets/send-result-image.svg";
import phone_icon from "../Assets/phone-icon.svg";

import DownloadAgnosModalForPsychiatrist, {
  modalType,
} from "../Components/Modal/DownloadAgnosForPsychiatrist";
import { Geolocation } from "@capacitor/geolocation";
import AgnosAnalytics, { AAActionType } from "../AgnosAnalytics";

const details = {
  severeCase: {
    text: "เราส่งข้อมูลให้เจ้าหน้าที่แล้ว",
    image: result_image,
    textDetail:
      "หมอพร้อมคิดว่าคุณต้องการ\nความช่วยเหลือ ณ ขณะนี้\nเราจะติดต่อกลับหาคุณโดยเร็วที่สุด",
    button1: "เสร็จสิ้นการใช้งาน",
    button2: "ค้นหาจิตแพทย์",
  },
  acceptCase: {
    text: "ขอบคุณสำหรับความคิดเห็น\nเราจะนำไปพัฒนาต่อ",
    image: moph_image,
    textDetail: "เพิ่มเพื่อนใน Line @MCH Group \nเพื่อติดต่อเจ้าหน้าที่",
    button1: "เพิ่มเพื่อน @MCH Group",
    button2: "ค้นหาจิตแพทย์",
  },
  defaultCase: {
    text: "ขอบคุณสำหรับความคิดเห็น\nเราจะนำไปพัฒนาต่อ",
    image: denied_consent_image,
    textDetail:
      "ถ้าไม่สบายใจก็สามารถโทรศัพท์\nเพื่อมาปรึกษาหรือศึกษาความรู้\nเกี่ยวกับสุขภาพจิตด้วยตนเองได้นะ",
    button1: "อ่านบทความเพิ่มเติม",
    button2: "ค้นหาจิตแพทย์",
  },
};

const CompleteContactPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [severity, setSeverity] = useState(eightQSeverity.NORMAL);
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [completeDetails, setCompleteDetails] = useState(details.defaultCase);
  const [downloadAgnosModal, setDownloadAgnosModal] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({});

  useEffect(() => {
    // const eightq = eightQSeverity.SEVERE;
    // const consent = true;
    const eightq = location.state?.severity ?? eightQSeverity.NORMAL;
    const consent = location.state?.consent ?? false;
    setSeverity(eightq);
    setConsentAccepted(consent);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    selectDetails(eightq, consent);
    getCurrentPosition();
  }, [location]);

  const getCurrentPosition = async () => {
    let pos = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
    setCurrentPosition({ lat: pos.coords.latitude, lng: pos.coords.longitude });
  };

  const selectDetails = (patientSeverity, consent) => {
    switch (patientSeverity) {
      case eightQSeverity.SEVERE:
        setCompleteDetails(details.severeCase);
        break;
      case eightQSeverity.MODERATE:
        if (consent) {
          setCompleteDetails(details.acceptCase);
        } else {
          setCompleteDetails(details.defaultCase);
        }
        break;
      default:
        setCompleteDetails(details.defaultCase);
        break;
    }
  };

  const onClickMore = () => {
    ReactGA.event({
      category: `complete`,
      action: "complete_page_click_readarticlemore",
      label: `complete_page_click_readarticlemore`,
    });
    switch (severity) {
      case eightQSeverity.SEVERE:
        onComplete();
        break;
      case eightQSeverity.MODERATE:
        navigateToLineOA();
        break;
      default:
        // navigate to dmind articles
        window.open("http://www.mhllibrary.com/dmind", "_self");
        break;
    }
  };

  const onComplete = () => {
    ReactGA.event({
      category: `complete`,
      action: "complete_page_click_complete",
      label: `complete_page_click_complete`,
    });
    window.open("https://line.me/R/ti/p/@mophconnect", "_self", "").close();
  };

  const onFindPsychiatrist = () => {
    AgnosAnalytics.event({
      page:
        severity === eightQSeverity.SEVERE
          ? "13_Result01"
          : severity === eightQSeverity.MODERATE && consentAccepted === true
          ? "AddLine"
          : "Dial",
      actionType: AAActionType.CLICK,
      action: "searchdoc_button_feedbackresult",
    });
    setDownloadAgnosModal(true);
  };

  const navigateToLineOA = () => {
    // navigate to line official account according to patient's province
    window.open("https://lin.ee/PIlq5Oh", "_blank");
  };

  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      minHeight="100vh"
      pb={40}
      css={{
        background: "#FFF",
      }}
    >
      <MetaTags>
        <title>Complete Contact Page | Mental Screening</title>
      </MetaTags>
      {/* Complete title and details */}
      <Flex
        width={"90%"}
        flexDirection="column"
        maxWidth={768}
        alignItems="center"
      >
        <Text
          mt={64}
          width="70%"
          fontFamily="Mitr"
          fontSize="1.2rem"
          fontWeight={500}
          color="#1E1446"
          textAlign="center"
          css={{
            whiteSpace: "pre",
          }}
        >
          {completeDetails.text}
        </Text>
        <Image mt={24} src={completeDetails.image} />

        {/* Contact 1323 */}
        {!consentAccepted && severity !== eightQSeverity.SEVERE && (
          <Flex mt={4} flexDirection="column" alignItems="center">
            <Text
              fontFamily="Mitr"
              fontSize="1rem"
              fontWeight={500}
              color="#1E1446"
              textAlign="center"
            >
              ติดต่อสายด่วนสุขภาพจิต
            </Text>
            <Button
              mt={2}
              display="flex"
              width={1}
              fontFamily="Mitr"
              fontWeight={500}
              color="#1E1446"
              alignItems="center"
              justifyContent="center"
              css={{
                border: "2px solid #F2E1C1",
                borderRadius: "2rem",
                background: "#fff",
              }}
              onClick={() => {
                window.open("tel:1323", "_self"); // call 1323
              }}
            >
              <Image src={phone_icon} />
              ติดต่อ 1323
            </Button>
          </Flex>
        )}

        {/* Complete Detail */}
        <Text
          mt={24}
          width="70%"
          fontFamily="Mitr"
          fontSize="1rem"
          fontWeight={300}
          color="#1E1446"
          textAlign="center"
          css={{
            whiteSpace: "pre",
          }}
        >
          {completeDetails.textDetail}
        </Text>
      </Flex>

      {/* Bottom View */}
      <Flex flexDirection="column" width={1}>
        {/* <MentalButton
          mt={40}
          width="80%"
          text="แนะนำคลีนิกสุขภาพใจ"
          textColor="#F2E1C1"
          backgroundColor="#1E1446"
          onClick={() => {
            navigate("/all_recommend_clinic", {
              state: {
                // clinicData,
                currentPosition,
              },
            });
            // navigate("/referral");
          }}
        /> */}
        <Flex
          flexDirection={
            severity === eightQSeverity.SEVERE ? "column-reverse" : "column"
          }
          width={1}
        >
          <MentalButton
            mt={2}
            width="80%"
            text={completeDetails.button1}
            textColor="#1E1446"
            backgroundColor="#FFF"
            border="2px solid #F2E1C1"
            onClick={onClickMore}
          />
          <MentalButton
            mt={2}
            width="80%"
            text={completeDetails.button2}
            textColor="#1E1446"
            backgroundColor="#FFF"
            border="2px solid #F2E1C1"
            onClick={onFindPsychiatrist}
          />
        </Flex>
      </Flex>
      <DownloadAgnosModalForPsychiatrist
        open={downloadAgnosModal}
        onClose={() => setDownloadAgnosModal(false)}
        type={modalType.getPsychiatrist}
      />
    </Flex>
  );
};

export default CompleteContactPage;
