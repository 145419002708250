import React, { forwardRef } from "react";
import { Checkbox, Label } from "@rebass/forms";
import { Text } from "rebass";

const ClinicCheckBox = forwardRef(
  (
    {
      label,
      name,
      value,
      checkedState,
      checked,
      onClick,
      onChange,
      defaultValue,
      defaultChecked,
    },
    ref
  ) => {
    return (
      <Label
        width={1}
        mt={2}
        css={{
          minHeight: "48px",
          display: "flex",
          padding: "0px 16px",
          alignItems: "center",
          background: checkedState ? "#F2E1C1" : "#FFF",
          borderRadius: "16px",
          boxShadow: "0px 4px 8px rgba(143, 182, 232, 0.08)",
        }}
      >
        <Checkbox
          onClick={onClick}
          onChange={onChange}
          name={name}
          ref={ref}
          value={value}
          defaultChecked={defaultChecked}
          defaultValue={defaultValue}
          checked={checked}
        />
        <Text variant="base" fontSize="16px" fontWeight={600} color="#1957BF">
          {label}
        </Text>
      </Label>
    );
  }
);

export default ClinicCheckBox;
