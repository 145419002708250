import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex, Text, Image } from "rebass";
import ReactGA from "react-ga4";
import MetaTags from "react-meta-tags";

import { authHeader, checkSessionByName, sessionName } from "../../session";
import { apiService } from "../../apiService";
import InternetErrorModal from "../../Components/Modal/InternetErrorModal";
import MentalButton from "../../Components/MentalButton";
import SymptomBox from "./Components/SymptomBox";
import agnosLogo from "../../Assets/logo/agnos-logo-text.webp";

const SymptomSelectionPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [recordSession, setRecordSession] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [symptoms, setSymptoms] = useState([]);
  const [isSymptomSelected, setIsSymptomSelected] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  useEffect(() => {
    // const checkUserSession = async () => {
    //   await checkSessionByName(sessionName.MENTAL, navigate, "/")
    // }
    // checkUserSession();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    getSymptoms();

    const userData =
      location.state?.user ?? JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    } else {
      navigate(`/${location.search}`);
    }

    setRecordSession(location.state?.recordSession);
    setStartDateTime(location.state?.startDateTime);
  }, [location]);

  const getSymptoms = async () => {
    const timer = setTimeout(() => {
      setErrorModal(true);
    }, 5000);
    await apiService
      .get(`/mental/symptoms`, authHeader())
      .then((response) => {
        const responseData = response.data;
        const symptomList = _.flatMap(responseData.symptoms, (symptom) => {
          const list = _.map(symptom.symptom_texts, (text) => {
            return {
              choice_id: symptom.choice_id,
              symptom_text: text,
              display_category: symptom.display_category,
            };
          });
          return list;
        });
        setSymptoms(symptomList);
        clearTimeout(timer);
      })
      .catch((err) => {
        if (
          err.message !== "Timeout" &&
          err.message !== "Network request failed"
        ) {
          clearTimeout(timer);
        }
        console.log(err);
      });
  };

  const onSelectSymptom = (symptom, selected) => {
    // update selected
    ReactGA.event({
      category: `survey`,
      action: `${symptom.symptom_text}`,
      label: `Selected Symptom ${symptom.symptom_text}`,
    });
    const symptomIndex = _.findIndex(
      symptoms,
      (s) => s.symptom_text === symptom.symptom_text
    );
    let symptomList = symptoms;
    symptomList[symptomIndex] = { ...symptom, selected };
    setSymptoms(symptomList);

    // check is any symptom selected
    let symptomSelected = selected
      ? true
      : _.find(symptoms, (s) => s.selected) != null;
    setIsSymptomSelected(symptomSelected);
  };

  const onClickNext = () => {
    // filter for selected symptoms
    ReactGA.event({
      category: `survey`,
      action: "Get Started",
      label: `Finish Selected Symptom`,
    });
    const filteredSymptoms = _.filter(symptoms, (s) => s.selected);
    const answers = _.map(filteredSymptoms, (symptom) => {
      return {
        order: 1,
        choice_id: symptom.choice_id,
        is_alias: false,
        is_chief: true,
        system: "mental",
        phase: 1,
      };
    });

    // get uniq list of choice_ids
    const filteredAnswers = _.uniqBy(answers, (answer) => answer.choice_id);

    navigate(`/question${location.search}`, {
      state: {
        user,
        recordSession,
        startDateTime,
        answers: filteredAnswers,
      },
    });
  };

  return (
    <Flex
      minHeight="100vh"
      flex={1}
      justifyContent="center"
      css={{
        background: "#FDFEF9",
      }}
    >
      <MetaTags>
        <title>Symptom Selection Page | Mental Screening</title>
      </MetaTags>
      <Flex
        pb={20}
        flexDirection="column"
        maxWidth={768}
        width="90%"
        css={{
          position: "relative",
        }}
      >
        <Image
          mt={36}
          width={82}
          src={agnosLogo}
          alignSelf="flex-start"
          css={{
            zIndex: 2,
          }}
        />
        <Text
          mt={3}
          fontFamily="Mitr"
          textAlign="center"
          color="#333333"
          css={{ whiteSpace: "pre-line", zIndex: 2 }}
        >
          <h4 style={{ fontSize: "1.3rem", fontWeight: 500 }}>
            {`ช่วงสัปดาห์ที่ผ่านมา ${
              user?.name ? user?.name : "คุณ"
            } \n มีความรู้สึกแบบไหนบ้างล่ะ?`}
          </h4>
        </Text>

        {/* Symptom Selection */}
        <Flex
          mt={36}
          mb={130}
          flexWrap="wrap"
          justifyContent="space-between"
          overflow="scroll"
          maxWidth={575}
          alignSelf="center"
          css={{
            zIndex: 2,
          }}
          p="8px"
        >
          {_.map(symptoms, (symptom, i) => {
            return (
              <SymptomBox
                key={`symptom-${i}`}
                index={i}
                symptom={symptom}
                onSelectSymptom={(selected) =>
                  onSelectSymptom(symptom, selected)
                }
              />
            );
          })}
        </Flex>
      </Flex>

      {/* Bottom Bar */}
      <Flex
        width={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        height={160}
        css={{
          position: "fixed",
          bottom: 0,
          zIndex: 3,
          background:
            " linear-gradient(175deg, rgba(196, 196, 196, 0) 20.72%, #DFD1B5 94.83%)",
        }}
      >
        <MentalButton
          width="80%"
          text="มาเริ่มกันเลย"
          backgroundColor="#F2E1C1"
          textColor="#1E1446"
          disabled={!isSymptomSelected}
          onClick={onClickNext}
        />
      </Flex>

      {/*  BG */}
      <svg
        width="184"
        height="156"
        viewBox="0 0 184 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
        }}
      >
        <path
          d="M146.014 232.466C185.995 221.927 218.789 196.983 232.503 151.18C244.639 110.772 246.877 64.9844 227.216 26.274C214.922 2.11134 192.62 -0.70237 167.962 1.47889C130.321 4.74422 92.6388 11.1909 55.1737 15.9837C16.5126 20.9953 1.1756 52.6492 0.401736 88.4944C-0.609467 137.887 7.47618 206.272 56.6393 231.086C66.3107 235.921 78.0263 237.996 88.8068 238.305C108.582 238.965 128 237.223 146.014 232.466Z"
          fill="#F2E1C1"
          fillOpacity="0.38"
        />
      </svg>
      <svg
        width="180"
        height="126"
        viewBox="0 0 180 126"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <path
          d="M30.3888 121.211C36.2498 119.476 41.9757 117.647 47.7016 115.818C72.5809 108.274 98.9846 106.744 123.899 98.7207C166.546 84.9937 197.242 37.4494 166.999 -6.08952C147.995 -33.4613 111.262 -49.6795 80.285 -58.7268C68.9138 -62.0735 57.2632 -64.3049 45.7075 -66.6714C14.0422 -72.8731 -23.57 -78.5105 -50.0751 -55.1601C-62.1079 -44.6459 -70.0511 -29.0395 -70.5612 -12.9536C-71.5432 16.723 -62.8317 46.2475 -54.3812 74.277C-40.989 118.388 -13.7791 134.374 30.3888 121.211Z"
          fill="#F2E1C1"
          fillOpacity="0.5"
        />
      </svg>
      <svg
        width="68"
        height="62"
        viewBox="0 0 68 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "fixed",
          top: 24,
          right: 34,
        }}
      >
        <path
          opacity="0.6"
          d="M31.0714 31.6906L28.8988 27.8463C28.153 26.5265 26.5463 25.9707 25.1517 26.55L23.748 27.133C22.3405 27.7176 20.7197 27.1458 19.9831 25.8049L19.1643 24.3143C18.4208 22.9608 16.778 22.3929 15.3647 23.0009L14.0631 23.561C12.6685 24.161 11.0466 23.6164 10.2895 22.2938L9.38738 20.718C8.63025 19.3954 7.00841 18.8508 5.61374 19.4509L1.82241 21.0821"
          stroke="#D8C6A4"
          strokeWidth="3.0845"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.6"
          d="M56.3542 47.3911C59.2176 48.3247 62.3029 46.7399 63.2454 43.8512C64.1879 40.9625 62.6308 37.8639 59.7674 36.9303C56.904 35.9966 53.8187 37.5815 52.8762 40.4701C51.9337 43.3588 53.4908 46.4574 56.3542 47.3911Z"
          fill="#F2E1C1"
        />
        <path
          opacity="0.6"
          d="M38.4526 48.2597C40.2747 48.8538 42.238 47.8454 42.8377 46.0074C43.4374 44.1694 42.4465 42.1977 40.6244 41.6036C38.8023 41.0095 36.839 42.0179 36.2393 43.8559C35.6396 45.6939 36.6305 47.6656 38.4526 48.2597Z"
          stroke="#D8C6A4"
          strokeWidth="1.2727"
          strokeMiterlimit="10"
        />
      </svg>

      <InternetErrorModal
        visible={errorModal}
        setVisible={() => setErrorModal(false)}
        buttonFunc={getSymptoms}
      />
    </Flex>
  );
};

export default SymptomSelectionPage;
