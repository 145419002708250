import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Flex, Text } from "rebass";
import ReactGA from "react-ga4";

import MultipleChoice from "./Choice/MultipleChoice";
import MentalButton from "../MentalButton";
import "../../Styles/questionTransition.css";
import { useLocation } from "react-router-dom";

const SingleMultipleQuestion = ({
  question,
  user,
  nextState,
  transition,
  setTransition,
  onAnswer,
}) => {
  const location = useLocation();
  const [questionTitle, setQuestionTitle] = useState("");
  const [currentChoices, setCurrentChoices] = useState({});
  const [noAllChoice, setNoAllChoice] = useState({});
  const [noAllSelected, setNoAllSelected] = useState(false);
  const [choiceSelected, setChoiceSelected] = useState(false);

  useEffect(() => {
    if (!question) return;
    setTransition(true);

    const title = _.replace(
      question?.question,
      new RegExp("NAME", "g"),
      user?.name
    );
    setQuestionTitle(title);
    setNoAllSelected(false);
    setNoAllChoice({});
  }, [question]);

  useEffect(() => {
    if (!question) return;
    setTransition(true);

    let choices = {};
    _.forEach(question.choices, (choice) => {
      choices = {
        ...choices,
        [`${choice.choice_key}`]: choice,
      };
    });
    setChoiceSelected(false);
    setCurrentChoices(choices);
  }, [question]);

  const onSelectAnswer = (choice) => {
    const previousChoice = currentChoices[`${choice.choice_key}`];
    const updatedChoices = {
      ...currentChoices,
      [`${choice.choice_key}`]: {
        ...choice,
        selected: !previousChoice?.selected,
      },
    };
    setCurrentChoices(updatedChoices);

    const filteredChoices = _.filter(
      updatedChoices,
      (choice) => choice && choice.selected
    );
    setChoiceSelected(filteredChoices.length > 0);
  };

  const onSelectNoAll = (choice) => {
    setNoAllChoice({ ...choice, selected: !noAllChoice.selected });
    setNoAllSelected(!noAllChoice.selected);
  };

  const onAnswerSingleMultipleChoice = () => {
    ReactGA.event({
      category: `survey`,
      action: "click_nextQuestion",
      label: `click_nextQuestion`,
    });
    if (noAllSelected) {
      onAnswer([noAllChoice]);
      return;
    }

    const filteredChoices = _.filter(
      currentChoices,
      (choice) => choice && choice.selected
    );
    onAnswer(filteredChoices);
  };

  const checkIsNoAll = (choice) => {
    switch (choice?.choice_text) {
      case "ไม่มีอาการข้างต้น":
      case "ไม่มีความคิดข้างต้น":
        return true;
      default:
        return false;
    }
  };
  // useEffect(() => {
  //   console.log(choiceSelected);
  // }, []);
  return (
    // <CSSTransition classNames={nextState} in={transition} timeout={200}>
      <Flex
        flexDirection="column"
        mt={22}
        minHeight={240}
        css={{
          background: "#FFF",
          border: "1px solid #EDEFF2",
          borderRadius: "16px",
          zIndex: 1,
        }}
        px={20}
        py={32}
      >
        {/* Title */}
        <Text fontFamily="Mitr" textAlign="center" color="#1E1446" mb={12}>
          <h4 style={{ fontSize: "1.3rem", fontWeight: 500 }}>
            {questionTitle}
          </h4>
        </Text>

        {/* Choices */}
        {_.map(question?.choices, (choice) => {
          return (
            <MultipleChoice
              key={`multi-choice-${choice.choice_id}`}
              choice={choice}
              isNoAll={checkIsNoAll(choice)}
              disabled={noAllSelected && !checkIsNoAll(choice)}
              onSelectNoAll={() => onSelectNoAll(choice)}
              onSelect={() => onSelectAnswer(choice)}
            />
          );
        })}

        {/* Bottom Bar */}
        <Flex
          width={1}
          height={100}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          css={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 3,
            background:
              " linear-gradient(175deg, rgba(196, 196, 196, 0) 20.72%, #DFD1B5 94.83%)",
          }}
        >
          <MentalButton
            width="80%"
            text="ต่อไป"
            backgroundColor="#F2E1C1"
            textColor="#1E1446"
            disabled={!choiceSelected && !noAllSelected}
            onClick={onAnswerSingleMultipleChoice}
          />
        </Flex>
      </Flex>
    // </CSSTransition>
  );
};

export default SingleMultipleQuestion;
