import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clinicFilter: {
    radius: null,
    clinicType: null,
    openingDays: null,
    openingTimes: null,
    hasDiscount: null,
    serviceType: null,
  },
  sortType: null,
  clinicSearchText: "",
};

export const clinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {
    setClinicFilter: (state, action) => {
      state.clinicFilter = action.payload;
    },
    setClinicSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setSearchText: (state, action) => {
      state.clinicSearchText = action.payload;
    },
    resetClinicFilter: () => initialState,
  },
});

export const { setClinicFilter, setClinicSortType, setSearchText, resetClinicFilter } = clinicSlice.actions;

export default clinicSlice.reducer;
