import React, { useState } from "react";
import { Box, Flex, Text, Image, Button } from "rebass";
import { Icon, Modal } from "semantic-ui-react";
import _ from "lodash";

function SeeMoreImageList({ images, size }) {
  const [index, setIndex] = useState(0);
  const [isZoom, setZoom] = useState(false);
  const [isBlur, setBlur] = useState(_.endsWith(images[0], "-blur.png"));
  const [cancelBlur, setCancelBlur] = useState(null);

  const changeToPreviousImage = () => {
    if (index <= 0) {
      setIndex(images.length - 1);
    } else {
      setIndex((index - 1) % images.length);
    }
  };

  const changeToNextImage = () => {
    setIndex((index + 1) % images.length);
  };

  const zoom = () => {
    setZoom(!isZoom);
  };

  const closeBlur = () => {
    setBlur(false);
    setCancelBlur(true);
  };

  const cancelBlurHandle = () => {
    setBlur(true);
    setCancelBlur(false);
  };

  return (
    <Box position="relative">
      <Box textAlign="center">
        <Flex alignItems="center" justifyContent="center" width={1}>
          <Box
            width="100%"
            maxWidth="375px"
            height="70px"
            display="flex"
            css={{
              position: "absolute",
              alignItems: "center",
              justifyContent: "left",
              paddingLeft: 15,
            }}
          >
            <Box
              sx={{
                zIndex: images.length + 1,
                position: "absolute",
                borderRadius: "50%",
                backgroundColor: "#fff",
                opacity: "0.8",
                boxShadow: "0 2px 4px 0 #dfdfdf",
              }}
              width="27px"
              height="27px"
              color="#6d7278"
            >
              <Box mt={1}></Box>
              <Icon name="chevron left" onClick={changeToPreviousImage} />
            </Box>
          </Box>

          <Flex
            sx={{
              position: "relative",
              zIndex: images.length,
              borderRadius: "28px",
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {cancelBlur && (
              <Box
                onClick={cancelBlurHandle}
                color="#fff"
                sx={{ position: "absolute", top: 12, right: 12 }}
              >
                <Icon name="hide" size="big" />
              </Box>
            )}
            {!isBlur && (
              <Flex
                justifyContent="center"
                alignItems="center"
                color="#fff"
                onClick={zoom}
                sx={{
                  position: "absolute",
                  width: "60%",
                  height: "60%",
                  zIndex: 10,
                }}
              >
                <Button
                  sx={{
                    background: "rgba(224, 224, 224, 0.15)",
                    borderRadius: "50%",
                    height: 27,
                    width: 27,
                    padding: 0,
                  }}
                >
                  <Icon name="zoom in" sx={{ height: 14, width: 14 }} />
                </Button>
              </Flex>
            )}

            <Image
              width={size}
              height="intrinsic"
              src={images[index]}
              sx={{
                borderRadius: "28px",
                border: "solid 1px #ffffff",
                filter: isBlur ? "blur(15px)" : null,
              }}
            />
          </Flex>
          {_.map(images, (image, i) => {
            if (i === 0 && isBlur)
              return (
                <Flex
                  flexDirection="column"
                  sx={{
                    position: "absolute",
                    background: "rgba(160, 160, 160, 0.38)",
                    borderRadius: "16px",
                    zIndex: images.length,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingBottom: 24,
                  }}
                  width={size}
                  height={size}
                >
                  <Box color="#fff">
                    <Icon name="unhide" size="huge"></Icon>
                  </Box>

                  <Box
                    mt={85}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      width: "90%",
                    }}
                  >
                    <Button
                      sx={{ width: "80%" }}
                      variant="buttonCloseBlur"
                      onClick={closeBlur}
                    >
                      <Flex
                        justifyItems="center"
                        sx={{
                          overflow: "hidden",
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        <Text fontSize={16}>กดดูรูป</Text>
                      </Flex>
                    </Button>
                  </Box>
                </Flex>
              );
            if (i !== 0 && !isBlur)
              return (
                <Box
                  sx={{
                    zIndex: images.length - i,
                    position: "absolute",
                    borderRadius: "28px",
                    overflow: "hidden",
                  }}
                  key={i}
                  maxWidth={`calc(100% - 112px)`}
                  width={size}
                  ml={`calc(4px*${i})`}
                  onClick={zoom}
                >
                  <Image
                    maxWidth={`calc(100% - 112px)`}
                    width={size}
                    height="intrinsic"
                    src={images[index]}
                    sx={{
                      borderRadius: "28px",
                      border: "solid 1px #ffffff",
                      filter: isBlur ? "blur(15px)" : null,
                    }}
                  />
                </Box>
              );
          })}
          <Box
            width="100%"
            maxWidth="375px"
            height="70px"
            display="flex"
            css={{
              position: "absolute",
              alignItems: "center",
              justifyContent: "right",
              paddingRight: 15,
            }}
          >
            <Box
              sx={{
                zIndex: images.length + 1,
                position: "absolute",
                borderRadius: "50%",
                backgroundColor: "#fff",
                opacity: "0.8",
                boxShadow: "0 2px 4px 0 #dfdfdf",
                right: "4%",
              }}
              width="27px"
              height="27px"
              color="#6d7278"
              pl={1}
              ml="2%"
              // ml={`calc(1*${size})`}
            >
              <Box mt={1}></Box>
              <Icon name="chevron right" onClick={changeToNextImage} />
            </Box>
          </Box>
        </Flex>
        <Box sx={{ position: "relative" }}>
          <Text variant="semibold" color="#86868c">
            {index + 1}/{images.length}
          </Text>
        </Box>
      </Box>
      <Modal open={isZoom} onClose={zoom} basic>
        <Box textAlign="center">
          <Flex alignItems="center" justifyContent="center">
            <Box sx={{ zIndex: 0 }} onClick={changeToPreviousImage}>
              <Icon name="chevron left" size="big" color="grey" inverted />
            </Box>
            <Box width="378px">
              <Image
                width="378px"
                src={images[index]}
                sx={{
                  filter: isBlur ? "blur(15px)" : null,
                }}
                height="intrinsic"
              />
              {isBlur && (
                <Box
                  sx={{
                    position: "absolute",
                    background: "rgba(160, 160, 160, 0.38)",
                    borderRadius: "16px",
                    zIndex: 1,
                    top: 0,
                  }}
                  maxWidth={378}
                  width="calc(100% - 65px)"
                  onClick={() => {
                    closeBlur();
                  }}
                >
                  <Box
                    height="1.5px"
                    backgroundColor=" #fff"
                    mt="4px"
                    mx={16}
                  ></Box>
                  <Button
                    variant="buttonSeeMoreImage"
                    onClick={() => {
                      closeBlur();
                    }}
                  >
                    <Flex alignItems="center">
                      <Text fontSize={16} mx={16}>
                        กดเพื่อดูรูปก่อน
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              )}
              <Text variant="semibold" color="#86868c">
                {index + 1}/{images.length}
              </Text>
            </Box>

            <Box sx={{ zIndex: 0 }} onClick={changeToNextImage}>
              <Icon name="chevron right" size="big" color="grey" inverted />
            </Box>
          </Flex>
          <Button
            onClick={zoom}
            sx={{
              marginTop: 40,
              fontSize: 40,
              fontWeight: 600,
              backgroundColor: "#FFF",
              color: "black",
              borderRadius: "50%",
              height: 56,
              width: 56,
            }}
          >
            &times;
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default SeeMoreImageList;
