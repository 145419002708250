import { Input } from "@rebass/forms";
import React from "react";
import { Box, Flex, Image } from "rebass";

import xGray from "../../Assets/x_gray.webp";
import magnifyingGlassIcon from "../../Assets/magnifying_glass_icon.svg";
import AgnosAnalytics, { AAActionType } from "../../AgnosAnalytics";

const SearchBox = ({
  searchBoxRef,
  searchText,
  setSearchText,
  onClickEnter,
  onClickClearSearchBox,
  placeholderText,
  AAScript,
}) => {
  return (
    <Flex
      bg="white"
      padding="12px 16px"
      alignItems="center"
      justifyContent="space-between"
      width={1}
      sx={{
        borderRadius: "24px",
        boxShadow: "0px 4px 8px rgba(143, 182, 232, 0.08)",
      }}
    >
      <Image
        alt="magnifying-glass"
        src={magnifyingGlassIcon}
        width="15px"
        height="15px"
      />
      <Input
        onClick={() => {
          if (AAScript) {
            AAScript();
          } else {
            AgnosAnalytics.event({
              page: "doctor_search_refferal",
              actionType: AAActionType.CLICK,
              action: "search_doctor_referral",
              data: {},
            });
          }
        }}
        onKeyPress={(e) => (onClickEnter ? onClickEnter(e) : undefined)}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        ref={searchBoxRef}
        value={searchText}
        type="text"
        width="80%"
        padding="0px"
        placeholder={placeholderText}
        color="#001B52"
        fontWeight="500"
        fontSize="16px"
        enterKeyHint="search"
        sx={{
          "::placeholder": {
            color: "#8FB6E8",
            fontFamily: "Anuphan",
          },
          border: "none",
        }}
      />
      {!searchText && <Box pl={10} />}
      {searchText && (
        <Image
          onClick={onClickClearSearchBox}
          alt="x-gray"
          src={xGray}
          width="15px"
          height="15px"
        />
      )}
    </Flex>
  );
};

export default SearchBox;
