import "../../../Styles/animationLoader.css";
import { Flex } from "rebass";

const AnimationLoader = () => {
  return (
    <Flex justifyContent='center' mt={2}>
      <div className='dual-ring'></div>
    </Flex>
  );
};
export default AnimationLoader;
