import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Flex, Text } from "rebass";
import ReactGA from "react-ga4";
import { CSSTransition } from "react-transition-group";

import { choiceType } from ".";
import SingleChoice from "./Choice/SingleChoice";
import MentalButton from "../MentalButton";

const MultipleSubQuestion = ({
  questions,
  user,
  transition,
  setTransition,
  nextState,
  onAnswer,
}) => {
  const [allChoiceSelected, setAllChoiceSelected] = useState(false);
  const [currentChoices, setCurrentChoices] = useState({});

  useEffect(() => {
    if (!questions) return;
    setTransition(true);

    let questionChoices = {};
    _.forEach(questions, (question) => {
      questionChoices = {
        ...questionChoices,
        [`${question.question_key}`]: null,
      };
    });

    setCurrentChoices(questionChoices);
  }, [questions]);

  const onSelectAnswer = (question, choice) => {
    const updatedChoices = {
      ...currentChoices,
      [`${question.question_key}`]: choice,
    };
    setCurrentChoices(updatedChoices);

    const filteredChoices = _.filter(
      updatedChoices,
      (questionChoice) => questionChoice != null
    );
    setAllChoiceSelected(filteredChoices.length === questions.length);
  };

  const onAnswerMultipleSub = () => {
    ReactGA.event({
      category: `click_nextQuestion`,
      action: "click",
      label: `click_nextQuestion`,
    });
    const filteredChoices = _.filter(
      currentChoices,
      (questionChoice) => questionChoice != null
    );
    onAnswer(filteredChoices);
  };

  return (
    // <CSSTransition classNames={nextState} in={transition} timeout={200}>
      <Flex flexDirection="column">
        {/* Sub Questions */}
        {_.map(questions, (question, index) => {
          const questionTitle = _.replace(
            question.question,
            new RegExp("NAME", "g"),
            user?.name
          );
          return (
            <Flex
              key={`multi-sub-question-${question.question_key}`}
              flexDirection="column"
              minHeight={240}
              css={{
                background: "#FFF",
                border: "1px solid #EDEFF2",
                borderRadius: "16px",
                zIndex: 1,
              }}
              mt={22}
              px={20}
              py={32}
            >
              {/* Title */}
              <Text
                fontFamily="Mitr"
                textAlign="center"
                color="#1E1446"
                mb={12}
              >
                <h4 style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                  {questionTitle}
                </h4>
              </Text>

              {/* Choices */}
              {_.map(question?.choices, (choice) => {
                switch (question.choice_type) {
                  case choiceType.RADIO:
                    return (
                      <SingleChoice
                        key={`choice-${choice.choice_id}`}
                        choice={choice}
                        selected={
                          currentChoices[`${question.question_key}`]
                            ?.choice_id === choice.choice_id
                        }
                        onSelect={() => onSelectAnswer(question, choice)}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Flex>
          );
        })}

        {/* Bottom Bar */}
        <Flex
          width={1}
          height={100}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          css={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 3,
            background:
              " linear-gradient(175deg, rgba(196, 196, 196, 0) 20.72%, #DFD1B5 94.83%)",
          }}
        >
          <MentalButton
            width="80%"
            text="ต่อไป"
            backgroundColor="#F2E1C1"
            textColor="#1E1446"
            disabled={!allChoiceSelected}
            onClick={onAnswerMultipleSub}
          />
        </Flex>
      </Flex>
    // </CSSTransition>
  );
};

export default MultipleSubQuestion;
