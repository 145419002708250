import React, { useState } from "react";
import { Flex, Text, Image } from "rebass";
import { Icon } from "semantic-ui-react";
import _ from "lodash";
import { set } from "js-cookie";

const Star = ({ onClick, ratingValue }) => {
  return (
    <Flex mt={4}>
      {_.map(new Array(5), (v, i) => (
        <Flex key={i} onClick={() => onClick(i + 1)}>
          <Icon
            size={i < ratingValue ? "huge" : "big"}
            color={i < ratingValue ? "yellow" : "grey"}
            name="star"
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Star;
