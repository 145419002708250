import React from "react";
import { Box, Flex } from "rebass";
import "../../Styles/questionTransition.css";

function ProgressBar({ progress }) {
  return (
    <Flex width={1} minWidth="72vw" sx={{ position: "relative" }}>
      <Box
        width={1}
        height={10}
        backgroundColor="#D8C6A4"
        sx={{ position: "absolute", left: 0, borderRadius: "5px" }}
      />
      <Box
        className="progress"
        width={progress / 100}
        height={10}
        backgroundColor="#FFF"
        sx={{ position: "absolute", left: 0, borderRadius: "5px" }}
      />
    </Flex>
  );
}

export default ProgressBar;
