import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { Flex, Text } from "rebass";
import { Label, Input } from "@rebass/forms";
import ReactGA from "react-ga4";
import MetaTags from "react-meta-tags";

import { hamdSeverity } from "../models/severity";
import NavBar from "../Components/Navbar";
import MultipleChoice from "../Components/Question/Choice/MultipleChoice";
import MentalButton from "../Components/MentalButton";
import feedbackBg from "../Assets/feedback-bg.svg";

const feedbackChoices = [
  {
    choice_id: 0,
    choice_key: "คำถามเยอะไป",
    choice_text: "คำถามเยอะไป",
  },
  {
    choice_id: 1,
    choice_key: "ใช้เวลานานไป",
    choice_text: "ใช้เวลานานไป",
  },
  {
    choice_id: 2,
    choice_key: "ไม่มีคำตอบที่ตรงใจ",
    choice_text: "ไม่มีคำตอบที่ตรงใจ",
  },
  {
    choice_id: 3,
    choice_key: "คำแนะนำไม่ชัดเจน",
    choice_text: "คำแนะนำไม่ชัดเจน",
  },
];

const UserFeedbackQuestion = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [rating, setRating] = useState(5);
  const [severity, setSeverity] = useState(hamdSeverity.NORMAL);
  const [consentAccepted, setConsentAccepted] = useState(false);

  const [currentChoices, setCurrentChoices] = useState({});
  const [choiceSelected, setChoiceSelected] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    setRating(location.state?.rating ?? 5);
    setSeverity(location.state?.severity ?? hamdSeverity.NORMAL);
    setConsentAccepted(location.state?.consent ?? false);

    setupChoices();
  }, [location]);

  const setupChoices = () => {
    let choices = currentChoices;
    _.forEach(feedbackChoices, (choice) => {
      choices = {
        ...choice,
        [`${choice.choice_key}`]: choice,
      };
    });

    setCurrentChoices(choices);
  };

  const onSelectChoice = (choice) => {
    // update choice selected value
    const previousChoice = currentChoices[`${choice.choice_key}`];
    const updatedChoices = {
      ...currentChoices,
      [`${choice.choice_key}`]: {
        ...choice,
        selected: !previousChoice?.selected,
      },
    };
    setCurrentChoices(updatedChoices);

    // check any choice selected
    const filteredChoices = _.filter(
      updatedChoices,
      (choice) => choice && choice.selected
    );
    setChoiceSelected(filteredChoices.length > 0);
  };

  const onClickNext = (skip) => {
    const feedback = {
      rating_score: rating,
      too_many_questions: !skip
        ? currentChoices[`${feedbackChoices[0].choice_key}`]?.selected ?? false
        : false,
      time_comsumption: !skip
        ? currentChoices[`${feedbackChoices[1].choice_key}`]?.selected ?? false
        : false,
      unsatisfied_answer: !skip
        ? currentChoices[`${feedbackChoices[2].choice_key}`]?.selected ?? false
        : false,
      ambiguous_instructions: !skip
        ? currentChoices[`${feedbackChoices[3].choice_key}`]?.selected ?? false
        : false,
    };

    navigate("/feedback-user", {
      state: {
        severity,
        consent: consentAccepted,
        feedback,
      },
    });
  };

  return (
    <Flex
      minHeight="100vh"
      width={1}
      flexDirection="column"
      alignItems="center"
      css={{
        background: `#FDFEF9 url(${feedbackBg}) no-repeat bottom left`,
      }}
    >
      <MetaTags>
        <title>User Feedback Question | Mental Screening</title>
      </MetaTags>
      <NavBar
        title="แสดงความคิดเห็น"
        backOnly
        fontSize="1.4rem"
        fontWeight={400}
      />
      <Flex
        pb={20}
        minHeight={"80vh"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={768}
        width="90%"
        fontFamily="Mitr"
        textAlign="center"
      >
        <Text>
          <h3
            style={{
              fontSize: "1.6rem",
              color: "#1E1446",
            }}
          >
            คุณอยากให้ปรับปรุง
            <br />
            ด้านใดมากที่สุด
          </h3>
        </Text>

        {/* Question */}
        <Flex flexDirection="column" width={1} mt={40}>
          {_.map(feedbackChoices, (choice) => {
            return (
              <MultipleChoice
                key={`feedback-${choice.choice_id}`}
                choice={choice}
                onSelect={() => onSelectChoice(choice)}
              />
            );
          })}
        </Flex>

        {/* Button */}
        <MentalButton
          width={1}
          mt={40}
          textColor="#1E1446"
          backgroundColor="#F2E1C1"
          text="ส่งความคิดเห็น"
          // disabled={!choiceSelected}
          onClick={() => onClickNext(false)}
        />
        <Text
          mt={48}
          fontSize="1rem"
          fontWeight={300}
          color="#1E1446"
          css={{
            textDecoration: "underline",
          }}
          onClick={() => onClickNext(true)}
        >
          ข้าม
        </Text>
      </Flex>
    </Flex>
  );
};

export default UserFeedbackQuestion;
