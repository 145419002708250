import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

// import diagnosisReducer from "./reducers/diagnosisSlice";
// import drugStoreReducer from "./reducers/drugStoreSlice";
// import historyReducer from "./reducers/historySlice";
// import userReducer from "./reducers/userSlice";
// import profileReducer from "./reducers/profileSlice";
// import doctorReducer from "./reducers/doctorSlice";
// import appReducer from "./reducers/appSlice";
// import forumReducer from "./reducers/forumSlice";
import clinicReducer from "./reducers/clinicSlice";

const rootReducer = {
  // user: userReducer,
  // diagnosis: diagnosisReducer,
  // drugStore: drugStoreReducer,
  // history: historyReducer,
  // profile: profileReducer,
  // doctor: doctorReducer,
  // app: appReducer,
  // forum: forumReducer,
  clinic: clinicReducer,
};

const env = process.env.REACT_APP_ENV;
const devPlugins =
  env === "dev" || env === "development" || env === "uat" ? [logger] : [];

export default configureStore({
  reducer: rootReducer,
  middleware: devPlugins,
});
