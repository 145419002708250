import React from "react";
import { Modal } from "semantic-ui-react";
import { Box, Flex, Text } from "rebass";

function ConfirmDialog({
  showConfirmDialog,
  title,
  message,
  cancelButtonTitle,
  confirmButtonTitle,
  onCancel,
  onConfirm,
}) {
  return (
    <>
      <Modal
        open={showConfirmDialog}
        onClose={onCancel}
        closeOnDimmerClick={false}
        style={{ maxWidth: "768px" }}
      >
        <Modal.Content>
          <Box
            display={title ? "block" : "none !important"}
            p={3}
            px={5}
            pb={0}
            textAlign="center"
          >
            <Text fontFamily="Mitr" color="#555555" fontSize="20px">
              {title ? title : "ยืนยัน"}
            </Text>
          </Box>
          <Box display={message ? "block" : "none"} m={2} textAlign="center">
            <Text fontFamily="Mitr" color="#979797" fontSize="16px">
              {message}
            </Text>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Flex textAlign="center">
            <Flex
              display={onCancel ? "block" : "none !important"}
              flex={1}
              textAlign="center"
              mb={2}
              onClick={onCancel}
              justifyContent="center"
              alignItems="center"
            >
              <Text fontFamily="Mitr" color="#e02020" fontSize="20px">
                {cancelButtonTitle ? cancelButtonTitle : "ยกเลิก"}
              </Text>
            </Flex>
            <Box
              display={onCancel ? "block" : "none !important"}
              width="1px"
              backgroundColor="#979797"
              mt="-1rem"
              opacity="0.34"
            ></Box>
            <Flex
              flex={1}
              textAlign="center"
              mb={2}
              onClick={onConfirm}
              justifyContent="center"
              alignItems="center"
            >
              <Text fontFamily="Mitr" color="#0091ff" fontSize="20px">
                {confirmButtonTitle ? confirmButtonTitle : "ตกลง"}
              </Text>
            </Flex>
          </Flex>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default ConfirmDialog;
