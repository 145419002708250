import React, { useState, useEffect } from "react";
import { Label } from "@rebass/forms";
import { Image } from "rebass";
import symptomColors from "../../../models/symptomColors";
import checkIcon from "../../../Assets/check-icon.svg";
import "./symptombox.css";

const SymptomBox = ({ index, symptom, onSelectSymptom }) => {
  const [checked, setChecked] = useState(false);
  const [color, setColor] = useState("transparent");

  useEffect(() => {
    setColor(
      index < symptomColors.length
        ? symptomColors[index]
        : Math.floor(Math.random() * 16777215).toString(16)
    );
  }, [symptom]);

  return (
    <Label
      width="48%"
      minWidth={135}
      maxWidth={170}
      height={82}
      mb={3}
      pl={3}
      pb={2}
      alignItems="flex-end"
      css={{
        background: color,
        borderRadius: "0.8rem",
        fontFamily: "Mitr",
        color: "#fff",
        position: "relative",
        transform: checked ? "scale(1.05)" : "none",
        userSelect: "none",
        // zIndex: 10
      }}
    >
      <input
        type="checkbox"
        className="symptombox"
        onClick={(e) => {
          setChecked(e.target.checked);
          onSelectSymptom(e.target.checked);
        }}
      />
      {symptom?.symptom_text}
      <Image
        // display={checked ? "block" : "none"}
        src={checkIcon}
        css={{
          position: "absolute",
          right: 8,
          top: 8,
          opacity: checked ? 1 : 0,
          transition: ".3s",
        }}
      />
    </Label>
  );
};

export default SymptomBox;
