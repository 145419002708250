import React from "react";
import { Button } from "rebass";

const MentalButton = ({
  onClick,
  disabled,
  width,
  mt,
  text,
  border,
  backgroundColor,
  textColor,
  type,
}) => {
  return (
    <Button
      mt={mt}
      type={type}
      width={width}
      maxWidth={500}
      alignSelf="center"
      disabled={disabled}
      css={{
        fontFamily: "Mitr",
        fontSize: "1.3rem",
        fontWeight: 500,
        border: disabled ? "2px solid #CACACA" : border,
        borderRadius: "0.8rem",
        background: disabled ? "#fff" : backgroundColor,
        color: disabled ? "#CACACA" : textColor,
        boxShadow: "0px 2px 8px rgba(124, 124, 124, 0.15)",
        "&:hover": {
          transform: "scale(0.95)",
        },
      }}
      p={14}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default MentalButton;
