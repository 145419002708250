import React from "react";
import { Flex, Image, Text } from "rebass";

const ClinicPackageDetailBox = ({
  title,
  packageDetails,
  packagePrice,
  packgaeDiscount,
}) => {
  return (
    <>
      <Text
        mt={2}
        variant="base"
        fontSize="20px"
        fontWeight={700}
        color="#001B52"
      >
        {title}
      </Text>
      <Flex
        mt={2}
        minHeight={400}
        width={1}
        css={{
          border: "1px solid #ADDAF8",
          borderRadius: "16px",
          padding: "16px 12px",
          textAlign: "left",
        }}
      >
        <Text
          mt={2}
          variant="base"
          fontSize="16px"
          fontWeight={700}
          color="#001B52"
        >
          รายการตรวจ
          <ul
            style={{
              paddingInlineStart: "32px",
              marginBlockStart: "12px",
            }}
          >
            {packageDetails.map((detail, i) => (
              <li
                key={i}
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {detail}
              </li>
            ))}
          </ul>
        </Text>
      </Flex>
      <Text
        my={2}
        variant="base"
        fontSize="16px"
        fontWeight={700}
        color="#001B52"
        css={{
          gap: "4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 16C12.8868 16 16.5077 12.3791 16.5077 8C16.5077 3.62089 12.8791 0 8.49226 0C4.11315 0 0.5 3.62089 0.5 8C0.5 12.3791 4.12089 16 8.5 16ZM8.60832 12.6267C8.39168 12.6267 8.22921 12.472 8.22921 12.2476V11.7215H6.99903C6.56576 11.7215 6.28723 11.4352 6.28723 10.9942V5.12186C6.28723 4.68085 6.5735 4.38685 6.99903 4.38685H8.22921V3.80658C8.22921 3.58221 8.39168 3.42747 8.60832 3.42747C8.81721 3.42747 8.97195 3.58221 8.97195 3.80658V4.38685H9.11895C10.3878 4.38685 11.2234 5.16828 11.2234 6.26692C11.2234 6.94004 10.8056 7.55899 10.2176 7.83752C11.03 8.07737 11.5716 8.75822 11.5716 9.62476C11.5716 10.9014 10.6277 11.7215 9.14217 11.7215H8.97195V12.2476C8.97195 12.472 8.81721 12.6267 8.60832 12.6267ZM7.51741 7.44294H8.22921V5.43907H7.52514L7.51741 7.44294ZM8.97195 7.40426C9.6528 7.31915 10.001 6.96325 10.001 6.39845C10.001 5.84139 9.64507 5.51644 8.97195 5.45455V7.40426ZM7.52514 10.6692L8.22921 10.6615V8.45648H7.52514V10.6692ZM8.97195 10.6538C9.87718 10.6151 10.3259 10.236 10.3259 9.54739C10.3259 8.88201 9.87718 8.51064 8.97195 8.45648V10.6538Z"
            fill="#1A59C2"
          />
        </svg>
        <span
          style={{
            textDecoration: "line-through",
          }}
        >
          {packagePrice} บาท
        </span>
        <span
          style={{
            color: "#D21C1C",
          }}
        >
          เหลือ {packgaeDiscount} บาท
        </span>
      </Text>
    </>
  );
};

export default ClinicPackageDetailBox;
