import React from "react";
import { Modal } from "semantic-ui-react";
import { Box, Text, Button, Flex, Image } from "rebass";
import modalLogo1 from "../../Assets/logo/download_agnos_mental-min.webp";
import modalLogo2 from "../../Assets/logo/download_agnos_mental_2-min.webp";
import MentalButton from "../MentalButton";
import { AGNOS_DOWNLOAD_URL } from "../../config";
import AgnosAnalytics, { AAActionType } from "../../AgnosAnalytics";

export const modalType = {
  getPsychiatrist: "getPsychiatrist",
  getDiscount: "getDiscount",
};

const DownloadAgnosModal = ({ open, onClose, type }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      basic
      style={{
        background: "#FFFFFF",
        borderRadius: "16px 16px 0px 0px",
        width: "100vw",
        bottom: "0px",
        marginBottom: "0px",
        paddingBottom: "48px",
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        marginTop="48px"
        style={{
          gap: "40px",
        }}
      >
        <Text
          fontFamily="Mitr"
          fontSize="1.2rem"
          fontWeight={600}
          color="#525050"
          lineHeight="2rem"
          textAlign="center"
        >
          ท่านสามารถ
          {type === modalType.getPsychiatrist
            ? "ค้นหาจิตแพทย์"
            : "รับส่วนลดพิเศษ"}
          ได้จาก <br />
          แอปพลิเคชัน Agnos
          {/* ดาวน์โหลด Agnos เพื่อ
          <br />
          ค้นหาจิตแพทย์ที่เหมาะกับคุณ */}
        </Text>
        {type === modalType.getPsychiatrist && <Image src={modalLogo1} />}
        {type === modalType.getDiscount && <Image src={modalLogo2} />}
        {/* <Text
          fontFamily="Mitr"
          fontSize="1.2rem"
          fontWeight={300}
          color="#525050"
          lineHeight="2rem"
          textAlign="center"
        >
          ท่านสามารถค้นหาจิตแพทย์หลังจาก
          <br />
          ดาวน์โหลด Agnos แอปพลิเคชั่น
        </Text> */}
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        <MentalButton
          mt={40}
          width="80%"
          text="ดาวน์โหลด Agnos"
          textColor="#F2E1C1"
          backgroundColor="#1E1446"
          onClick={() => {
            AgnosAnalytics.event({
              page: "searchdoc_download_modal",
              actionType: AAActionType.CLICK,
              action: "searchdoc_button_downloadmodal",
              data: {},
            });
            window.open(AGNOS_DOWNLOAD_URL, "_blank");
          }}
        />
        <MentalButton
          mt={3}
          width="80%"
          text="ยกเลิก"
          textColor="#1E1446"
          backgroundColor="#FFF"
          border="2px solid #F2E1C1"
          onClick={onClose}
        />
      </Flex>
    </Modal>
  );
};

export default DownloadAgnosModal;
