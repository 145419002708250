import React, { forwardRef } from "react";
import { Flex, Image, Text } from "rebass";
import plusDarkBlue from "../../../Assets/plus_dark_blue.svg";
import minusDarkBlue from "../../../Assets/minus_dark_blue.svg";

const ClinicFilterHeader = forwardRef(
  ({ onClick, index, headerText, name, value }, ref) => {
    return (
      <Flex
        onClick={onClick}
        justifyContent="space-between"
        alignItems="center"
        width={1}
        name={name}
        ref={ref}
        value={value}
        css={{
          display: "flex",
          border: "0px solid",
          background: "transparent",
          padding: "0px 0px 0px 0px",
        }}
      >
        <Flex alignItems="center">
          <Text
            variant="base"
            color="primary3"
            fontSize={16}
            fontWeight={700}
            pr="10px"
            textAlign="center"
          >
            {headerText}
          </Text>
        </Flex>
        <Image src={index ? minusDarkBlue : plusDarkBlue} />
      </Flex>
    );
  }
);

export default ClinicFilterHeader;
