import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Flex, Text, Image, Button } from "rebass";
import { Label, Input } from "@rebass/forms";
import ReactGA from "react-ga4";
import MetaTags from "react-meta-tags";

import { apiService } from "../apiService";
import { authHeader, verifyAuthentication } from "../session";

import MentalButton from "../Components/MentalButton";
import ConfirmDialog from "../Components/Modal/ConfirmDialog";
import InternetErrorModal from "../Components/Modal/InternetErrorModal";
import doctorAppointMentImage from "../Assets/doctor-appointment.svg";
import editIcon from "../Assets/edit-icon.svg";
import mophLogo from "../Assets/logo/moph-logo.webp";
import agnosLogo from "../Assets/logo/agnos-logo-text.webp";
import dmindLogo from "../Assets/logo/dmind-logo.webp";
import heartIcon from "../Assets/heart-circle-icon.svg";
import MentalReferralClinicButton from "../Components/MentalReferralClinicButton";
import AgnosAnalytics, { AAActionType } from "../AgnosAnalytics";
import { Geolocation } from "@capacitor/geolocation";

import ReactPixel from "react-facebook-pixel";
import { PIXEL_ID } from "../config";
import Cookies from "js-cookie";

const IntroductionLandingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({});
  const [userProvince, setUserProvince] = useState(null);

  ReactPixel.init(PIXEL_ID, { debug: true, autoConfig: true });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const channel = searchParams.get("channel");
    const nonce = searchParams.get("nonce");
    const version = searchParams.get("version");
    localStorage.setItem("version", version);
    localStorage.setItem("channel", channel);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    ReactPixel.pageView();
    ReactPixel.track("DMIND_start", {});
    callConversion();
    AgnosAnalytics.event({
      page: "introduction",
      actionType: AAActionType.CLICK,
      action: "DMIND_start",
      data: {
        province: userProvince,
        source_url: "https://mental.agnoshealth.com/",
      },
    });
    // const authCode = searchParams.get("auth_code");
    // const nonce = searchParams.get("nonce");

    // if (authCode && nonce) {
    //   verifyAuthentication(authCode, nonce, displayErrorPopup);
    // } else {
    //   setErrorMessage("authentication not provided");
    // }
    getCurrentPosition();
  }, [location]);

  const callConversion = async () => {
    const fbp = Cookies.get("_fbp");
    var req = {
      event_name: "DMIND_start",
      event_source_url: "https://mental.agnoshealth.com/",
      fbp: fbp,
    };
    await apiService
      .post(`/analytic/conversion`, req)
      .then(() => console.log(`[conversion] ${JSON.stringify(req)}`))
      .catch((err) => console.error(err));
  };

  const getCurrentPosition = async () => {
    let pos = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
    setCurrentPosition({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    getProvince(pos.coords.latitude, pos.coords.longitude);
  };

  const displayErrorPopup = (errorResponse) => {
    setErrorMessage(errorResponse.error_message);
  };

  const inputName = (e) => {
    setName(e.target.value);
  };

  const onClickSubmit = () => {
    ReactGA.event({
      category: `welcome`,
      action: "submit_name",
      label: `submit_name`,
    });
    const user = { name };
    localStorage.setItem("user", JSON.stringify(user));

    genRecordSession(user);
  };

  const genRecordSession = async (user) => {
    const timer = setTimeout(() => {
      setErrorModal(true);
    }, 5000);
    await apiService
      .get(`/mental/gen_record_session`, authHeader())
      .then((response) => {
        const responseData = response.data;
        if (responseData.record_session) {
          navigate(`/symptom-selection${location.search}`, {
            state: {
              user,
              recordSession: responseData.record_session,
              startDateTime: new Date().toISOString(),
            },
          });
        }
        clearTimeout(timer);
      })
      .catch((err) => {
        if (
          err.message !== "Timeout" &&
          err.message !== "Network request failed"
        ) {
          clearTimeout(timer);
        }
        console.log(err);
      });
  };

  const getProvince = async (lat, lng) => {
    const req = {
      location: { latitude: lat, longitude: lng },
    };
    await apiService
      .post("/analytic/get_province", req, authHeader())
      .then((response) => {
        setUserProvince(response.data.province);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Flex
      minHeight="100vh"
      flex={1}
      justifyContent="center"
      css={{
        background: "#FDFEF9",
      }}
    >
      <MetaTags>
        <title>Introduction Landing Page | Mental Screening</title>
      </MetaTags>
      <Flex pb={20} flexDirection="column" maxWidth={768} width="90%">
        <Image mt={36} width={62} src={mophLogo} alignSelf="flex-start" />
        <Image height={320} src={doctorAppointMentImage} />
        <Text fontFamily="Mitr" textAlign="center">
          <h3 style={{ fontSize: "1.3rem" }}>
            สวัสดี, อยากให้เราเรียกคุณว่าอะไรดี ?
          </h3>
          <span style={{ fontSize: "1rem", fontWeight: 300 }}>
            มาใช้เวลาทำความเข้าใจความรู้สึกตัวเองกันเถอะ
          </span>
        </Text>
        <Label
          display="flex"
          mt={3}
          width={"80%"}
          maxWidth="400px"
          alignItems="center"
          alignSelf="center"
          css={{
            position: "relative",
            borderBottom: "1px solid #9E9E9E",
          }}
        >
          <Input
            css={{
              border: "none",
              textAlign: "center",
              outline: "none",
              fontFamily: "Mitr",
              color: "#1E1446",
            }}
            onChange={(e) => inputName(e)}
            onClick={() =>
              ReactGA.event({
                category: `welcome`,
                action: "enter_name",
                label: `enter_name`,
              })
            }
          />
          <Image
            src={editIcon}
            css={{
              position: "absolute",
              right: 0,
            }}
          />
        </Label>
        <MentalButton
          mt={36}
          width="90%"
          text="เริ่มสำรวจความรู้สึกของคุณ"
          onClick={onClickSubmit}
          disabled={name === ""}
          backgroundColor="#F2E1C1"
          textColor="#1E1446"
          border="2px solid #F2E1C1"
        />
        {/* <MentalReferralClinicButton
          onClick={() => {
            AgnosAnalytics.event({
              page: "introduction",
              actionType: AAActionType.CLICK,
              action:
                location.pathname + location.search + "_to_mentalReferral",
              data: { province: userProvince },
            });
            navigate("/all_recommend_clinic", {
              state: {
                // clinicData,
                currentPosition,
              },
            });
            // navigate("/referral", { state: { root: "/" } });
          }}
          width="90%"
          mt={16}
          text="แนะนำคลินิกสุขภาพใจ"
          border="1px solid #333333"
          backgroundColor="white"
          textColor="#1E1446"
          fontWeight={400}
          fontSize="0.9rem"
          icon={heartIcon}
          gap={8}
          padding={6.5}
        /> */}
        <Text
          mt={56}
          textAlign="center"
          color="#788295"
          fontFamily="Nunito"
          fontSize="0.8rem"
        >
          Powered by
        </Text>
        <Flex
          mt={3}
          width="70%"
          maxWidth={250}
          justifyContent="space-around"
          alignSelf="center"
          alignItems="center"
        >
          <Image width={94} height={35} src={agnosLogo} />
          <Image width={94} src={dmindLogo} />
        </Flex>
        <Flex justifyContent="center">
          <Text
            my={36}
            textAlign="center"
            color="#1E1446"
            fontFamily="Mitr"
            fontSize="1rem"
            fontWeight={400}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              window.location.replace(
                "https://www.agnoshealth.com/services/mental_screening?utm_source=DMIND_website&utm_medium=text_link&utm_campaign=DMIND_landing"
              )
            }
          >
            รู้จัก DMIND ให้มากขึ้น
          </Text>
        </Flex>
      </Flex>

      {/* Error Popup Dialog */}
      <ConfirmDialog
        showConfirmDialog={!_.isEmpty(errorMessage)}
        title="เกิดข้อผิดพลาด"
        message="กรุณาลองใหม่อีกครั้ง"
        confirmButtonTitle="ปิด"
        onConfirm={() => {
          setErrorMessage(null);
          window.opener = null;
          window.open("", "_self");
          window.close();
        }}
      />

      <InternetErrorModal
        visible={errorModal}
        setVisible={() => setErrorModal(false)}
        buttonFunc={onClickSubmit}
      />
    </Flex>
  );
};

export default IntroductionLandingPage;
