// import * as jwt from "jsonwebtoken";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { apiService } from "./apiService";

const sessionName = {
  MENTAL: "session_mental",
  MENTAL_DOCTOR: "session_mental_doctor",
};

var session = null;

const checkSession = async () => {
  if (session) {
    return checkSessionByName(session.name);
  } else {
    return checkSessionByName(sessionName.MENTAL);
  }
};

const checkSessionByName = async (name, navigate, redirectPath) => {
  const sessionData = Cookies.get(name);
  session = sessionData ? JSON.parse(sessionData) : null;
  if (!session || session.name !== name) {
    if (navigate) navigate(redirectPath);
    return null;
  }

  let decoded = jwt_decode(session.refresh_token);
  let expire = decoded["exp"] * 1000;
  // check session expire
  if (expire > Date.now()) {
    return session;
  }

  await refreshSession();

  if (!session && navigate) {
    navigate(redirectPath);
  }

  return session;
};

const refreshSession = async () => {
  if (!session) return;

  // refresh session
  switch (session.name) {
    case sessionName.MENTAL:
      await verifyAuthentication(session.auth_code, session.nonce);
      break;
    case sessionName.MENTAL_DOCTOR:
      await refreshUserToken();
      break;
    default:
      break;
  }
}

const setSessionByName = (name, s) => {
  s.name = name;
  Cookies.set(name, JSON.stringify(s));
  session = s;
};

const clearSession = () => {
  if (!session) return;
  clearSessionByName(session.name);
};

const clearSessionByName = (name) => {
  Cookies.remove(name);
  session = null;
};

const verifyAuthentication = async (authCode, nonce, displayErrorPopup) => {
  let req = {
    auth_code: authCode,
    nonce: nonce,
  };
  await apiService
    .post(`/mental/auth_verify`, req)
    .then((response) => {
      session = {
        name: sessionName.MENTAL,
        ...response.data,
        auth_code: authCode,
        nonce,
      };
      Cookies.set(sessionName.MENTAL, JSON.stringify(session));
      return session;
    })
    .catch((err) => {
      console.log(err);
      if (displayErrorPopup) {
        displayErrorPopup(err.respose.data);
      }
      clearSession();
      return null;
    });
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const refreshUserToken = async () => {
  if (!session) {
    // localStorage.removeItem("user");
    return;
  }
  let req = { refresh: session.refresh_token };
  await apiService
    .post(`/token/refresh`, req)
    .then(async (response) => {
      let responseData = response.data;
      const sessionData = {
        access_token: responseData.access,
        refresh_token: responseData.refresh,
      };
      setSessionByName(session.name, sessionData);
      await sleep(1000);
    })
    .catch((error) => {
      console.log({ ...error });
      if (error.response && error.response.data) {
        if (error.response.data.code === "token_not_valid") {
          clearSessionByName(sessionName.MENTAL_DOCTOR);
          // localStorage.removeItem("user");
        }
      }
    });
};

var authHeader = () => {
  return session
    ? {
        headers: {
          Authorization: "JWT " + session.access_token,
          "Content-Type": "application/json",
        },
      }
    : {
        headers: {
          "Content-Type": "application/json",
        },
      };
};

export {
  verifyAuthentication,
  authHeader,
  checkSession,
  checkSessionByName,
  refreshSession,
  setSessionByName,
  clearSession,
  clearSessionByName,
  session,
  sessionName,
};
