import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import { Flex, Image } from "rebass";
import { Player } from "@lottiefiles/react-lottie-player";
import ReactGA from "react-ga4";
import MetaTags from "react-meta-tags";

import { authHeader, checkSessionByName, sessionName } from "../session";
import { apiService } from "../apiService";
import ProgressNavbar from "../Components/Navbar/ProgressNavbar";
import InternetErrorModal from "../Components/Modal/InternetErrorModal";
import back_icon from "../Assets/back-arrow-icon.svg";
import Question from "../Components/Question";

const SymptomQuestion = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [recordSession, setRecordSession] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [questionData, setQuestionData] = useState(null);

  const [transition, setTransition] = useState(false);
  const [nextState, setNextState] = useState("next");
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  useEffect(() => {
    // const checkUserSession = async () => {
    //   await checkSessionByName(sessionName.MENTAL, navigate, "/")
    // }
    // checkUserSession();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });

    const userData =
      location.state?.user ?? JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    } else {
      navigate(`/${location.search}`);
    }
    setRecordSession(location.state?.recordSession);
    setStartDateTime(location.state?.startDateTime);

    const order = location.state?.order;
    setCurrentOrder(order ? order + 1 : 1);

    setAnswers(location.state?.answers ?? []);
  }, [location]);

  useEffect(() => {
    if (answers.length === 0) {
      return;
    }
    getNextQuestion();
  }, [answers]);

  const getNextQuestion = async () => {
    const timer = setTimeout(() => {
      setErrorModal(true);
    }, 5000);
    const req = { answers: answers };
    await apiService
      .post(`/mental/next_question`, req, authHeader())
      .then((response) => {
        const responseData = response.data;
        setQuestionData(responseData);
        handleQuestion(responseData);
        clearTimeout(timer);
      })
      .catch((err) => {
        if (
          err.message !== "Timeout" &&
          err.message !== "Network request failed"
        ) {
          clearTimeout(timer);
        }
        console.log(err);
      });
  };

  const handleQuestion = (question) => {
    // if last question
    if (!question.has_next) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate(`/diagnosis-complete${location.search}`, {
          state: {
            user,
            recordSession,
            startDateTime,
            answers,
          },
        });
      }, 2000);
    }
  };

  const onAnswer = (choices) => {
    const newAnswers = _.map(choices, (choice) => {
      return {
        order: currentOrder,
        choice_id: choice.choice_id,
        is_alias: false,
        is_chief: false,
        system: questionData.system,
        phase: questionData.phase,
      };
    });

    navigate(`/question${location.search}`, {
      state: {
        user,
        recordSession,
        startDateTime,
        order: currentOrder,
        answers: _.concat(answers, newAnswers),
      },
    });

    setTransition(false);
    setNextState("next");
  };

  const onClickBack = () => {
    ReactGA.event({
      category: `back_qeustion`,
      action: "click",
      label: `back_question`,
    });
    setNextState("return");
    setTransition(false);
    navigate(-1);
  };

  return (
    <Flex
      flex={1}
      flexDirection="column"
      minHeight="100vh"
      backgroundColor="#FDFEF9"
      alignItems="center"
      pb={100}
      sx={{ position: "relative" }}
    >
      <MetaTags>
        <title>Symptom Question | Mental Screening</title>
      </MetaTags>
      {/* Progress */}
      <ProgressNavbar progress={questionData?.progress} />

      <Flex
        flexDirection="column"
        width={1}
        maxWidth={400}
        p={16}
        css={{ position: "relative" }}
      >
        {/* Back */}
        <Flex
          mt={24}
          width={32}
          height={32}
          alignItems="center"
          justifyContent="center"
          css={{ borderRadius: "16px", border: "1px solid #EDEFF2", zIndex: 1 }}
        >
          <Image src={back_icon} width={32} height={32} onClick={onClickBack} />
        </Flex>

        {/* Question */}
        <Question
          questionData={questionData}
          user={user}
          transition={transition}
          setTransition={setTransition}
          nextState={nextState}
          onAnswer={onAnswer}
        />
      </Flex>

      {/* Success */}
      {showSuccess && (
        <Flex
          justifyContent="center"
          alignItems="center"
          width={1}
          height="90vh"
          sx={{ position: "absolute", zIndex: 2 }}
        >
          <Player
            autoplay={true}
            loop={false}
            src={"https://assets4.lottiefiles.com/packages/lf20_mkelocrp.json"}
            style={{ height: "300px", width: "300px" }}
          />
        </Flex>
      )}

      {/* BG */}
      <Flex
        height="100vh"
        sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <svg
          width="66"
          height="52"
          viewBox="0 0 66 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 124,
            right: 20,
          }}
        >
          <path
            d="M42.603 33.8949C35.0563 42.3037 46.9609 57.2636 58.9718 50.1259C75.5533 40.7393 54.0824 19.4239 42.603 33.8949Z"
            fill="#F2E1C1"
            fillOpacity="0.34"
          />
          <path
            d="M22.7616 5.74998C9.75925 25.8426 51.3468 39.6203 49.9572 14.1697C49.7587 0.966021 30.6026 -5.44446 22.7616 5.74998Z"
            fill="#F2E1C1"
            fillOpacity="0.34"
          />
          <path
            d="M5.45437 19C8.46673 19 10.9087 16.5376 10.9087 13.5C10.9087 10.4624 8.46673 8 5.45437 8C2.442 8 0 10.4624 0 13.5C0 16.5376 2.442 19 5.45437 19Z"
            fill="#F2E1C1"
            fillOpacity="0.34"
          />
          <path
            d="M27.7112 44C29.6282 44 31.1822 42.433 31.1822 40.5C31.1822 38.567 29.6282 37 27.7112 37C25.7942 37 24.2402 38.567 24.2402 40.5C24.2402 42.433 25.7942 44 27.7112 44Z"
            fill="#F2E1C1"
            fillOpacity="0.34"
          />
          <path
            d="M11.1528 34.5537C12.4948 33.852 14.0996 34.8384 14.0794 36.3527L14.0242 40.5L13.969 44.6462C13.9488 46.1607 12.3178 47.104 10.995 46.3663L7.03233 44.1562L3.41606 42.1097C2.04152 41.3319 2.0686 39.3425 3.4638 38.6023L7.13222 36.6562L11.1528 34.5537Z"
            fill="#F2E1C1"
            fillOpacity="0.34"
          />
        </svg>

        <svg
          width="191"
          height="127"
          viewBox="0 0 191 127"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        >
          <path
            d="M94.3973 232.466C134.378 221.927 167.172 196.983 180.887 151.18C193.022 110.772 195.261 64.9844 175.6 26.274C163.305 2.11134 141.004 -0.70237 116.345 1.47889C78.7046 4.74422 41.0221 11.1909 3.55697 15.9837C-35.1041 20.9953 -50.4411 52.6492 -51.215 88.4944C-52.2262 137.887 -44.1405 206.272 5.02256 231.086C14.694 235.921 26.4096 237.996 37.1901 238.305C56.9651 238.965 76.3832 237.223 94.3973 232.466Z"
            fill="#F2E1C1"
            fillOpacity="0.38"
          />
        </svg>
      </Flex>

      <InternetErrorModal
        visible={errorModal}
        setVisible={() => setErrorModal(false)}
        buttonFunc={getNextQuestion}
      />
    </Flex>
  );
};

export default SymptomQuestion;
