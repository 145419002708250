import React, { useEffect, useState } from "react";
import { Flex, Text, Image } from "rebass";
import { useNavigate, useLocation } from "react-router-dom";
import { Geolocation } from "@capacitor/geolocation";
import { parse } from "date-fns";
import default_clinic from "../../../Assets/clinic/default_clinic.png";

const dayInTh = {
  Mon: "จันทร์",
  Tue: "อังคาร",
  Wed: "พุธ",
  Thu: "วันพฤหัสบดี",
  Fri: "ศุกร์",
  Sat: "เสาร์",
  Sun: "อาทิตย์",
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const ClinicCard = ({ clinicData, currentPosition, onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [clinicPoisition, setClinicPoisition] = useState({});
  const [clinicDistance, setClinicDistance] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openingDisplayStr, setOpeningDisplayStr] = useState(null);

  useEffect(() => {
    if (clinicData) {
      setClinicPoisition({ lat: clinicData.lat, lng: clinicData.lng });
    }
    checkOpeningHours(clinicData);
  }, [location, clinicData]);

  function haversineDistance(mk1, mk2) {
    if (!mk1.lat || !mk1.lng) return null;
    if (!mk2.lat || !mk2.lng) return null;

    const R = 6371.071; // Radius of the Earth in kilo
    const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    const d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return !isNaN(d) ? d.toFixed() : null;
  }

  const checkOpeningHours = (data) => {
    if (!data || !data.opening_hours) return;

    let clinicOpening = [];
    days.forEach((day) => {
      const today = data.opening_hours.filter((d) => d.day === day);
      const todayHourList = today?.map((d) =>
        d.opening_time ? `${d.opening_time}-${d.closing_time} น.` : ""
      );

      const todayHoursStr = today[0]?.opening_time
        ? `${dayInTh[today[0]?.day]} ${todayHourList.join(", ")}`
        : `${dayInTh[today[0]?.day]} ปิดทำการ`;

      clinicOpening = [
        ...clinicOpening,
        {
          day: today[0]?.day,
          openingHoursDisplay: todayHoursStr,
          closed: !today[0]?.opening_time || !today[0]?.closing_time,
        },
      ];

      calculateOpeningDisplayString(today, today[0]?.day);
    });
  };

  const calculateOpeningDisplayString = (todayOpeningHours, day) => {
    const currentDate = new Date();
    const isSunday = currentDate.getDay() === 0 && day === days[6]; // if today is sunday
    const isToday = day === days[currentDate.getDay() - 1] || isSunday;

    if (!isToday) return;

    todayOpeningHours.forEach((opening) => {
      if (!opening.opening_time || !opening.closing_time) {
        setIsOpen(false);
        setOpeningDisplayStr(null);
        return;
      }

      const currentTime = currentDate.getHours();
      const openingDate = parse(
        opening.opening_time.slice(0, -3),
        "HH:mm",
        new Date()
      );
      const openingTime = openingDate.getHours();
      let closingDate = parse(
        opening.closing_time.slice(0, -3),
        "HH:mm",
        new Date()
      );
      const closingTime = closingDate.getHours();

      if (closingTime < openingTime) {
        closingDate.setDate(closingDate.getDate() + 1);
      }

      let openingStr = "";
      if (currentDate < openingDate) {
        setIsOpen(false);
        openingStr = `จะเปิดเมื่อ ${opening.opening_time}`;
      } else if (currentDate >= openingDate && currentDate < closingDate) {
        setIsOpen(true);
        openingStr = `เปิดจนถึง ${opening.closing_time}`;
      } else {
        setIsOpen(false);
        openingStr = `ปิดเมื่อ ${opening.closing_time}`;
      }

      setOpeningDisplayStr(openingStr.slice(0, -3));
    });
  };

  return (
    <Flex
      flexDirection="column"
      minHeight={170}
      // minWidth={[250,330]}
      maxWidth={384}
      width="95%"
      onClick={onClick}
      css={{
        background: "rgba(255, 255, 255)",
        boxShadow: "0px 4px 8px rgba(143, 182, 232, 0.08)",
        borderRadius: "16px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Image
        maxHeight={120}
        height={120}
        width={1}
        src={
          clinicData?.site_images?.length > 0
            ? clinicData.site_images[0]
            : default_clinic
        }
        onError={(e) => (e.target.src = default_clinic)}
        css={{
          borderRadius: "16px 16px 0 0",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <Flex
        flexDirection="column"
        css={{
          padding: "8px 16px",
          textAlign: "start",
          background: "#fff",
        }}
      >
        <Text
          variant="base"
          fontSize={["14px", "12px", "14px"]}
          fontWeight={700}
          color="#001B52"
          css={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {clinicData?.clinic_name}
        </Text>
        <Text
          variant="base"
          fontSize="14px"
          fontWeight={400}
          color="#9E9E9E"
          css={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {clinicData?.clinic_specialty ? clinicData.clinic_specialty : ""}
        </Text>
      </Flex>

      {/* description section */}
      {/* discount */}
      <Flex
        css={{
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "90px 120px 0 0",
          borderColor: "#ff0000 transparent transparent transparent ",
          position: "absolute",
          top: 0,
          left: 0,
          visibility: clinicData?.has_promotion ? "visible" : "hidden",
        }}
      >
        <Text
          width={60}
          variant="base"
          color="#fff"
          fontSize="14px"
          fontWeight={700}
          css={{
            position: "absolute",
            top: "-80px",
            left: "12px",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {clinicData?.promotions[0]?.promotion_tag}
        </Text>
      </Flex>

      {/* open/close section */}
      <Flex
        minWidth={137}
        minHeight={24}
        justifyContent="center"
        alignItems="center"
        css={{
          background: "#F2E1C1",
          padding: "0px 8px",
          borderRadius: "12px",
          position: "absolute",
          bottom: 65,
          left: 20,
          textAlign: "start",
          color: "#333333",
          fontWeight: 700,
          fontSize: "14px",
          fontFamily: "Anuphan",
          gap: "4px",
          flex: "1 1 auto",
          whiteSpace: "nowrap",
        }}
      >
        <Text
          variant="base"
          as="span"
          style={{
            width: "11px",
            height: "11px",
            borderRadius: "50%",
            background: isOpen ? "#60CB6A" : "#F54800",
          }}
        />
        {isOpen ? "เปิดอยู่" : "ปิดอยู่"}{" "}
        <Text
          variant="base"
          fontWeight={500}
          fontSize="14px"
          css={{
            color: "#333333",
            lineHeight: 0,
          }}
        >
          {openingDisplayStr}
        </Text>
      </Flex>

      {/* distance section */}
      <Flex
        minWidth={65}
        minHeight={24}
        justifyContent="center"
        alignItems="center"
        css={{
          background: "rgba(255, 255, 255, 0.84)",
          padding: "0px 8px",
          borderRadius: "12px",
          position: "absolute",
          top: 5,
          right: 10,
          textAlign: "start",
          color: "#001B52",
          fontWeight: 700,
          fontSize: "14px",
          fontFamily: "Anuphan",
          gap: "4px",
          flex: "1 1 auto",
          whiteSpace: "nowrap",
        }}
      >
        <Flex
          display={clinicData?.online ? "flex" : "none !important"}
          alignItems="center"
          pr={1}
          css={{
            borderRight: "1px solid #001B52",
            gap: "2px",
          }}
        >
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="7" cy="7.5" r="7" fill="#206ED1" />
            <path
              d="M10.5222 9.2454L9.4802 8.2034C9.10806 7.83126 8.47542 7.98013 8.32657 8.4639C8.21492 8.79884 7.84278 8.98492 7.50786 8.91047C6.76357 8.7244 5.75879 7.75684 5.57272 6.97534C5.46108 6.6404 5.68437 6.26826 6.01929 6.15663C6.50308 6.00777 6.65193 5.37513 6.27979 5.00299L5.2378 3.961C4.94008 3.7005 4.49352 3.7005 4.23302 3.961L3.52595 4.66807C2.81888 5.41235 3.60038 7.38469 5.34944 9.13376C7.0985 10.8828 9.07085 11.7015 9.81513 10.9572L10.5222 10.2502C10.7827 9.95247 10.7827 9.5059 10.5222 9.2454Z"
              fill="white"
            />
          </svg>
          <Text variant="base" fontSize="12px" fontWeight={500}>
            มีบริการออนไลน์
          </Text>
        </Flex>
        <svg
          width="11"
          height="15"
          viewBox="0 0 11 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.61725 14.6207C5.61725 14.6207 10.6692 9.54956 10.9488 5.95658C11.204 2.67677 8.89251 0 5.61725 0C2.342 0 -0.228884 2.67599 0.0161729 5.95658C0.279646 9.4837 5.61725 14.6207 5.61725 14.6207ZM5.55515 8.1875C7.00862 8.1875 8.18688 7.00923 8.18688 5.55577C8.18688 4.10231 7.00862 2.92404 5.55515 2.92404C4.10169 2.92404 2.92343 4.10231 2.92343 5.55577C2.92343 7.00923 4.10169 8.1875 5.55515 8.1875Z"
            fill="#001B52"
          />
        </svg>
        {`${haversineDistance(clinicPoisition, currentPosition) ?? "-"}  กม.`}
      </Flex>
    </Flex>
  );
};

export default ClinicCard;
