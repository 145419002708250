import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import ReactGA from "react-ga4";
import { Flex, Text, Image, Button } from "rebass";
import MetaTags from "react-meta-tags";

import { apiService } from "../apiService";
import { dmindApiService } from "../DmindApiService";
import { authHeader, checkSessionByName, sessionName } from "../session";
import { eightQSeverity, hamdSeverity } from "../models/severity";
import ConsentModal from "../Components/ConsentModal";
import ConfirmDialog from "../Components/Modal/ConfirmDialog";
import DownloadAgnosModalForPsychiatrist, {
  modalType,
} from "../Components/Modal/DownloadAgnosForPsychiatrist";

import severe_focus_ring from "../Assets/severity/severe-focus-ring.svg";
import moderate_focus_ring from "../Assets/severity/moderate-focus-ring.svg";
import mild_focus_ring from "../Assets/severity/mild-focus-ring.svg";
import normal_focus_ring from "../Assets/severity/normal-focus-ring.svg";

import severity_severe_icon from "../Assets/severity/severity-severe-icon.svg";
import severity_moderate_icon from "../Assets/severity/severity-moderate-icon.svg";
import severity_mild_icon from "../Assets/severity/severity-mild-icon.svg";
import severity_normal_icon from "../Assets/severity/severity-normal-icon.svg";
import arrow_right_icon from "../Assets/arrow-right-circle.svg";
import arrow_right_white from "../Assets/arrow-right-white.svg";
import heartIcon from "../Assets/heart-circle-icon.svg";
import MentalReferralClinicButton from "../Components/MentalReferralClinicButton";
import AgnosAnalytics, { AAActionType } from "../AgnosAnalytics";
import MentalButton from "../Components/MentalButton";

import { Geolocation } from "@capacitor/geolocation";

import ReactPixel from "react-facebook-pixel";
import { PIXEL_ID } from "../config";
import Cookies from "js-cookie";

const hamdSeverityData = {
  SEVERE: {
    textColor: "#A63F26",
    focusRingImg: severe_focus_ring,
    focusRingBottom: -20,
    focusRingHorizontal: "30%",
    severityIcon: severity_severe_icon,
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(166, 63, 38, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(137.15% 148.29% at 25% 32.33%, rgba(255, 165, 60, 0.3) 0%, rgba(0, 0, 0, 0) 100%), #A63F26",
    border: "2px solid #A85D10",
    text: "หมอพร้อมคิดว่าคุณต้องการ\nความช่วยเหลือ ณ ขณะนี้\nเราจะติดต่อกลับหาคุณโดยเร็วที่สุด",
  },
  MODERATE: {
    textColor: "#CC6600E5",
    focusRingImg: moderate_focus_ring,
    focusRingBottom: -20,
    focusRingHorizontal: "20%",
    severityIcon: severity_moderate_icon,
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(208, 117, 24, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(180.22% 125.8% at 22.66% 27.41%, rgba(255, 232, 198, 0.3) 0%, rgba(0, 0, 0, 0) 100%), rgba(204, 102, 0, 0.9)",
    border: "2px solid #A85D10",
    text: "หมอพร้อมคิดว่าคุณมีความไม่สบายใจเราจะติดต่อกลับไปหาคุณภายใน 1-2 วัน",
  },
  MILD: {
    textColor: "#CCA300",
    focusRingImg: mild_focus_ring,
    severityIcon: severity_mild_icon,
    focusRingBottom: -20,
    focusRingHorizontal: "30%",
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(204, 163, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(156.25% 524.98% at 13.83% 28.57%, rgba(218, 220, 132, 0.4) 0%, rgba(0, 0, 0, 0) 100%), #CCA300",
    border: "2px solid #948922",
    text: "หมอพร้อมคิดว่าคุณยังอยู่ในเกณฑ์ปกติแต่ถ้าไม่สบายใจก็สามารถโทรศัพท์เพื่อมาปรึกษาหรือศึกษาความรู้เกี่ยวกับสุขภาพจิตด้วยตนเองได้นะ",
  },
  NORMAL: {
    textColor: "#69A868",
    focusRingBottom: 20,
    focusRingHorizontal: "24%",
    focusRingImg: normal_focus_ring,
    severityIcon: severity_normal_icon,
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(130, 220, 126, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(156.25% 524.98% at 13.83% 28.57%, rgba(218, 220, 132, 0.4) 0%, rgba(0, 0, 0, 0) 100%), #62BC6A",
    border: "2px solid #528951",
    text: "หมอพร้อมคิดว่าคุณยังอยู่ในเกณฑ์ปกติ\nแต่ถ้าไม่สบายใจก็สามารถโทรศัพท์\nเพื่อมาปรึกษาหรือศึกษาความรู้\nเกี่ยวกับสุขภาพจิตด้วยตนเองได้นะ",
  },
};

const convertSeverityToColor = (hamd) => {
  switch (hamd) {
    case hamdSeverity.SEVERE:
      return "red";
    case hamdSeverity.MODERATE:
      return "orange";
    case hamdSeverity.MILD:
      return "yellow";
    case hamdSeverity.NORMAL:
      return "green";
    default:
      return "green";
  }
};

const DiagnosisResultPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [diagnosisRecordId, setDiagnosisRecordId] = useState(null);
  const [diagnosisResult, setDiagnosisResult] = useState(null);
  const [hamd, setHamd] = useState(null);
  const [eightq, setEightq] = useState(null);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [downloadAgnosModal, setDownloadAgnosModal] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({});

  ReactPixel.init(PIXEL_ID, { debug: true, autoConfig: true });
  useEffect(() => {
    // const checkUserSession = async () => {
    //   await checkSessionByName(sessionName.MENTAL, navigate, "/")
    // }
    // checkUserSession();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });

    const recordId = location.state?.recordId;
    setDiagnosisRecordId(recordId);

    const result = JSON.parse(localStorage.getItem("diagnosis_result"));
    // if (!result || result.record_id !== recordId) {
    //   setErrorMessage("ข้อมูลเกิดข้อผิดพลาด");
    //   return;
    // }

    setDiagnosisResult(result);
    setHamd(result?.hamd_severity_level);
    setEightq(result?.eightq_severity_level);

    ReactPixel.pageView();
    ReactPixel.track(getPixelName(result?.hamd_severity_level), {});
    callConversion(getPixelName(result?.hamd_severity_level));

    getCurrentPosition();
  }, [location]);

  const callConversion = async (eventName) => {
    const fbp = Cookies.get("_fbp");
    var req = {
      event_name: eventName,
      event_source_url: "https://mental.agnoshealth.com/diagnosis-result",
      fbp: fbp,
    };
    await apiService
      .post(`/analytic/conversion`, req)
      .then(() => console.log(`[conversion] ${JSON.stringify(req)}`))
      .catch((err) => console.error(err));
  };

  const getPixelName = (hamd) => {
    switch (hamd) {
      case hamdSeverity.SEVERE:
        return "DMIND_complete_group1";
      case hamdSeverity.MODERATE:
        return "DMIND_complete_group2";
      case hamdSeverity.MILD:
        return "DMIND_complete_group3";
      case hamdSeverity.NORMAL:
        return "DMIND_complete_group4";
      default:
        return "DMIND_complete_group4";
    }
  };

  useEffect(() => {
    if (!diagnosisRecordId) return;
    // getDiagnosisRecord(diagnosisRecordId);
  }, [diagnosisRecordId]);

  const getCurrentPosition = async () => {
    let pos = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
    setCurrentPosition({ lat: pos.coords.latitude, lng: pos.coords.longitude });
  };

  useEffect(() => {
    if (!hamd || !currentPosition) return;

    getProvince(currentPosition.lat, currentPosition.lng);
  }, [hamd, currentPosition]);

  const getProvince = async (lat, lng) => {
    const req = {
      location: { latitude: lat, longitude: lng },
    };
    await apiService
      .post("/analytic/get_province", req, authHeader())
      .then((response) => {
        AgnosAnalytics.event({
          page: "diagnosis_result",
          actionType: AAActionType.SUBMIT,
          action: getPixelName(hamd),
          data: {
            province: response.data.province,
            source_url: "https://mental.agnoshealth.com/diagnosis-result",
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDiagnosisRecord = async (id) => {
    await apiService
      .get(`/mental/mental_records/${id}`, authHeader())
      .then((response) => {
        const responseData = response.data;
        setDiagnosisResult(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickNext = () => {
    ReactGA.event({
      category: `dmind`,
      action: "result_page_click_next",
      label: `result_page_click_next`,
    });
    if (!diagnosisResult) return;

    switch (eightq) {
      case eightQSeverity.SEVERE:
        onAcceptConsent();
        break;
      case eightQSeverity.MODERATE:
        setIsConsentModalOpen(true);
        break;
      default:
        navigate("/feedback-rating", {
          state: { severity: eightq, consent: false },
        });
        break;
    }
  };

  const onAcceptConsent = async () => {
    ReactGA.event({
      category: `feedback`,
      action: "consent_modal_click_accept",
      label: `consent_modal_click_accept`,
    });
    if (!diagnosisResult) return;
    setConsentAccepted(true);

    await dmindApiService
      .get(
        `/mental/mental_records/${diagnosisResult.record_id}/consent`,
        authHeader()
      )
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === "success") {
          switch (eightq) {
            case eightQSeverity.SEVERE:
            case eightQSeverity.MODERATE:
              navigate("/personal-info", { state: { severity: eightq } });
              break;
            default:
              navigateToFeedback();
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateToFeedback = () => {
    ReactGA.event({
      category: `dmind`,
      action: "consent_modal_click_notaccept",
      label: `consent_modal_click_notaccept`,
    });
    navigate("/feedback-rating", {
      state: { severity: eightq, consent: consentAccepted },
    });
  };

  const onClickOpenchat = () => {
    AgnosAnalytics.event({
      page: "diagnosis_result",
      actionType: AAActionType.CLICK,
      action: "click_line_link",
      label: `${diagnosisResult.hamd_severity_level}`,
      data: {
        source_url: window.location.href,
      },
    });

    window.open(
      "https://line.me/ti/g2/l9IdM9Boqr9oiPZfNu70GR6ChI23PNQBfvr5KQ?utm_source=invitation&utm_medium=link_copy&utm_campaign=default",
      "_blank"
    );
  };

  return (
    <Flex
      flex={1}
      justifyContent="center"
      css={{
        background: "#F7F8F3",
        position: "relative",
      }}
    >
      <MetaTags>
        <title>Diagnosis Result Page | Mental Screening</title>
      </MetaTags>
      <Flex
        minHeight="100vh"
        maxWidth={768}
        width={1}
        flexDirection="column"
        alignItems="center"
      >
        {/* Results */}
        <Flex
          alignItems="center"
          justifyContent="center"
          css={{ position: "relative" }}
        >
          <Text width="80%" mt={36} textAlign="center" fontFamily="Mitr">
            <h4
              style={{
                fontSize: "1.4rem",
                fontWeight: 400,
                color: "#1E1446",
              }}
            >
              ผลการประเมิน
            </h4>
            <h2
              style={{
                fontSize: "2rem",
                fontWeight: 500,
                color: hamdSeverityData[hamd]?.textColor,
              }}
            >
              {diagnosisResult?.hamd_display_word}
            </h2>
          </Text>

          {/* Focus ring decoration */}
          <Image
            src={hamdSeverityData[hamd]?.focusRingImg}
            width={hamd === hamdSeverity.MODERATE ? 180 : 84}
            height={67}
            css={{
              position: "absolute",
              bottom: hamdSeverityData[hamd]?.focusRingBottom,
              left:
                hamd === hamdSeverity.NORMAL
                  ? hamdSeverityData[hamd]?.focusRingHorizontal
                  : "none",
              right:
                hamd !== hamdSeverity.NORMAL
                  ? hamdSeverityData[hamd]?.focusRingHorizontal
                  : "none",
            }}
          />
        </Flex>

        {/* Severity Icon */}
        <Flex
          minHeight={320}
          width={320}
          alignSelf="center"
          justifyContent="center"
          css={{
            background: hamdSeverityData[hamd]?.shadow,
            borderRadius: "50%",
            zIndex: 1,
          }}
        >
          <Image width={150} src={hamdSeverityData[hamd]?.severityIcon} />
        </Flex>

        <Flex
          width={278}
          height={41}
          alignSelf="center"
          justifyContent="center"
          flexDirection="row"
          css={{
            background: "#60CB6A",
            borderRadius: "16px ",
            zIndex: 2,
            top: "46%",
            position: "absolute",
          }}
          onClick={onClickOpenchat}
        >
          <Text
            textAlign="center"
            fontFamily="Mitr"
            alignItems="center"
            justifyContent="center"
            display="flex"
            margin="6px"
          >
            <h4
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#FFFFFF",
                lineHeight: "25px",
              }}
            >
              คุยกับเราต่อใน Line Openchat
            </h4>
          </Text>
          <Image width={20} src={arrow_right_white} />
        </Flex>

        {/* Detail Box */}
        <Flex
          mt={2}
          width={1}
          flexDirection="column"
          minHeight={400}
          alignItems="center"
          justifyContent="space-around"
          css={{
            background: hamdSeverityData[hamd]?.background,
            borderRadius: "50px 50px 0px 0px",
          }}
          pb={60}
        >
          <Text
            width="70%"
            mt={64}
            fontFamily="Mitr"
            textAlign="center"
            color="#fff"
          >
            <h3
              style={{
                fontSize: "1.4rem",
                fontWeight: 400,
              }}
            >
              {hamdSeverityData[hamd]?.text}
            </h3>
          </Text>
          <Flex
            flexDirection={
              hamd === hamdSeverity.SEVERE ? "column-reverse" : "column"
            }
            alignItems="stretch"
            width="100%"
            css={{ gap: "16px", marginTop: "60px" }}
          >
            {/* <MentalReferralClinicButton
              onClick={() => {
                AgnosAnalytics.event({
                  page: "result",
                  actionType: AAActionType.CLICK,
                  action:
                    window.location.pathname +
                    window.location.search +
                    "_to_mentalReferral",
                  data: {},
                });
                navigate("/all_recommend_clinic", {
                  state: {
                    // clinicData,
                    currentPosition,
                  },
                });
                // navigate("/referral", {
                //   state: {
                //     root: "/diagnosis-result",
                //   },
                // });
              }}
              width="80%"
              mt={0}
              text="แนะนำคลินิกสุขภาพใจ"
              border={
                hamd === hamdSeverity.SEVERE
                  ? "2px solid #F2F2F2"
                  : hamdSeverityData[hamd]?.border
              }
              backgroundColor={
                hamd === hamdSeverity.SEVERE ? "transparent" : "#FFFFFFD9"
              }
              textColor={hamd === hamdSeverity.SEVERE ? "#FDFEF9" : "#1E1446"}
              fontSize="1.2rem"
              fontWeight={500}
              icon={undefined}
              gap={8}
              padding={10}
            /> */}
            <Button
              width="80%"
              alignSelf="center"
              css={{
                color: "#FDFEF9",
                backgroundColor: "transparent",
                border: "2px solid #F2F2F2",
                borderRadius: ".7rem",
                fontSize: "1.2rem",
                fontFamily: "Mitr",
              }}
              onClick={() => {
                AgnosAnalytics.event({
                  page: "result_" + convertSeverityToColor(hamd),
                  actionType: AAActionType.CLICK,
                  action: "searchdoc_button_result",
                  data: {},
                });
                setDownloadAgnosModal(true);
              }}
            >
              ค้นหาจิตแพทย์
            </Button>
            <Button
              // mt={60}
              display="flex"
              alignItems="center"
              justifyContent="center"
              alignSelf="center"
              width="80%"
              css={{
                padding: "10px",
                fontWeight: 500,
                background:
                  hamd === hamdSeverity.SEVERE
                    ? "rgba(255, 255, 255, 0.85)"
                    : "transparent",
                // background: "rgba(255, 255, 255, 0.85)",
                // border: hamdSeverityData[hamd]?.border,
                border: "2px solid #F2F2F2",
                borderRadius: ".7rem",
                fontSize: "1.2rem",
                fontFamily: "Mitr",
                color: hamd === hamdSeverity.SEVERE ? "#1E1446" : "#FDFEF9",
              }}
              onClick={onClickNext}
            >
              {hamd === "SEVERE"
                ? "ให้เจ้าหน้าที่สายด่วนโทรหาฉัน"
                : "ให้คะแนนแบบทดสอบนี้"}
              {/* <Image ml={3} src={arrow_right_icon} /> */}
            </Button>
          </Flex>
          {/* Button */}
          {/* <Button
            mt={60}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="80%"
            css={{
              background: "rgba(255, 255, 255, 0.85)",
              border: hamdSeverityData[hamd]?.border,
              borderRadius: ".7rem",
              fontSize: "1.2rem",
              fontFamily: "Mitr",
              color: "#1E1446",
            }}
            onClick={onClickNext}
          >
            ต่อไป
            <Image ml={3} src={arrow_right_icon} />
          </Button> */}
        </Flex>
      </Flex>

      {/* Consent */}
      <ConsentModal
        isModalOpen={isConsentModalOpen}
        onClickAccept={onAcceptConsent}
        onClickCancel={navigateToFeedback}
      />

      {/* Error Popup Dialog */}
      <ConfirmDialog
        showConfirmDialog={!_.isEmpty(errorMessage)}
        title={errorMessage}
        message="กรุณาลองใหม่อีกครั้ง"
        confirmButtonTitle="กลับสู่หน้าหลัก"
        onConfirm={() => {
          setErrorMessage(null);
          navigate("/");
        }}
      />
      <DownloadAgnosModalForPsychiatrist
        open={downloadAgnosModal}
        onClose={() => setDownloadAgnosModal(false)}
        type={modalType.getPsychiatrist}
      />
    </Flex>
  );
};

export default DiagnosisResultPage;
