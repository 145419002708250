import React, { useState, useEffect } from "react";
import { Flex, Image, Text } from "rebass";
import { useNavigate, useLocation } from "react-router-dom";
import AgnosAnalytics, { AAActionType } from "../../../AgnosAnalytics";
import { differenceInSeconds } from "date-fns";

const ClinicHeader = ({ textHeader, page, hideBackIcon }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickClose = async () => {
    page === "clinic_profile" &&
      AgnosAnalytics.event({
        page: "clinic_profile",
        actionType: AAActionType.CLOSE,
        action: "close_clinic_profile",
        data: {},
      });

    navigate("/");
    // history.push({ pathname: "/home" });
  };
  return (
    <Flex
      width="100vw"
      height="30px"
      alignItems="center"
      padding="0px 24px"
      pt={"env(safe-area-inset-top, 20px)"}
      margin="48px 0px 20px 0px"
      justifyContent="space-between"
      textAlign="center"
      css={{
        zIndex: 2,
      }}
    >
      <svg
        onClick={() => {
          navigate(-1);
          // history.goBack();
        }}
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.960938 7.85938C0.960938 8.125 1.0625 8.35156 1.27344 8.55469L7.36719 14.5156C7.53125 14.6875 7.75 14.7734 8 14.7734C8.50781 14.7734 8.90625 14.3828 8.90625 13.8672C8.90625 13.6172 8.80469 13.3906 8.63281 13.2188L3.14062 7.85938L8.63281 2.5C8.80469 2.32031 8.90625 2.09375 8.90625 1.84375C8.90625 1.33594 8.50781 0.945312 8 0.945312C7.75 0.945312 7.53125 1.03125 7.36719 1.20312L1.27344 7.16406C1.0625 7.36719 0.96875 7.59375 0.960938 7.85938Z"
          fill="#292A2A"
        />
      </svg>
      <Text variant="base" fontSize="20px" fontWeight={700} color="#292A2A">
        {textHeader}
      </Text>
      <svg
        onClick={onClickClose}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          visibility: hideBackIcon ? "hidden" : "visible",
        }}
      >
        <path
          d="M1.24219 12.9062C1.58594 13.2422 2.14844 13.2422 2.47656 12.9141L7.28125 8.10938L12.0859 12.9141C12.4219 13.2422 12.9844 13.25 13.3203 12.9062C13.6562 12.5703 13.6562 12.0078 13.3281 11.6797L8.52344 6.86719L13.3281 2.0625C13.6562 1.73438 13.6641 1.16406 13.3203 0.835938C12.9766 0.5 12.4219 0.5 12.0859 0.828125L7.28125 5.63281L2.47656 0.828125C2.14844 0.5 1.57812 0.492188 1.24219 0.828125C0.90625 1.17188 0.914062 1.74219 1.24219 2.0625L6.04688 6.86719L1.24219 11.6797C0.914062 12 0.90625 12.5781 1.24219 12.9062Z"
          fill="#292A2A"
        />
      </svg>
    </Flex>
  );
};

export default ClinicHeader;
