import React, { useState, forwardRef, useEffect } from "react";
import { Flex, Image, Button } from "rebass";
import DatePicker, { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import th from "date-fns/locale/th";
import "react-datepicker/dist/react-datepicker.css";

import calendar_icon from "../../../Assets/calendar-icon.svg";
import "../../../Styles/CustomCalendar.css";

registerLocale("th", th);

const DatePickerInput = ({ start, end, onApply }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [dateString, setDateString] = useState(null);

  useEffect(() => {
    if (start) {
      setStartDate(new Date(start));
    }
    if (end) {
      setEndDate(new Date(end));
    }
    if (start && end) {
      const formattedDisplayString = formatSelectedDateString(
        new Date(start),
        new Date(end)
      );
      setDateString(formattedDisplayString);
    }
  }, [start, end]);

  const formatDate = (pubDate) => {
    const dateStr = new Date(pubDate).toLocaleDateString("th-TH", {
      day: "numeric",
      month: "short",
      year: "2-digit",
    });
    return dateStr;
  };

  const formatSelectedDateString = (start, end) => {
    if (start && end) {
      if (start.toDateString() === end.toDateString()) {
        return formatDate(start);
      } else {
        return `${formatDate(start)} - ${formatDate(end)}`;
      }
    }
    return "";
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Flex
      minWidth={50}
      minHeight={38}
      px={2}
      alignItems="center"
      ref={ref}
      onClick={() => {
        setCalendarOpen((prev) => !prev);
      }}
      css={{
        boxShadow:
          "0px 0.0851744px 0.255523px rgba(0, 0, 0, 0.1), 0px 0.851744px 1.70349px rgba(0, 0, 0, 0.2)",
        borderRadius: ".5rem",
        fontFamily: "Mitr",
        cursor: "pointer",
        color: "#1034A6",
      }}
    >
      <Image src={calendar_icon} mr={2} />
      {dateString ? dateString : "ค้นหาวัน"}
    </Flex>
  ));

  const MyContainer = ({ className, children }) => {
    return (
      <Flex
        flexDirection="column"
        backgroundColor="#fff"
        width={300}
        css={{
          border: "1px solid #D0DAE9",
          boxShadow:
            "0px 3px 6px rgba(0, 0, 0, 0.1), 0px 24px 48px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          fontFamily: "Mitr",
        }}
      >
        <Flex
          p={2}
          flexWrap="wrap"
          css={{
            gap: "14px",
          }}
        >
          <Button
            minWidth={80}
            minHeight={32}
            css={{
              background: "#fff",
              border: "2px solid #1034A6",
              borderRadius: "7px",
              color: "#000",
              fontFamily: "Mitr",
              fontSize: ".9rem",
            }}
            onClick={() => {
              setStartDate(new Date());
              setEndDate(new Date());
              setDateString("วันนี้");
            }}
          >
            วันนี้
          </Button>
          <Button
            minWidth={80}
            minHeight={32}
            css={{
              background: "#fff",
              border: "2px solid #1034A6",
              borderRadius: "7px",
              color: "#000",
              fontFamily: "Mitr",
              fontSize: ".9rem",
            }}
            onClick={() => {
              setStartDate(new Date().setDate(new Date().getDate() - 1));
              setEndDate(new Date().setDate(new Date().getDate() - 1));
              setDateString("เมื่อวาน");
            }}
          >
            เมื่อวาน
          </Button>
          <Button
            minWidth={80}
            minHeight={32}
            css={{
              background: "#fff",
              border: "2px solid #1034A6",
              borderRadius: "7px",
              color: "#000",
              fontFamily: "Mitr",
              fontSize: ".9rem",
            }}
            onClick={() => {
              setStartDate(new Date().setDate(new Date().getDate() - 7));
              setEndDate(new Date());
              setDateString("สัปดาห์นี้");
            }}
          >
            สัปดาห์นี้
          </Button>
          <Button
            minWidth={80}
            minHeight={32}
            css={{
              background: "#fff",
              border: "2px solid #1034A6",
              borderRadius: "7px",
              color: "#000",
              fontFamily: "Mitr",
              fontSize: ".9rem",
            }}
            onClick={() => {
              setStartDate("");
              setEndDate("");
              setDateString("ทั่งหมด");
            }}
          >
            ทั้งหมด
          </Button>
        </Flex>
        <Flex
          mt={3}
          justifyContent="center"
          flexDirection="column"
          css={{
            position: "relative",
            background: "#fff",
          }}
        >
          {children}
        </Flex>
      </Flex>
    );
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;

    let date = null;
    if (start && end) {
      date = formatSelectedDateString(start, end);
    }
    setDateString(date);
    setStartDate(start);
    setEndDate(end);
  };

  const onClickApply = () => {
    if (!startDate || !endDate) {
      onApply(null, null);
    } else {
      const startDateData = format(startDate, "yyyy-MM-dd");
      const endDateData = format(endDate, "yyyy-MM-dd");
      onApply(startDateData, endDateData);
    }
    setCalendarOpen(false);
  };

  return (
    <Flex flexDirection="column" minWidth={204} mt={12}>
      <DatePicker
        selected={startDate}
        onChange={onChangeDate}
        customInput={<CustomInput />}
        locale="th"
        calendarContainer={MyContainer}
        startDate={startDate}
        endDate={endDate}
        open={calendarOpen}
        selectsRange
        shouldCloseOnSelect={false}
        onClickOutside={() => setCalendarOpen(false)}
        disabledKeyboardNavigation
      >
        <Button
          width="30%"
          my={3}
          alignSelf="center"
          minHeight={30}
          backgroundColor="#206ED1"
          css={{
            borderRadius: "7px",
            color: "#fff",
            fontFamily: "Mitr",
            fontSize: ".9rem",
          }}
          onClick={onClickApply}
        >
          Apply
        </Button>
      </DatePicker>
    </Flex>
  );
};

export default DatePickerInput;
