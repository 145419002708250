import React, { useEffect } from "react";
import { Button, Flex, Image, Text } from "rebass";
import AgnosAnalytics, { AAActionType } from "../../../AgnosAnalytics";
import checkIcon from "../../../Assets/clinic/check_icon.svg";

export const clinicSortType = {
  DISTANCE: "distance",
  MEDICAL_FEE_ASC: "medical_fee_asc",
  MEDICAL_FEE_DESC: "medical_fee_desc",
};

const ClinicSortModal = ({ openModal, selectedSortType, onSelectSortType }) => {
  useEffect(() => {
    AgnosAnalytics.event({
      page: "Mhorprom-provide mental services",
      actionType: AAActionType.CLICK,
      action: "clinic_sort_DMIND",
      data: { sort_type: selectedSortType },
    });
  }, [selectedSortType]);
  return (
    <Flex
      height="100vh"
      width={1}
      alignItems="center"
      justifyContent="center"
      css={{
        background: "rgba(80, 80, 80, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <Flex
        width={1}
        minHeight={200}
        pb={20}
        px={2}
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        css={{
          background: "#fff",
          boxShadow: "0px 4px 8px rgba(143, 182, 232, 0.35)",
          borderRadius: "12px",
          position: "fixed",
          bottom: 0,
        }}
      >
        <Text
          variant="base"
          mt={3}
          fontSize="20px"
          textAlign="center"
          fontWeight={700}
        >
          จัดเรียงตาม
        </Text>
        <hr
          style={{
            marginTop: "8px",
            width: "100%",
            border: "0.5px solid #D9D9D9",
          }}
        />
        <Flex
          my={2}
          px={27}
          width={1}
          justifyContent="space-between"
          onClick={() => onSelectSortType(clinicSortType.DISTANCE)}
        >
          <Text variant="base" fontSize="16px" fontWeight={400}>
            ระยะทางใกล้ที่สุด
          </Text>
          {selectedSortType === clinicSortType.DISTANCE && (
            <Image src={checkIcon} />
          )}
        </Flex>
        <Flex
          my={2}
          px={27}
          width={1}
          justifyContent="space-between"
          onClick={() => onSelectSortType(clinicSortType.MEDICAL_FEE_ASC)}
        >
          <Text variant="base" fontSize="16px" fontWeight={400}>
            ราคา(ต่ำไปสูง)
          </Text>
          {selectedSortType === clinicSortType.MEDICAL_FEE_ASC && (
            <Image src={checkIcon} />
          )}
        </Flex>
        <Flex
          my={2}
          px={27}
          width={1}
          justifyContent="space-between"
          onClick={() => onSelectSortType(clinicSortType.MEDICAL_FEE_DESC)}
        >
          <Text variant="base" fontSize="16px" fontWeight={400}>
            ราคา(สูงไปต่ำ)
          </Text>
          {selectedSortType === clinicSortType.MEDICAL_FEE_DESC && (
            <Image src={checkIcon} />
          )}
        </Flex>
        <Button
          onClick={() => openModal(false)}
          mt={3}
          mx={27}
          width="90%"
          minHeight={40}
          variant="base"
          backgroundColor="#1E1446"
          color="#F2E1C1"
          css={{
            borderRadius: "14px",
          }}
          fontFamily="Anuphan"
          fontSize="20px"
          fontWeight={500}
        >
          ปิด
        </Button>
      </Flex>
    </Flex>
  );
};

export default ClinicSortModal;
