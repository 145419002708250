import React, { useState, useEffect } from "react";
import { Flex, Text, Image, Button, Box } from "rebass";
import { useNavigate, useLocation } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import ClinicHeader from "./Components/ClinicHeader";
import ClinicProfileDetailBox from "./Components/ClinicProfileDetailBox.js";

import clinic_background from "../../Assets/clinic/clinic_background.webp";
import ClinicBanner from "./Components/ClinicBanner";
import HeaderImage from "../../Assets/logo/clinic-profile-header-min.webp";
import BottomImage from "../../Assets/logo/clinic-profile-bottom-min.webp";
import AgnosAnalytics, { AAActionType } from "../../AgnosAnalytics";
import DownloadAgnosModalForPsychiatrist, {
  modalType,
} from "../../Components/Modal/DownloadAgnosForPsychiatrist";
import default_clinic from "../../Assets/clinic/default_clinic.png";

const ClinicProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [clinicData, setClinicData] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({});
  const [clinicPoisition, setClinicPoisition] = useState({});
  const [clinicDistance, setClinicDistance] = useState("");
  const [downloadAgnosModal, setDownloadAgnosModal] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { clinicData, currentPosition } = location?.state;
      setClinicData(clinicData);
      setClinicPoisition({ lat: clinicData.lat, lng: clinicData.lng });
      setCurrentPosition(currentPosition);
      setPromotions(clinicData?.promotions[0]);

      let distance = haversineDistance(clinicPoisition, currentPosition);
      setClinicDistance(distance);
    }
  }, [location, currentPosition]);

  function haversineDistance(mk1, mk2) {
    if (!mk1.lat || !mk1.lng) return null;
    if (!mk2.lat || !mk2.lng) return null;

    const R = 6371.071; // Radius of the Earth in kilo
    const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    const d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return !isNaN(d) ? d.toFixed() : null;
  }

  const onClickBanner = () => {
    AgnosAnalytics.event({
      page: "clinic_profile",
      actionType: AAActionType.CLICK,
      action: "clinic_code_redeem_banner",
      data: { clinic_name: clinicData.clinic_name },
    });
    navigate("/clinic_promotion", {
      state: {
        clinicData: promotions,
      },
    });
    // history.push({
    //   pathname: "/clinic_promotion",
    //   state: {
    //     clinicData: promotions,
    //   },
    // });
  };

  const onClickReedeemButton = () => {
    AgnosAnalytics.event({
      page: "clinic_profile",
      actionType: AAActionType.CLICK,
      action: "clinic_code_redeem_fix_button",
      data: { clinic_name: clinicData.clinic_name },
    });
    clinicData?.has_promotion
      ? setDownloadAgnosModal(true)
      : window.open(`tel:${clinicData.phone}`, "_self");
    // clinicData?.has_promotion
    //   ? navigate("/clinic_promotion", {
    //       state: {
    //         clinicData: promotions,
    //       },
    //     })
    //   : window.open(`tel:${clinicData.phone}`, "_self");
    // clinicData?.has_promotion
    //   ? history.push({
    //       pathname: "/clinic_promotion",
    //       state: {
    //         clinicData: promotions,
    //       },
    //     })
    //   : window.open(`tel:${clinicData.phone}`, "_self");
  };
  console.log(clinicData);
  return (
    <Flex
      minHeight="100vh"
      width={1}
      flexDirection="column"
      alignItems="center"
      mb={20}
      backgroundColor="#FDFEF9"
      css={{
        // background: `url(${clinic_background}) no-repeat`,
        // backgroundSize: "cover",
        // backgroundAttachment: "fixed",
        overflow: "hidden",
      }}
    >
      <Image
        src={HeaderImage}
        minWidth="100vw"
        position="sticky"
        top="0"
        marginBottom="-40%"
        css={{
          zIndex: 1,
        }}
      />
      <ClinicHeader textHeader={"คลินิกสุขภาพใจ"} page="clinic_profile" />

      {clinicData?.site_images && clinicData?.site_images.length > 0 && (
        <Swiper
          modules={[Pagination]}
          pagination={{
            dynamicBullets: true,
          }}
          // spaceBetween={20}
          style={{
            maxWidth: "768px",
            width: "100%",
            height: "275px",
            marginBottom: "-50px",
            zIndex: 2,
          }}
        >
          {clinicData?.site_images?.map((img, i) => (
            <SwiperSlide key={i} style={{ width: "100%", marginTop: "8px" }}>
              <Image
                height={"220px"}
                width={"100vw"}
                maxWidth={"none"}
                src={img ? img : default_clinic}
                onError={(e) => (e.target.src = default_clinic)}
                css={{
                  objectFit: "cover",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <Flex width="90%" maxWidth={768} flexDirection="column">
        {/* <Flex
          width={1}
          flexDirection="column"
          css={{
            display: clinicData.has_promotion ? "flex" : "none !important",
          }}
        >
          <Text
            mt={5}
            variant="base"
            fontSize="16px"
            fontWeight={700}
            color="#001B52"
            textAlign="left"
          >
            ส่วนลดพิเศษจากคลินิก
          </Text>
          <ClinicBanner
            onClick={onClickBanner}
          />
        </Flex> */}
        {!clinicData.has_promotion && <Box mt={"50px"} />}
        <ClinicProfileDetailBox data={clinicData} distance={clinicDistance} />
      </Flex>
      <Flex
        width={1}
        paddingY="30px"
        // minHeight={90}
        justifyContent="center"
        alignItems="center"
        css={{
          background:
            "linear-gradient(170.13deg, rgba(196, 196, 196, 0) 4.78%, #DFD1B5 131.21%)",
          position: "fixed",
          bottom: 0,
          //   filter: "blur(10px)",
          // backdropFilter: "blur(10px)",
          visibility: clinicData?.has_promotion ? "visible" : "hidden",
          zIndex: 20,
        }}
      >
        <Button
          minWidth={350}
          minHeight={48}
          fontSize="16px"
          fontWeight={700}
          onClick={onClickReedeemButton}
          css={{
            borderRadius: "12px",
            background: "#F2E1C1",
            fontFamily: "Anuphan",
            color: "#1E1446",
            boxShadow: "0px 2px 8px rgba(124, 124, 124, 0.15)",
          }}
        >
          {clinicData?.has_promotion ? "รับโค้ดส่วนลด" : "ติดต่อคลินิก"}
        </Button>
      </Flex>
      <DownloadAgnosModalForPsychiatrist
        open={downloadAgnosModal}
        onClose={() => setDownloadAgnosModal(false)}
        type={modalType.getDiscount}
      />
      <Image
        src={BottomImage}
        minWidth="100vw"
        css={{
          position: "relative",
          marginTop: "-200px",
          bottom: "0px",
          zIndex: 1,
        }}
      />
    </Flex>
  );
};

export default ClinicProfile;
