import React from "react";
import { Modal } from "semantic-ui-react";
import { Box, Text, Button, Flex, Image } from "rebass";

import done from "../../Assets/done.svg";
import { Player } from "@lottiefiles/react-lottie-player";

const SuccessModal = ({ open, onClose, text }) => {
  return (
    <Modal open={open} onClose={onClose} basic>
      <Flex justifyContent="center">
        <Box
          textAlign="center"
          backgroundColor="#fff"
          pt={0}
          px={32}
          pb={32}
          m={16}
          width={"90%"}
          maxWidth={400}
          sx={{ borderRadius: "27px" }}
        >
          <Player
            autoplay
            loop={false}
            keepLastFrame
            src={"https://assets4.lottiefiles.com/packages/lf20_mkelocrp.json"}
            style={{ height: "160px", width: "160px" }}
          />

          <Text fontFamily="Mitr" fontSize={24} color="#1A5650" mt={-10}>
            {text ? text : "ส่งผลสำเร็จ"}
          </Text>

          <Box textAlign="center">
            <Button
              sx={{
                borderRadius: "21px",
                border: "1.5px solid #3BCCBC",
                backgroundColor: "#3BCCBC",
              }}
              px={32}
              mt={32}
              onClick={() => onClose()}
            >
              <Text fontFamily="Mitr" fontSize={17} color="#fff">
                ตกลง
              </Text>
            </Button>
          </Box>
        </Box>
      </Flex>
    </Modal>
  );
};

export default SuccessModal;
