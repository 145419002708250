import React, { forwardRef } from "react";
import { Image } from "rebass";
import { Label, Input } from "@rebass/forms";
import editIcon from "../Assets/edit-icon.svg";

const MentalInput = forwardRef(
  (
    {
      onChange,
      onBlur,
      name,
      label,
      labelText,
      lableImage,
      type,
      textAlign,
      onFocus,
      readOnly,
      value,
      mt,
      headerLabel,
      className,
      defaultValue
    },
    ref
  ) => (
    <>
      <Label
        display={headerLabel ? "flex" : "none !important"}
        mt={mt}
        width="80%"
        fontFamily="Mitr"
        fontSize="1rem"
        fontWeight={300}
      >
        <Image display="inline-flex" mr={2} width={24} src={lableImage} />
        {labelText}
      </Label>
      <Label
        mt={2}
        width={1}
        alignItems="center"
        alignSelf="center"
        css={{
          position: "relative",
          borderBottom: "1px solid #9E9E9E",
        }}
      >
        <Input
          type={type}
          readOnly={readOnly}
          defaultValue={defaultValue}
          className={className}
          value={value}
          name={name}
          ref={ref}
          css={{
            border: "none",
            textAlign: textAlign,
            outline: "none",
            fontFamily: "Mitr",
            color: "#1E1446",
          }}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <Image
          src={editIcon}
          css={{
            position: "absolute",
            right: 0,
          }}
        />
      </Label>
    </>
  )
);

// const MentalInput = ({
//   mt,
//   label,
//   labelText,
//   lableImage,
//   onChange,
//   type,
//   textAlign,
//   onFocus,
//   onBlur,
//   readOnly,
//   value,
// }) => {
//   return (
//     <>
//       <Label
//         display={label ? "flex" : "none !important"}
//         mt={mt}
//         width="80%"
//         fontFamily="Mitr"
//         fontSize="1rem"
//         fontWeight={300}
//       >
//         <Image display="inline-flex" mr={2} width={24} src={lableImage} />
//         {labelText}
//       </Label>
//       <Label
//         mt={2}
//         width={1}
//         alignItems="center"
//         alignSelf="center"
//         css={{
//           position: "relative",
//           borderBottom: "1px solid #9E9E9E",
//         }}
//       >
//         <Input
//           type={type}
//           readOnly={readOnly}
//           defaultValue={value}
//           css={{
//             border: "none",
//             textAlign: textAlign,
//             outline: "none",
//             fontFamily: "Mitr",
//             color: "#1E1446",
//           }}
//           onChange={onChange}
//           onFocus={onFocus}
//           onBlur={onBlur}
//         />
//         <Image
//           src={editIcon}
//           css={{
//             position: "absolute",
//             right: 0,
//           }}
//         />
//       </Label>
//     </>
//   );
// };

export default MentalInput;
