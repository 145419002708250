import React from "react";
import { Flex, Text, Image } from "rebass";
import doctorImage from "../Assets/doctor-consent-image.svg";
import MentalButton from "./MentalButton";

const ConsentModal = ({ isModalOpen, onClickAccept, onClickCancel }) => {
  return (
    <Flex
      display={isModalOpen ? "flex" : "none !important"}
      width={1}
      justifyContent="center"
      css={{
        background: "rgba(0, 0, 0, 0.5);",
        position: "absolute",
        top: 0,
        bottom: 0,
        zIndex: 10,
      }}
    >
      <Flex
        minHeight="100vh"
        maxWidth={768}
        width={1}
        flexDirection="column"
        alignItems="center"
      >
        <Flex
          width={1}
          minHeight="60vh"
          backgroundColor="#fff"
          flexDirection="column"
          alignItems="center"
          css={{
            position: "absolute",
            bottom: 0,
            borderRadius: "16px 16px 0px 0px",
            padding: "48px 24px",
          }}
        >
          <Image width={176} src={doctorImage} />
          <Text
            mt={24}
            width="70%"
            fontFamily="Mitr"
            fontWeight={300}
            textAlign="center"
          >
            ท่านยินยอมให้มีการติดตามดูแลโดย
            <br />
            เจ้าหน้าที่สาธารณสุขหรือไม่
          </Text>
          <MentalButton
            mt={36}
            width="80%"
            backgroundColor="#1E1446"
            textColor="#F2E1C1"
            text="ยินยอม"
            onClick={onClickAccept}
          />
          <MentalButton
            mt={3}
            width="80%"
            backgroundColor="#fff"
            border="2px solid #F2E1C1"
            textColor="#1E1446"
            text="ไม่ยินยอม"
            onClick={onClickCancel}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ConsentModal;
