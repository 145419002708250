import React from "react";
import { Button, Text } from "rebass";

const SingleChoice = ({ choice, selected, onSelect }) => {
  return (
    <Button
      alignItems="center"
      justifyContent="center"
      css={{
        border: selected ? "1px solid #F2E1C1" : "1px solid #1E1446",
        borderRadius: "26px",
        background: selected ? "#F2E1C1" : "#FFF",
        transform: selected ? "scale(1.05)" : "none",
        "&:hover": {
          background: "#F2E1C1",
          border: "1px solid #F2E1C1",
          transform: "scale(1.05)"
        },
      }}
      width={1}
      p={10}
      my={12}
      onClick={onSelect}
    >
      <Text fontFamily="Mitr" textAlign="center" color="#1E1446">
        <span style={{ fontSize: "1.2rem", fontWeight: 300 }}>{choice?.choice_text}</span>
      </Text>
    </Button>
  );
};

export default SingleChoice;
