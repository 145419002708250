import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Image, Text } from "rebass";
import { useForm } from "react-hook-form";
import { Slider } from "react-semantic-ui-range";

import { setClinicFilter } from "../../reducers/clinicSlice";

import clinic_background from "../../Assets/clinic/clinic_background.webp";
import ClinicHeader from "./Components/ClinicHeader";
import ClinicFilterHeader from "./Components/ClinicFilterHeader";
import ClinicCheckBox from "./Components/ClinicCheckBox";

import plusDarkBlue from "../../Assets/plus_dark_blue.svg";
import minusDarkBlue from "../../Assets/minus_dark_blue.svg";

import HeaderImage from "../../Assets/logo/clinic-profile-header-min.webp";
import BottomImage from "../../Assets/logo/clinic-profile-bottom-min.webp";

import { Checkbox, Input, Label } from "@rebass/forms";
import AgnosAnalytics, { AAActionType } from "../../AgnosAnalytics";

const day = [
  { text: "วันจันทร์", value: "Mon" },
  { text: "วันอังคาร", value: "Tue" },
  { text: "วันพุธ", value: "Wed" },
  { text: "วันพฤหัสบดี", value: "Thu" },
  { text: "วันศุกร์", value: "Fri" },
  { text: "วันเสาร์", value: "Sat" },
  { text: "วันอาทิตย์", value: "Sun" },
];

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const clinicTypes = [
  "คลินิกโรคทั่วไป",
  "คลินิกโรคสุขภาพจิต",
  "คลินิกโรคทางเพศ",
  "คลินิกกายภาพ",
];

const openingHours = ["08:00,17:00", "17:00,22:00", "22:00,08:00"];

const serviceTypes = ["มีบริการแบบออนไลน์", "มีบริการที่คลินิก"];

const ClinicFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(Array(5).fill(false));
  const [selectedNearMe, setSelectedNearMe] = useState(null);
  const [sliderState, setSliderState] = useState(null);
  const [distance, setDistance] = useState(null);
  const [distanceInputChange, setDistanceInputChange] = useState(false);

  const { clinicFilter } = useSelector((state) => state.clinic);

  const { register, handleSubmit, watch, setValue, getValues, reset } =
    useForm();

  const sliderStateToDistance = { 1: 5, 2: 15, 3: 30, 4: 100, 5: 200, 6: 500 };
  const distanceInputType = {
    SLIDER: "slider",
    INPUT: "input",
  };

  useEffect(() => {
    prefillFilterData();
  }, [location]);

  const prefillFilterData = () => {
    // clinicRadius
    const selectClinicRadius = clinicFilter?.radius;
    if (selectClinicRadius) {
      setSelectedNearMe(true);
      setDistance(selectClinicRadius);
      calculateSliderState(selectClinicRadius);
      onClickExpand(0);
    }
    // clinicType
    let selectedClinicTypeList = clinicFilter?.clinicType;
    if (selectedClinicTypeList) {
      selectedClinicTypeList.map((value) => {
        const typeIndex = clinicTypes.findIndex((type) => type === value);
        if (typeIndex >= 0) {
          setValue(`clinicType.${typeIndex}`, value);
        }
      });
      onClickExpand(1);
    }

    //clinicOpeningDay
    let selectedOpeningDays = clinicFilter?.openingDays;
    if (selectedOpeningDays) {
      selectedOpeningDays.map((value) => {
        const dayIndex = days.findIndex((day) => day === value);
        if (dayIndex >= 0) {
          setValue(`openingDay.${dayIndex}`, value);
        }
      });
      onClickExpand(2);
    }

    //clinicOpeningTimes
    let selectedOpeningTimes = clinicFilter?.openingTimes;
    if (selectedOpeningTimes) {
      selectedOpeningTimes.map((value) => {
        const openingIndex = openingHours.findIndex((range) => range === value);
        if (openingIndex >= 0) {
          setValue(`openingTime.${openingIndex}`, value);
        }
      });
      onClickExpand(3);
    }

    //clinicDiscount
    const selectedDiscount = clinicFilter?.hasDiscount;
    if (selectedDiscount) {
      setValue("promotion", selectedDiscount);
      onClickExpand(4);
    }

    //clinicServiceTypes
    let selectedServiceTypes = clinicFilter?.serviceType;
    if (selectedServiceTypes) {
      selectedServiceTypes.map((value) => {
        const serviceIndex = serviceTypes.findIndex(
          (service) => service === value
        );
        if (serviceIndex >= 0) {
          setValue(`serviceType.${serviceIndex}`, value);
        }
      });
      onClickExpand(5);
    }
  };

  useEffect(() => {}, [clinicFilter]);

  const onClickExpand = (index) => {
    var filterType = undefined;

    switch (index) {
      case 0:
        filterType = "clinicRadius";
        break;
      case 1:
        filterType = "clinicType";
        break;
      case 2:
        filterType = "clinicOpeningDay";
        break;
      case 3:
        filterType = "clinicOpeningTimes";
        break;
      case 4:
        filterType = "clinicDiscount";
        break;
      case 5:
        filterType = "clinicServiceTypes";
        break;
      default:
        filterType = undefined;
    }

    AgnosAnalytics.event({
      page: "All Clinic_filter_DMIND",
      actionType: AAActionType.EXPAND,
      action: `clinic_filter_expand_DMIND`,
      data: { filter_type: filterType },
    });

    let selectedExpand = expand;
    selectedExpand[index] = !expand[index];
    setExpand((prev) => [...prev]);
  };

  const calculateSliderState = (range) => {
    let tempSliderState = 1;
    Object.entries(sliderStateToDistance).forEach(
      ([sliderKey, sliderValue]) => {
        if (range >= sliderValue) {
          tempSliderState = sliderKey;
        }
      }
    );
    setSliderState(tempSliderState);
  };

  const onChangeDistance = async (value, type) => {
    switch (type) {
      case distanceInputType.SLIDER:
        if (!distanceInputChange) {
          setSliderState(value);
          setDistance(sliderStateToDistance[value]);
        }
        setDistanceInputChange(false);
        break;
      case distanceInputType.INPUT:
        await setDistanceInputChange(true);
        setDistance(value);
        calculateSliderState(value);
        break;
      default:
        break;
    }
  };

  const onClickClearAll = () => {
    AgnosAnalytics.event({
      page: "All Clinic_filter_DMIND",
      actionType: AAActionType.RESET,
      action: `clinic_filter_reset_default_DMIND`,
      data: {},
    });

    setSelectedNearMe(false);
    setDistance(null);
    calculateSliderState(5);
    Array.from(Array(4).keys()).forEach((n, i) =>
      setValue(`clinicType.${i}`, false)
    );
    Array.from(Array(8).keys()).forEach((n, i) =>
      setValue(`openingDay.${i}`, false)
    );
    Array.from(Array(3).keys()).forEach((n, i) =>
      setValue(`openingTime.${i}`, false)
    );
    setValue("promotion", false);
    Array.from(Array(2).keys()).forEach((n, i) =>
      setValue(`serviceType.${i}`, false)
    );
  };

  const onSubmit = (data) => {
    console.log(data);

    const filterClinicType = data.clinicType.filter((data) => data !== false);
    const filterOpeningDays = data.openingDay.filter((data) => data !== false);
    const filterOpeningTimes = data.openingTime.filter(
      (data) => data !== false
    );
    const filterServiceType = data.serviceType.filter((data) => data !== false);

    AgnosAnalytics.event({
      page: "All Clinic_filter_DMIND",
      actionType: AAActionType.SUBMIT,
      action: `clinic_filter_dmind`,
      data: {
        near_me: selectedNearMe,
        near_me_distance: distance,
        clinic_type: filterClinicType.length === 0 ? null : filterClinicType,
        opening_day: filterOpeningDays.length === 0 ? null : filterOpeningDays,
        opening_hours: data.openingTime,
        promotion: !data.promotion ? null : data.promotion,
        form_of_service:
          filterServiceType.length === 0 ? null : filterServiceType,
      },
    });

    dispatch(
      setClinicFilter({
        radius: distance,
        clinicType: filterClinicType.length === 0 ? null : filterClinicType,
        openingDays: filterOpeningDays.length === 0 ? null : filterOpeningDays,
        // openingTimes:
        //   filterOpeningTimes.length === 0 ? null : filterOpeningTimes,
        openingTimes: data.openingTime,
        hasDiscount: !data.promotion ? null : data.promotion,
        serviceType: filterServiceType.length === 0 ? null : filterServiceType,
      })
    );

    navigate(-1);
  };

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      flexDirection="column"
      backgroundColor="#FDFEF9"
      alignItems="center"
      minHeight="100vh"
      pb={120}
      // sx={{
      //   backgroundImage: `url(${clinic_background})`,
      //   backgroundSize: "cover",
      //   backgroundAttachment: "fixed",
      // }}
    >
      <Image
        src={HeaderImage}
        minWidth="100vw"
        position="sticky"
        top="0"
        marginBottom="-40%"
        css={{
          zIndex: 1,
        }}
      />

      <ClinicHeader textHeader="ตัวกรอง" hideBackIcon />
      <Text
        onClick={onClickClearAll}
        variant="base"
        color="primary3"
        fontSize={16}
        fontWeight={700}
        padding="0px 24px 26px 0px"
        width={1}
        textAlign="right"
        sx={{ textDecoration: "underline" }}
        css={{
          zIndex: 2,
        }}
      >
        กลับสู่ค่าเริ่มต้น
      </Text>

      {/* nearest */}
      <Flex
        flexDirection="column"
        padding={expand[0] ? "0px 12px 28px 12px" : "0px 12px 8px 12px"}
        width={"90%"}
        sx={{ borderBottom: "1px solid #8FB6E8" }}
        css={{
          zIndex: 2,
        }}
      >
        <Flex
          onClick={() => onClickExpand(0)}
          justifyContent="space-between"
          alignItems="center"
          width={1}
        >
          <Flex alignItems="center">
            <Text
              variant="base"
              color="primary3"
              fontSize={16}
              fontWeight={700}
              pr="10px"
              textAlign="center"
            >
              ใกล้ฉัน
            </Text>
            {selectedNearMe && distance < 501 && distance !== "" && (
              <Flex
                variant="base"
                justifyContent="center"
                alignItems="center"
                backgroundColor="#00CCB1"
                width={`${(distance?.toString().length + 5) * 8}px`}
                height={26}
                color="white"
                fontWeight={500}
                sx={{ borderRadius: "100px" }}
              >
                {`${distance} กม.`}
              </Flex>
            )}
          </Flex>
          <Image src={expand[0] ? minusDarkBlue : plusDarkBlue} />
        </Flex>
        {expand[0] && (
          <>
            <Label>
              <Flex
                backgroundColor={selectedNearMe ? "#F2E1C1" : "white"}
                width={1}
                mt="16px"
                padding="13px 16px"
                sx={{
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(143, 182, 232, 0.08)",
                }}
              >
                <Checkbox
                  onClick={(e) => {
                    setSelectedNearMe(!selectedNearMe);
                    e.target.checked
                      ? setDistance(distance ? distance : 5)
                      : setDistance(null);
                  }}
                  checked={selectedNearMe}
                  readOnly={true}
                />
                <Text
                  variant="base"
                  color="primary3"
                  fontSize={16}
                  fontWeight={700}
                >
                  ใกล้ฉัน
                </Text>
              </Flex>
            </Label>

            {selectedNearMe && (
              <>
                <Text
                  variant="base"
                  color="primary3"
                  fontWeight={500}
                  pt="16px"
                  textAlign="left"
                >
                  ในรัศมี
                </Text>
                <Flex alignItems="center">
                  <Flex flexDirection="column" width={0.65} mr="5%">
                    <Slider
                      value={sliderState}
                      discrete
                      color="primary3"
                      settings={{
                        min: 1,
                        max: 6,
                        step: 1,
                        onChange: (value) => {
                          onChangeDistance(value, distanceInputType.SLIDER);
                        },
                      }}
                      style={{
                        height: "28px",
                        track: { top: "14px" },
                        trackFill: {
                          top: "14px",
                          backgroundColor: "#1A59C2",
                        },
                        thumb: {
                          backgroundColor: "white",
                          width: "28px",
                          height: "28px",
                        },
                      }}
                    />
                    <Text
                      variant="base"
                      color="primary3"
                      pt="10px"
                      pl={
                        sliderState !== 6
                          ? `${((sliderState - 1) * 100) / 6}%`
                          : "0px"
                      }
                      textAlign={sliderState !== 6 ? "left" : "right"}
                    >
                      {sliderState ? `${distance} กม.` : null}
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" width={0.3}>
                    {distance > 500 && (
                      <Text variant="base" color="#F54800">
                        ได้ไม่เกิน 500 กม.
                      </Text>
                    )}
                    <Flex
                      // onClick={() => distanceBoxRef.current.focus()}
                      backgroundColor="white"
                      alignItems="center"
                      padding="13px 16px"
                      sx={{ borderRadius: "16px" }}
                    >
                      <Input
                        //   ref={distanceBoxRef}
                        onChange={(e) => {
                          onChangeDistance(
                            e.target.value,
                            distanceInputType.INPUT
                          );
                        }}
                        value={distance}
                        type="number"
                        color={distance > 500 ? "#F54800" : "primary3"}
                        fontSize={16}
                        fontWeight={700}
                        padding="0px"
                        sx={{
                          fontFamily: "Anuphan",
                          border: "none",
                          width: `${(distance?.toString().length + 1) * 8}px`,
                        }}
                      />
                      <Text
                        variant="base"
                        color={distance > 500 ? "#F54800" : "primary3"}
                        fontSize={16}
                        fontWeight={700}
                      >
                        กม.
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
      {/* nearest */}

      {/* clinictype */}

      <Flex
        mt={3}
        flexDirection="column"
        padding={expand[1] ? "0px 12px 28px 12px" : "0px 12px 8px 12px"}
        width={"90%"}
        sx={{ borderBottom: "1px solid #8FB6E8" }}
        css={{
          zIndex: 2,
        }}
      >
        <ClinicFilterHeader
          headerText="ประเภทของคลินิก"
          onClick={(e) => onClickExpand(1)}
          index={expand[1]}
        />

        <Flex
          display={expand[1] ? "flex" : "none !important"}
          flexDirection="column"
        >
          {clinicTypes.map((type, index) => {
            return (
              <ClinicCheckBox
                key={`clinicType-${index}`}
                label={type}
                // name={`clinicType.${index}`}
                value={type}
                {...register(`clinicType.${index}`)}
                // ref={register}
                checkedState={watch(`clinicType.${index}`)}
              />
            );
          })}
        </Flex>
      </Flex>

      {/* clinictype */}

      {/* openday */}
      <Flex
        mt={3}
        flexDirection="column"
        padding={expand[2] ? "0px 12px 28px 12px" : "0px 12px 8px 12px"}
        width={"90%"}
        sx={{ borderBottom: "1px solid #8FB6E8" }}
        css={{
          zIndex: 2,
        }}
      >
        <ClinicFilterHeader
          headerText="เปิดให้บริการ"
          onClick={() => onClickExpand(2)}
          index={expand[2]}
        />
        <Flex
          display={expand[2] ? "flex" : "none !important"}
          flexDirection="column"
        >
          {day.map((day, i) => (
            <ClinicCheckBox
              key={i}
              // name={`openingDay.${i}`}
              label={day.text}
              // ref={register}
              {...register(`openingDay.${i}`)}
              value={day.value}
              checkedState={watch(`openingDay.${i}`)}
            />
          ))}
        </Flex>
      </Flex>
      {/* openday */}

      {/* opentime */}
      <Flex
        mt={3}
        flexDirection="column"
        padding={expand[3] ? "0px 12px 28px 12px" : "0px 12px 8px 12px"}
        width={"90%"}
        sx={{ borderBottom: "1px solid #8FB6E8" }}
        css={{
          zIndex: 2,
        }}
      >
        <ClinicFilterHeader
          headerText="เวลาให้บริการ"
          onClick={() => onClickExpand(3)}
          index={expand[3]}
        />

        <Flex
          display={expand[3] ? "flex" : "none !important"}
          flexDirection="column"
        >
          {openingHours.map((range, index) => {
            const rangeDisplayText = range.replace(",", "-");
            return (
              <ClinicCheckBox
                key={`openingHours-${index}`}
                // ref={register}
                // name={`openingTime.${index}`}
                {...register(`openingTime.${index}`)}
                value={range}
                checkedState={watch(`openingTime.${index}`)}
                label={`${rangeDisplayText} น.`}
              />
            );
          })}
        </Flex>
      </Flex>
      {/* opentime */}

      {/* promotion */}
      <Flex
        mt={3}
        flexDirection="column"
        padding={expand[4] ? "0px 12px 28px 12px" : "0px 12px 8px 12px"}
        width={"90%"}
        sx={{ borderBottom: "1px solid #8FB6E8" }}
        css={{
          zIndex: 2,
        }}
      >
        <ClinicFilterHeader
          headerText="โปรโมชั่น"
          onClick={() => onClickExpand(4)}
          index={expand[4]}
        />
        <Flex
          display={expand[4] ? "flex" : "none !important"}
          flexDirection="column"
        >
          <ClinicCheckBox
            label="มีโปรโมชัน"
            // ref={register}
            // name="promotion"
            {...register("promotion")}
            checkedState={watch("promotion")}
          />
        </Flex>
      </Flex>
      {/* promotion */}

      {/* servicetype */}
      <Flex
        mt={3}
        flexDirection="column"
        padding={expand[5] ? "0px 12px 28px 12px" : "0px 12px 8px 12px"}
        width={"90%"}
        sx={{ borderBottom: "1px solid #8FB6E8" }}
        css={{
          zIndex: 2,
        }}
      >
        <ClinicFilterHeader
          headerText="รูปแบบการให้บริการ"
          onClick={() => onClickExpand(5)}
          index={expand[5]}
        />
        <Flex
          display={expand[5] ? "flex" : "none !important"}
          flexDirection="column"
        >
          <ClinicCheckBox
            label="มีบริการแบบออนไลน์"
            // ref={register}
            // name="serviceType.0"
            {...register("serviceType.0")}
            value="มีบริการแบบออนไลน์"
            checkedState={watch("serviceType.0")}
          />
          <ClinicCheckBox
            label="มีบริการที่คลินิก"
            // ref={register}
            // name="serviceType.1"
            {...register("serviceType.1")}
            value="มีบริการที่คลินิก"
            checkedState={watch("serviceType.1")}
          />
        </Flex>
      </Flex>
      {/* servicetype */}
      {/* button section */}
      <Flex
        width={1}
        css={{
          position: "fixed",
          bottom: 0,
          minHeight: "90px",
          alignItems: "center",
          justifyContent: "center",
          // background: "rgba(239, 242, 245, 0.2)",
          //   filter: "blur(10px)",
          //   zIndex:4
          background:
            "linear-gradient(170.13deg, rgba(196, 196, 196, 0) 4.78%, #DFD1B5 131.21%)",
          flexDirection: "column",
          zIndex: 2,
        }}
      >
        <Button
          type="submit"
          width="90%"
          variant="base"
          fontSize="16px"
          fontWeight={700}
          css={{
            borderRadius: "12px",
            padding: "12px 48px",
            background: "#F2E1C1",
            fontFamily: "Anuphan",
            color: "#1E1446",
            boxShadow: "0px 2px 8px rgba(124, 124, 124, 0.15)",
            zIndex: 2,
          }}
        >
          ตกลง
        </Button>
      </Flex>
      {/* button section */}
    </Flex>
  );
};

export default ClinicFilter;
