import React from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Text, Image } from "rebass";

import back_icon from "../../Assets/back-arrow-icon.svg";
import close_icon from "../../Assets/close-icon.svg";

const NavBar = ({
  backOnly,
  title,
  subtitle,
  line,
  dropShadow,
  fontSize,
  fontWeight,
  fontFamily,
  closeExec,
  backExec,
  fixed,
  backgroundColor,
  paddingTop,
}) => {
  const navigate = useNavigate();

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      backgroundColor={backgroundColor ? backgroundColor : "transparant"}
      width={1}
      minHeight="10vh"
      pt={"env(safe-area-inset-top, 20px)"}
      px={20}
      pb={24}
      sx={{ position: fixed === true ? "fixed" : "relative" }}
    >
      {/* Back */}
      <Flex flex={1}>
        <Image
          src={back_icon}
          width={36}
          height={36}
          css={{
            "&:hover": {
              transform: "scale(1.2)",
            },
          }}
          onClick={() => (backExec ? backExec() : navigate(-1))}
        />
      </Flex>

      {/* Title */}
      <Flex
        flex={2}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Text>
          <h2
            style={{
              fontSize: fontSize,
              fontWeight: fontWeight,
              fontFamily: fontFamily ? fontFamily : "",
            }}
            className="text-headline"
          >
            {title}
          </h2>
          {subtitle && (
            <h4 className="text-subhead-2 text-gray-inactive">{subtitle}</h4>
          )}
        </Text>
      </Flex>

      {/* Close */}
      <Flex
        flex={1}
        justifyContent="flex-end"
        css={{
          visibility: backOnly ? "hidden" : "visible",
        }}
      >
        <Image
          src={close_icon}
          width={52}
          height={52}
          onClick={() => (closeExec ? closeExec() : navigate("/"))}
        />
      </Flex>
    </Flex>
  );
};

export default NavBar;
