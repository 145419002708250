export const hamdSeverity = {
  SEVERE: "SEVERE",
  MODERATE: "MODERATE",
  MILD: "MILD",
  NORMAL: "NORMAL",
};

export const eightQSeverity = {
  SEVERE: "SEVERE",
  MODERATE: "MODERATE",
  MILD: "MILD",
  NORMAL: "NORMAL",
};
