const APP_ENV = process.env.REACT_APP_ENV;
const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_BASE_URL;
const PRESCREENING_API_BASE_URL =
  process.env.REACT_APP_PRESCREENING_API_BASE_URL;
const DMIND_PRESCREENING_URL = process.env.REACT_APP_DMIND_PRESCREENING_URL;
const AGNOS_API_BASE_URL = process.env.REACT_APP_AGNOS_API_BASE_URL;
const AGNOS_DOWNLOAD_URL = process.env.REACT_APP_AGNOS_DOWNLOAD_URL;
const APP_API_BASE_URL = process.env.REACT_APP_APP_API_BASE_URL;
const PIXEL_ID = "523824056573726";
export {
  APP_ENV,
  DASHBOARD_API_BASE_URL,
  PRESCREENING_API_BASE_URL,
  DMIND_PRESCREENING_URL,
  AGNOS_API_BASE_URL,
  AGNOS_DOWNLOAD_URL,
  APP_API_BASE_URL,
  PIXEL_ID,
};
