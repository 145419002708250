import axios from "axios";
import Cookies from "js-cookie";

import { PRESCREENING_API_BASE_URL } from "./config";
import { authHeader, checkSession, refreshSession } from "./session";

const dmindPrescreeningApiService = axios.create();

dmindPrescreeningApiService.defaults.baseURL = PRESCREENING_API_BASE_URL;

// Request interceptor for API calls
dmindPrescreeningApiService.interceptors.request.use(
  async (config) => {
    // config.headers = authHeader();
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
dmindPrescreeningApiService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    var originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    await checkSession();
    await refreshSession();
    originalRequest.headers = authHeader().headers;
    return dmindPrescreeningApiService(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { dmindPrescreeningApiService };
