import React from "react";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material"

const theme = {
  breakpoints: ["20em", "25em", "64em"],
  fonts: {
    body: "Anuphan",
    medium: "Anuphan",
    monospace: "Menlo, monospace",
    logo: "Aquatico",
  },
  fontSizes: [12, 14, 16, 24, 32, 48, 64],
  colors: {
    primary: "#206ED1",
    textPrimary: "#000000",
    gray: "#f6f6ff",
    inverse: "#ffffff",
    logo: "blue",
    primary3: "#1A59C2",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fontWeights: {
    body: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.5,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  text: {
    base: {
      fontFamily: "body",
      color: "textPrimary",
      fontWeight: 400,
      lineHeight: "body",
    },
    semibold: {
      fontFamily: "body",
      color: "textPrimary",
      fontWeight: 600,
      lineHeight: "body",
    },
    semiboldGray: {
      fontFamily: "body",
      color: "#6D7278",
      fontWeight: 600,
      lineHeight: "body",
    },
    semiboldBlue: {
      fontFamily: "body",
      color: "#495ea2",
      fontWeight: 600,
      lineHeight: "body",
    },
    bold: {
      fontFamily: "body",
      color: "textPrimary",
      fontWeight: 700,
      lineHeight: "body",
    },
    gradient: {
      fontFamily: "body",
      color: "inverse",
      fontWeight: 400,
    },
    logo: {
      fontFamily: "logo",
      color: "logo",
    },
    link: {
      fontFamily: "body",
      color: "#1a59c2",
    },
    disabledText: {
      fontFamily: "body",
      color: "#b9b9b9",
      fontWeight: "body",
      lineHeight: "body",
    },
  },
  variants: {
    input: {
      borderRadius: "13px",
      backgroundColor: "#e9eef4",
    },
    boxBlue: {
      borderRadius: "9px",
      boxShadow: "0 1px 4px 0 rgba(95, 95, 95, 0.21)",
      // backgroundImage: 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)',
      backgroundColor: "#0750b1",
      fontWeight: 600,
    },
    boxGrey: {
      borderRadius: "9px",
      boxShadow: "0 1px 4px 0 rgba(95, 95, 95, 0.21)",
      backgroundColor: "#e9eef4",
    },
    boxGreyError: {
      borderRadius: "9px",
      boxShadow: "0 1px 4px 0 rgba(95, 95, 95, 0.21)",
      border: "2px solid red",
      backgroundColor: "#e9eef4",
    },
    boxWhite: {
      borderRadius: "9px",
      boxShadow: "0 1px 4px 0 rgba(95, 95, 95, 0.21)",
      backgroundColor: "#fff",
    },
    boxWhiteBlue: {
      borderRadius: "9px",
      boxShadow: "0 1px 4px 0 rgba(95, 95, 95, 0.21)",
      backgroundColor: "#f7fcff",
    },
    covidLanding: {
      height: "58em",
      opacity: "0.84",
      backgroundImage: "linear-gradient(209deg, #48b3fe 99%, #0bcce1 29%)",
    },
    boxCovidLanding: {
      width: "1px",
      height: "60px",
      boxShadow: "2px 0 4px 0 rgba(73, 133, 230, 0.37)",
      border: "solid 4px #0656d6",
    },
    boxGreen: {
      borderRadius: "9px 9px 0px 0px",
      backgroundColor: "#a8df5e",
    },
    boxYellow: {
      borderRadius: "9px 9px 0px 0px",
      backgroundColor: "#f3cc00",
    },
    boxOrange: {
      borderRadius: "9px 9px 0px 0px",
      backgroundColor: "#ff9b57",
    },
    boxRed: {
      borderRadius: "9px 9px 0px 0px",
      backgroundColor: "#fe6a69",
    },
    expandBoxGrey: {
      borderRadius: "0px 0px 9px 9px",
      backgroundColor: "#e9eef4",
    },
    circle: {
      borderRadius: "200px 200px 200px 200px",
    },
    boxHeader: {
      borderRadius: "0px 0px 8px 8px",
      boxShadow: "0 4px 4px 0 rgba(194, 194, 194, 0.5)",
      backgroundImage:
        "radial-gradient(circle at 88% 24%, #2d93ee, #1034a6 145%)",
    },
    boxHeaderQuestion: {
      borderRadius: "0px 0px 8px 8px",
      boxShadow: "0 4px 4px 0 rgba(194, 194, 194, 0.5)",
      backgroundImage: "linear-gradient(1deg, #32aaff 11%, #1034a6 141%)",
    },
    inputBoxWhite: {
      fontFamily: "body",
      fontWeight: "semibold",
      borderRadius: "29px",
      border: "none",
      boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.26)",
      backgroundColor: "#fff",
      outline: "none",
      color: "#555555",
    },
    boxCovid: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "43.5px",
    },
    questionChoice: {
      borderRadius: "18px",
      border: "solid 1.5px #1034a6",
      backgroundColor: "#fff",
      fontFamily: "body",
      outline: "none",
      color: "#1034a6",
    },
  },
  buttons: {
    primary: {
      fontFamily: "body",
      borderRadius: "21px",
      color: "white",
      bg: "primary",
      outline: "none",
    },
    gradient: {
      fontFamily: "body",
      fontWeight: 600,
      color: "inverse",
      borderRadius: "18px",
      // backgroundImage: 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)',
      backgroundColor: "#206ed1",
      outline: "none",
    },
    choice: {
      fontFamily: "body",
      color: "primary",
      borderRadius: "9px",
      border: "solid 1px #ffffff",
      backgroundColor: "#e9eef4",
      outline: "none",
    },
    base: {
      fontFamily: "body",
      color: "primary",
      borderRadius: "21px",
      boxShadow: "0 1px 1px 0 rgba(93, 93, 93, 0.22)",
      backgroundColor: "rgba(233, 238, 244, 0.58)",
      outline: "none",
    },
    outline: {
      fontFamily: "body",
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 0 2px",
      outline: "none",
    },
    button: {
      width: "20em",
      fontFamily: "body",
      fontWeight: "bold",
      borderRadius: "22px",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      backgroundColor: "#0656d6",
      border: "solid 1px rgba(151, 151, 151, 0)",
      outline: "none",
    },
    buttonGrey: {
      borderRadius: "16px",
      backgroundColor: "#f1f1f1",
      fontFamily: "body",
      outline: "none",
      color: "#0666d2",
    },
    buttonDisable: {
      width: "20em",
      fontFamily: "body",
      fontWeight: "bold",
      borderRadius: "22px",
      backgroundColor: "#dadada",
      border: "solid 1px rgba(151, 151, 151, 0)",
      outline: "none",
      color: "#808080",
    },
    buttonGreen: {
      width: "20em",
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "2",
      opacity: "0.66",
      borderRadius: "21px",
      border: "solid 1.5px #0e9e53",
      outline: "none",
      backgroundColor: "#fff",
      color: "#0e9e53",
    },
    buttonCovid: {
      borderRadius: "36px",
      border: "solid 1px #1034a6",
      backgroundColor: "#206ed1",
      fontFamily: "body",
      outline: "none",
      color: "#fff",
    },
    buttonChoice: {
      borderRadius: "18px",
      border: "solid 1.5px #1034a6",
      backgroundColor: "#fff",
      fontFamily: "body",
      outline: "none",
      color: "#1034a6",
    },
    buttonChoiceSelected: {
      borderRadius: "18px",
      backgroundColor: "#206ed1",
      fontFamily: "body",
      outline: "none",
      color: "#fff",
    },
    buttonChoiceClicked: {
      borderRadius: "18px",
      // backgroundColor: "#206ed1",
      background: "linear-gradient(0deg, #51BAF1 0%, #206ED1 100.68%)",
      fontFamily: "body",
      outline: "none",
      color: "#fff",
    },
    buttonChoicePreviousClicked: {
      borderRadius: "18px",
      backgroundColor: "#575757",
      fontFamily: "body",
      outline: "none",
      color: "#fff",
    },
    buttonSeeMoreImage: {
      backgroundColor: "rgba(0, 133, 255, 0.13)",
      fontFamily: "body",
      outline: "none",
      color: "#1034A6",
      borderRadius: "69px",
    },
    buttonCloseBlur: {
      backgroundColor: "#fff",
      fontFamily: "body",
      outline: "none",
      color: "#1034A6",
      borderRadius: "69px",
      position: "absolute",
      bottom: 0,
      fontWeight: 600,
    },
    buttonOTP: {
      fontFamily: "body",
      fontWeight: "semibold",
      borderRadius: "26px",
      backgroundColor: "rgba(0, 58, 112, 0.46)",
      outline: "none",
    },
    buttonTransparent: {
      fontFamily: "body",
      fontWeight: "semibold",
      backgroundColor: "transparent",
      borderRadius: "26px",
      border: "solid 2px #ffffff",
      outline: "none",
    },
    buttonLine: {
      fontFamily: "body",
      fontWeight: "body",
      backgroundColor: "#02b900",
      borderRadius: "26px",
      boxShadow: "0 5px 11px 0 rgba(10, 10, 10, 0.16)",
      outline: "none",
      width: "100%",
      minHeight: "56px",
    },
    buttonFB: {
      fontFamily: "body",
      fontWeight: "body",
      backgroundColor: "#1877F2",
      borderRadius: "26px",
      boxShadow: "0 5px 11px 0 rgba(10, 10, 10, 0.16)",
      outline: "none",
      width: "100%",
      minHeight: "56px",
    },
    buttonGoogle: {
      fontFamily: "body",
      fontWeight: "body",
      backgroundColor: "#fff",
      borderRadius: "26px",
      boxShadow: "0 5px 11px 0 rgba(10, 10, 10, 0.16)",
      outline: "none",
      width: "100%",
      minHeight: "56px",
    },
    buttonOnBoarding: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "16px",
    },
    buttonFeedbackGreen: {
      backgroundColor: "#62C08F",
      outline: "none",
      padding: "7px 49px",
      borderRadius: "21px",
      fontSize: "17px",
      fontFamily: "body",
      fontWeight: 600,
      lineHeight: "body",
    },
    buttonOrder: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: 20,
      border: "solid 2px #206ED1",
      outline: "none",
      color: "#206ED1",
      backgroundColor: "#fff",
      borderRadius: "14px",
      width: "100%",
      minHeight: "56px",
    },
  },
  forms: {
    input: {
      fontFamily: "body",
      fontWeight: "bold",
      border: "none",
      outline: "none",
      color: "#0656d6",
      ":checked": {
        backgroundColor: "#1034a6",
        color: "#1034a6",
      },
    },
    inputPersonal: {
      fontFamily: "body",
      color: "#001B52",
      fontWeight: 400,
      lineHeight: "body",
      border: "none",
      outline: "none",
      borderBottom: "1px solid #1A59C2",
    },
    inputBox: {
      fontFamily: "body",
      color: "#000",
      fontWeight: 600,
      lineHeight: "body",
      border: "1px solid #C6C6C6",
      outline: "none",
      borderRadius: "4px",
    },
    radio: {
      // backgroundColor: "#d8d8d8",
      // boxShadow: "inset 0 1px 3px 0 rgba(133, 133, 133, 0.5)",
      color: "#0c66d5",
    },
    checkbox: {
      borderRadius: "3px",
      // border: "solid 2.5px #0c66d5",
      color: "#0c66d5",
      // "::checked": {
      //   colors: "#1034a6",
      // },
      // ":checked": {
      // backgroundColor: "#1034a6",
      //   color: "#1034a6",
      // },
    },
    // checkboxDisabled: {
    //   borderRadius: "3px",
    //   color: "#B9B9B9",
    //   backgroundColor: "#B9B9B9",
    //   // border: "solid 0px #B9b9b9",
    //   width: 20,
    //   height: 20,
    //   mr: "0.75em",
    //   ml: "0.1em",
    // },
  },
};

const themes = createTheme(theme)

export default (props) => (
  <ThemeProvider theme={themes}>{props.children}</ThemeProvider>
);
